import { Modal } from "./Modal";
import { ModalButton } from "../modal/ModalButton";

// Initialize all modals.
document.querySelectorAll('[data-cbg-cmp="modal"]').forEach((el) => {
  new Modal(<HTMLElement>el);
});

// Initialize all modal buttons.
document.querySelectorAll("[data-opens-modal]").forEach((el) => {
  new ModalButton(<HTMLElement>el);
});
