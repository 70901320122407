import { Cart } from "./Cart";
import { CartCTA } from "./CartCTA";

document.querySelectorAll('[data-cbg-cmp="cart"]').forEach((el) => {
  new Cart(<HTMLElement>el);
});

// Establish CartCTA instances for every cart CTA button.
document.querySelectorAll('[data-cbg-cmp="cart-cta"]').forEach((el) => {
  new CartCTA(<HTMLElement>el);
});
