enum Selector {
  hook = "data-cbg-cmp-hook-info-drawer",
}

// Classes that get toggled for component's info-boxes element
enum Classnames {
  displayNone = "display-none",
  flexIn = "flex-n-wrap-it",
}

class InfoDrawer {
  // Elements
  container: HTMLElement;
  expandIcon: HTMLElement;
  collapseIcon: HTMLElement;
  infoBoxes: HTMLElement;
  iconCtaHolder: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.container = component;

    if (!this.container) {
      return null;
    }

    // Set Elements to correlating data-properties

    this.expandIcon = this.container.querySelector(
      `[${Selector.hook}="expand-cta"]`,
    );

    this.iconCtaHolder = this.container.querySelector(
      `[${Selector.hook}="info-cta"]`,
    );

    this.collapseIcon = this.container.querySelector(
      `[${Selector.hook}="collapse-cta"]`,
    );

    this.infoBoxes = this.container.querySelector(
      `[${Selector.hook}="info-boxes"]`,
    );

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    this.iconCtaHolder?.addEventListener(
      "click",
      this.toggleInfoBoxState.bind(this),
    );
  }

  // Method for toggling info-box element's expand and collapse states
  private toggleInfoBoxState() {
    this.infoBoxes.classList.toggle(Classnames.displayNone);
    this.infoBoxes.classList.toggle(Classnames.flexIn);
    this.expandIcon.classList.toggle(Classnames.displayNone);
    this.collapseIcon.classList.toggle(Classnames.displayNone);
  }
}

export { InfoDrawer };
