import { Cpra } from "../../site/scripts/cpra";

class NavigationV2 {
  mainNav: HTMLElement;
  cpra: Cpra;

  // Mobile check and element movements
  private checkForMobile = () => {
    const isMobile = window.innerWidth <= 768;
    isMobile ? this.handleMobileView() : this.handleDesktopView();
  };

  constructor(component: HTMLElement) {
    if (!component) return;

    this.mainNav = document.querySelector(".navigationV2") as HTMLElement;
    this.cpra = new Cpra();

    document.addEventListener("DOMContentLoaded", this.checkForMobile);

    this.initSearchBarEvents();
    this.initDocumentClickListener();
  }

  // Initialize search bar focus/blur events
  private initSearchBarEvents() {
    const headerSearchInputContainer = document.querySelector(
      ".header-home .search-wrapper .search-zone .cmp-searchbar__form-container",
    ) as HTMLElement;

    const headerSearchInput = document.querySelector(
      ".header-home .search-wrapper .search-zone .cmp-searchbar__input",
    ) as HTMLElement;

    const headerSearchZone = document.querySelector(
      ".header-home .search-wrapper .search-zone",
    ) as HTMLElement;

    HTMLElement;

    headerSearchInput.addEventListener("focus", () => {
      this.toggleSearchBar(
        true,
        headerSearchInput,
        headerSearchInputContainer,
        headerSearchZone,
      );
    });

    headerSearchInput.addEventListener("blur", () => {
      if (window.innerWidth >= 768) {
        this.toggleSearchBar(
          false,
          headerSearchInput,
          headerSearchInputContainer,
          headerSearchZone,
        );
      }
    });
  }

  // Toggles search bar classes
  private toggleSearchBar(
    active: boolean,
    input: HTMLElement,
    container: HTMLElement,
    zone: HTMLElement,
    containertest?: HTMLElement,
  ) {
    const action = active ? "add" : "remove";
    input.classList[action]("header-search-input-active");
    container.classList[action]("header-search-container-active");
    containertest?.classList.remove("active");
    zone.classList[action]("header-search-zone-active");
  }

  // Document click listener to handle closing the search bar
  private initDocumentClickListener() {
    const headerSearchInputContainer = document.querySelector(
      ".header-home .search-wrapper .search-zone .cmp-searchbar__form-container",
    ) as HTMLElement;

    document.addEventListener("click", (e) => {
      const isClickedInsideDiv = e
        .composedPath()
        .includes(headerSearchInputContainer);

      if (!isClickedInsideDiv) {
        this.closeSearchBar();
        const searchForm = document.querySelector(
          ".search-zone .cmp-searchbar__form",
        ) as HTMLElement;

        const headerSearchInputContainertest = document.querySelector(
          ".cmp-searchbar__results",
        ) as HTMLElement;
        (
          headerSearchInputContainertest?.firstChild as HTMLElement
        )?.classList?.remove("active");

        searchForm?.parentElement?.classList?.remove(
          "header-search-container-active",
        );
      }
    });
  }

  // Close search bar function
  private closeSearchBar() {
    const headerSearchInput = document.querySelector(
      ".cmp-searchbar__input",
    ) as HTMLElement;
    const headerSearchInputContainer = document.querySelector(
      ".cmp-searchbar__form-container",
    ) as HTMLElement;
    const headerSearchInputContainertest = document.querySelector(
      ".cmp-searchbar__results-inner-container",
    ) as HTMLElement;
    const headerSearchZone = document.querySelector(
      ".search-zone",
    ) as HTMLElement;

    if (headerSearchInput.classList.contains("header-search-input-active")) {
      this.toggleSearchBar(
        false,
        headerSearchInput,
        headerSearchInputContainer,
        headerSearchZone,
      );
    }
  }

  private handleMobileView() {
    this.moveUtilityNavToMobile();
    this.moveFavoritesToMobile();
    this.createMobileSearchToggleButton();
    this.createMobileBackButton();
  }

  private handleDesktopView() {
    this.removeElement(".mobile-nav-back-level-button");
    this.moveUtilityNavToDesktop();
    this.moveFavoritesToDesktop();
    this.removeNewMobileBackButton();
    this.createSearchBackground();
  }

  private moveUtilityNavToMobile() {
    const utilityNavItem = document.querySelector(
      "nav .main-menu-list .authored-list-listItem",
    );
    if (!utilityNavItem) {
      const utilityItems = document.querySelectorAll(
        ".utility-zone ul.authored-list-list li",
      );
      const mainMenuList = document.querySelector(
        "nav ul.main-menu-list",
      ) as HTMLElement;

      utilityItems.forEach((item) => mainMenuList.appendChild(item));
    }
  }

  private moveFavoritesToMobile() {
    const mobileFavoritesNavItem = document.querySelector(
      "nav .mobile-favorites-nav-item",
    );
    if (!mobileFavoritesNavItem) {
      const favoritesSection = document.querySelector(
        ".cbg-cmp-favorites.context--header",
      ) as HTMLElement;
      if (favoritesSection) {
        const favoritesItem = document.createElement("li");
        favoritesItem.className = "mobile-favorites-nav-item";
        favoritesItem.appendChild(favoritesSection);

        const mainMenuList = document.querySelector(
          "nav ul.main-menu-list",
        ) as HTMLElement;
        mainMenuList.appendChild(favoritesItem);
      }
    }
  }

  private createMobileBackButton() {
    const mobileBackButton = document.querySelector(
      "nav .mobile-nav-back-level-button",
    );

    if (!mobileBackButton) {
      const navigationContainer = document.querySelector(
        ".navigationV2 .main-menu-navigation",
      ) as HTMLElement;
      const navigationHamburgerMenu = document.querySelector(
        ".navigationV2 .main-menu-mobile-toggle-button",
      ) as HTMLElement;

      // Event listener for when a navigation item is clicked
      navigationContainer.addEventListener("click", (event) => {
        const target = event.target as HTMLElement;

        const hasDropdownItem = target.closest("div.main-menu-dropdown-item");

        if (!hasDropdownItem) return;

        const newMobileBackButton = document.createElement("div");
        newMobileBackButton.className = "mobile-nav-back-level-button";
        newMobileBackButton.tabIndex = 0;
        newMobileBackButton.textContent = "Back"; // Should be moved to data element for translation text

        // Insert the back button after the hamburger menu
        navigationContainer.insertBefore(
          newMobileBackButton,
          navigationHamburgerMenu.nextSibling,
        );

        // Remove back button when the navigation closes
        const navigationCloseButton = document.querySelector(
          ".navigationV2 .main-menu-mobile-toggle-button",
        );
        navigationCloseButton.addEventListener("click", () => {
          newMobileBackButton.remove();
        });

        // Remove back button when clicking on it
        newMobileBackButton.addEventListener("click", () => {
          newMobileBackButton.remove();
        });

        // Remove back button when clicking outside of the navigation window
        document.addEventListener("click", (e) => {
          const navigationContainer = document.querySelector(
            ".navigationV2 .main-menu-navigation .main-menu-list",
          );
          const isClickInsideNav = e
            .composedPath()
            .includes(navigationContainer);

          if (!isClickInsideNav && newMobileBackButton) {
            newMobileBackButton.remove();
          }
        });
      });
    }
  }

  private removeNewMobileBackButton() {
    const newMobileBackButton = document.querySelector(
      "nav .mobile-nav-back-level-button",
    );
    if (newMobileBackButton) {
      newMobileBackButton.remove();
    }
  }

  private createMobileSearchToggleButton() {
    const searchForm = document.querySelector(
      ".search-zone .cmp-searchbar__form",
    ) as HTMLElement;
    let mobileSearchBackground = document.querySelector(".header-search-bg");

    if (!mobileSearchBackground) {
      mobileSearchBackground = document.createElement("div");
      mobileSearchBackground.className = "header-search-bg";
      searchForm.appendChild(mobileSearchBackground);
    }

    let mobileSearchToggleButton = document.querySelector(
      ".header-search-toggle-btn",
    ) as HTMLElement;
    if (!mobileSearchToggleButton) {
      mobileSearchToggleButton = document.createElement("div") as HTMLElement;
      mobileSearchToggleButton.className = "header-search-toggle-btn";
      mobileSearchToggleButton.tabIndex = 0;
      searchForm.appendChild(mobileSearchToggleButton);

      mobileSearchToggleButton.addEventListener("click", () => {
        const headerSearchInputContainer = document.querySelector(
          ".cmp-searchbar__form-container",
        ) as HTMLElement;
        headerSearchInputContainer.classList.add(
          "header-search-container-active",
        );
      });
    }
  }

  private moveUtilityNavToDesktop() {
    const utilityNavItems = document.querySelectorAll(
      "nav .main-menu-list .authored-list-listItem",
    );
    const utilityZone = document.querySelector(
      ".utility-zone .authored-list-list",
    );

    utilityNavItems.forEach((item) => {
      utilityZone.appendChild(item);
    });
  }

  private moveFavoritesToDesktop() {
    const mobileFavoritesItem = document.querySelector(
      "nav .mobile-favorites-nav-item .cbg-cmp-favorites",
    );
    if (mobileFavoritesItem) {
      const tertiaryFavoritesZone = document.querySelector(
        ".tertiary-zone .favorites",
      );
      tertiaryFavoritesZone.appendChild(mobileFavoritesItem);

      this.removeElement("nav .mobile-favorites-nav-item");
    }
  }

  private createSearchBackground() {
    const desktopSearchBackground = document.querySelector(
      ".search-zone .header-search-bg",
    );
    if (!desktopSearchBackground) {
      const searchZone = document.querySelector(".search-zone") as HTMLElement;
      const searchBackground = document.createElement("div");
      searchBackground.className = "header-search-bg";
      searchZone.appendChild(searchBackground);
    }
  }

  // Utility function to remove elements
  private removeElement(selector: string) {
    const element = document.querySelector(selector);
    if (element) {
      element.remove();
    }
  }
}

export { NavigationV2 };
