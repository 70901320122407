import { ResultsContainer, AlgoliaSearchResult } from "./ResultsContainer";

class ColorResultsContainer extends ResultsContainer {
  resultsIndexKey = "wp_color_index";
  type = "color";

  constructor() {
    super();
    this.setContainerFromType();
  }

  updateWithResults(results: AlgoliaSearchResult): void {
    results?.hits?.forEach((hit) => {
      const title = hit.post_title;
      const colorNumber = hit.color_number;
      const rgb = hit.rgb;
      const hex = hit.hex;
      const slug = hit.slug;
      const isColorOfTheYear = hit.color_of_the_year === "1";
      const colorOfTheYearText = hit.coty_word;
      const colorImage = hit.color_swatch_url;

      const html = this.getTemplateFromType();

      try {
        const $colorLink = html.querySelector(
          `[${this.resultsHook}="colorlink"]`,
        );
        const $colorSwatch = <HTMLElement>(
          html.querySelector(`[${this.resultsHook}="colorswatch"]`)
        );
        const $colorName = html.querySelector(
          `[${this.resultsHook}="colorname"]`,
        );
        const $colorId = html.querySelector(`[${this.resultsHook}="colorid"]`);
        const $colorTagEmblemText = html.querySelector(
          `[${this.resultsHook}="colortagemblemtext"]`,
        );

        if (slug && $colorLink) {
          $colorLink.setAttribute("href", slug);
        }

        if (title && $colorName) {
          $colorName.innerHTML = title;
        }

        if (colorNumber && $colorId) {
          $colorId.innerHTML = colorNumber;
        }

        if (isColorOfTheYear && $colorTagEmblemText) {
          $colorTagEmblemText.innerHTML = colorOfTheYearText;
        }

        if ((hex || rgb) && $colorSwatch) {
          let color = hex;
          if ((!color || color.trim() == "") && rgb) {
            color = `rgb(${rgb})`;
          }
          $colorSwatch.style.backgroundColor = color;
        }

        this.container.append(html);
      } catch (error) {
        console.warn("Issue adding Color Results", error);
      }
    });
  }
}

export { ColorResultsContainer };
