import { ResultsContainer, AlgoliaSearchResult } from "./ResultsContainer";

class ProductResultsContainer extends ResultsContainer {
  resultsIndexKey = "wp_products_index";
  type = "product";

  constructor() {
    super();
    this.setContainerFromType();
  }

  updateWithResults(results: AlgoliaSearchResult): void {
    results?.hits?.forEach((hit) => {
      const title = hit.post_title;
      const desc = hit.prod_description;
      const image = hit.prod_image;
      const slug = hit.slug;
      const productId = hit.product_id;

      const html = this.getTemplateFromType();

      try {
        const $product = html.querySelector(`[${this.resultsHook}="product"]`);
        const $productSlug = <HTMLElement>(
          html.querySelector(`[${this.resultsHook}="productslug"]`)
        );
        const $image = html.querySelector(`[${this.resultsHook}="image"]`);
        const $productTitle = html.querySelector(
          `[${this.resultsHook}="producttitle"]`,
        );
        const $productDesc = html.querySelector(
          `[${this.resultsHook}="productdesc"]`,
        );

        if (productId && $product) {
          $product.setAttribute("data-product-id", productId);
        }

        if (slug) {
          $productSlug?.setAttribute("href", slug);
          $productTitle?.setAttribute("href", slug);
          $productDesc?.setAttribute("href", slug);
        }

        if (title && $productTitle) {
          $productTitle.innerHTML = title;
        }

        if (desc && $productDesc) {
          $productDesc.innerHTML = desc;
        }

        if (image && $image) {
          $image.setAttribute("src", image);
        }

        this.container.append(html);
      } catch (error) {
        console.warn("Issue adding Product Results", error);
      }
    });
  }
}

export { ProductResultsContainer };
