import "./navigation";
import "./search";
import "./pagenavigator";
import "./cookiePersist";
import "./carousel";
import "./modal";
import "./dynamicflexcontainer";
import "./filteroption";
import "./simpleCarousel";
import "./inspirationCarousel";
import "./fieldToggle";
import "./combinedinput";
import "./wall";
import "./tooltip";
import "./ratingsreviews";
import "./location";
import "./form";
import "./banner";
import "./bannerV2";
import "./button";
import "./imageupload";
import "./productspecs";
import "./recaptcha";
import "./languageToggle";
import "./sliderComponent";
import "./cart";
import "./contentDrawer";
import "./favorites";
import "./notificationsBanner";
import "./carouselNotificationsBanner";
import "./cookieAware";
import "./infodrawer";
import "./colordetail";
import "./newsLetter";
import "./checkoutConfirmation";
import "./prism";
import "./colorVisualizer";
import "./paintSplotchCarousel";
import "./scrollTo";
import Analytics from "../site/scripts/analytics";
import "sorttable";
import "./gif";
import "./accordion";
import "./navigationV2";
import "./dropDownSelector";
import "./dynamicPdpColorChips";
import "./billboard";

// Dynamically register handlers for authored analytics
Analytics.registerAnalyticsComponents();
