import { Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";

enum Selector {
  color = "data-cbg-cmp-hook-color-name",
}
// Sass Class to apply when Notification Banner is displayed.
enum Classnames {
  fadeIn = "fade-in",
}

class NotificationsBanner {
  // Elements
  container: HTMLElement;
  nameOfColor: HTMLElement;
  maxItemsAllowed = 10;
  globalCartCount = 0;
  loadDelay: number;
  eventToFireOn: string;
  btnElement: HTMLButtonElement;
  focusableElements: Element[] = [];
  currentBrand: string;
  isV3Brand: boolean;

  constructor(component: HTMLElement) {
    // Confirm component
    this.container = component;
    if (!this.container) {
      return null;
    }
    // Component's data attributes
    const data = this.container.dataset;
    this.nameOfColor = this.container.querySelector(
      `[${Selector.color}="color-name"]`,
    );
    // Initialize the delay for component's setTimeout method
    this.loadDelay =
      data && !isNaN(parseFloat(data.notificationsBannerDelay))
        ? parseFloat(data.notificationsBannerDelay)
        : 0;
    // Initialize the event that triggers the display of the Notification Banner
    this.eventToFireOn = data.notificationsBannerEvent
      ? data.notificationsBannerEvent
      : "add";
    this.currentBrand = Utils.getCbgBrand();
    this.isV3Brand = Utils.getCartVersionBoolean();

    // Add Button on Click Event Listener
    this.btnElement = this.container.querySelector("#notificationsBanner-btn");
    this.btnElement?.addEventListener(
      "click",
      this.hideNotificationHandler.bind(this),
    );

    // Call message subscriber method
    this.registerMessageSubscribers();

    this.getFocusableElements();
  }

  // Handling Notification Button on Click
  private hideNotificationHandler(): void {
    this.container.classList.remove(Classnames.fadeIn);
    this.setTabIndex(false);
  }

  // Subscribe to the color chip publisher.
  private registerMessageSubscribers(): void {
    // TODO: Subscribe to Fav CTA click event when it is completed.

    //  If fav chip, listen for addToFavorites publisher message addFavoritesErrorResponse
    if (this.eventToFireOn === "fav") {
      Utils.msg.subscribe(Message.addToFavorites, this.setChip.bind(this));
      //Only for Minwax
      Utils.msg.subscribe(
        Message.addFavoritesErrorResponse,
        this.errorMessage.bind(this),
      );
    }

    //  If max chip, listen for addToCartResponse publisher message
    if (this.eventToFireOn === "max") {
      Utils.msg.subscribe(Message.addToCartResponse, (response) => {
        this.setFavorites(response);
      });
    }
    //  If add chip, listen for addToCart publisher message
    if (this.eventToFireOn === "add") {
      Utils.msg.subscribe(Message.addToCart, this.setChip.bind(this));
    }
    //  If remove chip, listen for removeFromCart publisher message
    if (this.eventToFireOn === "remove") {
      Utils.msg.subscribe(Message.removeFromCart, this.setChip.bind(this));
    }
  }
  // Trigger Notifications Banner for Max Chip event
  private setFavorites(response): void {
    const { cart, cartItems, favorites } = response;
    const isMaxedOut = this.globalCartCount === this.maxItemsAllowed;
    this.globalCartCount = this.isV3Brand ? cart?.length : cartItems.length;

    if (isMaxedOut) {
      favorites.forEach((fav) => {
        this.nameOfColor.textContent = fav.colorName;
        this.container.classList.add(Classnames.fadeIn);
        this.setTabIndex(true);
        const container = this.container;
        const delay = this.loadDelay ? this.loadDelay * 1000 : 4000;
        setTimeout(() => {
          container.classList.remove(Classnames.fadeIn);
          this.setTabIndex(false);
        }, delay);
      });
    }
  }
  // Trigger Notifications Banner for Add and Remove Chips events
  private setChip(response): void {
    const { colorName } = response;
    Utils.msg.publish(Message.notificationsBannerState, { isOpen: true });
    if (colorName) {
      this.nameOfColor.textContent = colorName;
      const bannerContent = this.container.querySelector(
        ".notificationBanner-content",
      );
      bannerContent.textContent =
        this.container?.dataset?.notificationsBannerDescription;
      this.container.classList.add(Classnames.fadeIn);
      this.setTabIndex(true);
      const container = this.container;
      const delay = this.loadDelay ? this.loadDelay * 1000 : 4000;
      setTimeout(() => {
        container.classList.remove(Classnames.fadeIn);
        this.setTabIndex(false);
        Utils.msg.publish(Message.notificationsBannerState, { isOpen: false });
      }, delay);
    }
  }

  // Trigger Notifications Banner to let the end user know about the limit
  private errorMessage(response): void {
    //Response
    const { colorName } = response;
    Utils.msg.publish(Message.notificationsBannerState, { isOpen: true });
    if (colorName) {
      this.nameOfColor.textContent = "";
      const bannerContent = this.container.querySelector(
        ".notificationBanner-content",
      );
      bannerContent.textContent = colorName;
      this.container.classList.add(Classnames.fadeIn);
      this.setTabIndex(true);
      const container = this.container;
      const delay = this.loadDelay ? this.loadDelay * 1000 : 4000;
      setTimeout(() => {
        container.classList.remove(Classnames.fadeIn);
        this.setTabIndex(false);
        Utils.msg.publish(Message.notificationsBannerState, { isOpen: false });
      }, delay);
    }
  }

  private getFocusableElements(): void {
    const focusableSelectors: string[] = [
      "input",
      "select",
      "textarea",
      "a",
      "button",
      "area",
    ];
    focusableSelectors.forEach((item) => {
      const elements = this.container.querySelectorAll(item);
      elements.forEach((element) => {
        this.focusableElements.push(element);
      });
    });
  }

  private setTabIndex(placeInTabOrder): void {
    this.focusableElements.forEach((element) => {
      element.setAttribute("tabindex", placeInTabOrder == true ? "0" : "-1");
    });
  }
}

export { NotificationsBanner };
