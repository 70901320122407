import Swiper, { Navigation, Pagination } from "swiper";

class SimpleCarousel {
  container: HTMLElement;
  howManySlides: number;
  spaceBetween: number;
  slideThumbnails: any;

  constructor(component: HTMLElement) {
    Swiper.use([Navigation, Pagination]);
    this.container = component;
    this.howManySlides = parseInt(this.container.dataset.howManySlides);
    this.spaceBetween = parseInt(this.container.dataset.spaceBetween);
    if (!this.container) {
      return null;
    }

    // const thumbControls = new Swiper(".thumbnailControls", {
    //   spaceBetween: 10,
    //   slidesPerView: 4,
    //   watchSlidesProgress: true,
    // });
    //
    // const mainSwiper = new Swiper(".mySwiper", {
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    //   breakpoints: {
    //     // when window width is >= 320px
    //     320: {
    //       slidesPerView: 1,
    //       spaceBetween: 0,
    //     },
    //     // when window width is >= 480px
    //     780: {
    //       slidesPerView: 2,
    //       spaceBetween: 30,
    //     },
    //     // when window width is >= 640px
    //     1200: {
    //       slidesPerView: this.howManySlides,
    //       spaceBetween: this.spaceBetween,
    //     },
    //   },
    //   zoom: true,
    //   thumbs: {
    //     swiper: thumbControls,
    //   },
    // });
  }
}

export { SimpleCarousel };
