const resizeRatings = function () {
  const outer = document.querySelector<HTMLElement>(
    ".ratings-reviews__integration-container.ratings-reviews__integration-container--bvReviews",
  );
  if (outer) {
    const shadow = outer.shadowRoot;
    if (shadow) {
      const container = shadow.querySelector<HTMLElement>(
        ' [class*="bv-rnr"]:has(> [aria-label*="Rating"])',
      );
      if (container) {
        container.style.setProperty("container-type", "inline-size");
        const grid = container.querySelector<HTMLElement>(
          '[aria-label*="Rating"]',
        );
        if (grid) {
          grid.style.setProperty("gap", "min(2cqi, 10px)");
          grid.style.setProperty("width", "100%");
          grid.style.setProperty(
            "grid-template-columns",
            "repeat(5, min(18cqi, 55px))",
          );
          grid
            .querySelectorAll<HTMLElement>(".bv-reviews-product-rating-star")
            .forEach((button) => {
              button.style.setProperty("padding", "0");
            });
          grid.querySelectorAll<HTMLElement>("div div").forEach((star) => {
            star.style.setProperty("max-width", "revert");
            star.style.setProperty("min-width", "revert");
            star.style.setProperty("max-height", "revert");
            star.style.setProperty("min-height", "revert");
            star.style.setProperty("aspect-ratio", "1");
          });
        }
      } else {
        setTimeout(resizeRatings, 100);
      }
    } else {
      setTimeout(resizeRatings, 100);
    }
  } else {
    setTimeout(resizeRatings, 100);
  }
};

export default resizeRatings;
