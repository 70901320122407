enum Classnames {
  hideClass = "show-tooltip",
  element = "data-component-id",
}

enum Selector {
  hook = "data-cbg-cmp-hook-tool-tip",
}

class ToolTip {
  // Elements
  closeButton: HTMLElement;
  showToolTipButton: HTMLElement;
  component: HTMLElement;
  toolTipIcon: HTMLElement;

  constructor(component: HTMLElement) {
    this.component = component;
    this.toolTipIcon = component.querySelector(".modal-icon");

    if (!this.component) {
      return;
    }

    this.closeButton = this.component.querySelector(
      `[${Selector.hook}="close-button"]`,
    );

    this.showToolTipButton = this.component.querySelector(
      `[${Selector.hook}="show-tooltip-button"]`,
    );

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    window.addEventListener("click", this.removeHidden.bind(this));

    this.component.addEventListener(
      "keypress",
      this.removeWithEnter.bind(this),
    );

    this.toolTipIcon?.addEventListener(
      "keypress",
      this.openWithKeyPress.bind(this),
    );
  }

  private removeHidden(e) {
    const showClass = document.getElementsByClassName("show-tooltip");
    const containerClass = ".cbg-cmp-toolTip-icon";
    const tgt = <HTMLElement>e.target;

    if (!tgt.closest(containerClass)) {
      this.component.classList.remove(Classnames.hideClass);
    }

    if (tgt == this.showToolTipButton) {
      if (showClass.length > 0) {
        [].forEach.call(showClass, function (el) {
          el.classList.remove("show-tooltip");
        });
      }
      this.component.classList.add(Classnames.hideClass);
      this.styleHideClass();
    }

    if (tgt == this.closeButton) {
      this.component.classList.remove(Classnames.hideClass);
    }
  }

  private removeWithEnter(e) {
    if (e.key === "Enter") {
      this.component.classList.add(Classnames.hideClass);
    }
  }

  private openWithKeyPress(e) {
    if (e.key === "Enter" || e.key == " " || e.code == "Space")
      this.toolTipIcon.click();
  }

  private styleHideClass() {
    if (screen.width > 991) return;
    const screenMidPoint = screen.width / 2;
    const boundingRect = this.component.getBoundingClientRect();
    const toolTipSpan = this.component.querySelector(".cbg-cmp-toolTip-icon");

    if (boundingRect.left + boundingRect.width / 2 < screenMidPoint) {
      if (toolTipSpan.classList.contains("open-right")) {
        toolTipSpan.classList.remove("open-right");
      }
      if (!toolTipSpan.classList.contains("open-left")) {
        toolTipSpan.classList.add("open-left");
      }
    } else {
      if (toolTipSpan.classList.contains("open-left")) {
        toolTipSpan.classList.remove("open-left");
      }
      if (!toolTipSpan.classList.contains("open-right")) {
        toolTipSpan.classList.add("open-right");
      }
    }
  }
}

export { ToolTip };
