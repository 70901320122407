import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";
import { CartAPI } from "../cart/AskValV3";
import { FavoriteCTA } from "./FavoriteCTA";
import { State } from "../../enum/state";

interface FavoriteColorItem {
  mdmColorId?: string;
  cartId: number | null;
  channelId: string;
  collection?: string;
  isCollection?: boolean;
  collectionData?: string;
  collectionName?: string;
  colorId: string;
  colorName: string;
  colorNumber: string;
  colorFamily: string;
  coty?: boolean;
  colors?: string[];
  colorsInCollectionText?: string;
  hex?: string;
  hexColor?: string;
  href?: string;
  id?: number;
  localeId?: string;
  paintSampleUrl?: string;
  tagEmblem?: string;
  tags?: string;
  timestamp?: number;
  mdmId?: string;
  swatchUrl?: string;
  mdmType?: string;
  transparency?: string;
  associatedProducts?: Record<string, string>[];
}

// Only adding the attributes we are using.
// Add more as you need more
export interface ProductT {
  productId: string;
  productName: string;
}

class FavoritesController {
  private static initPromise: Promise<void>;
  cartId: null;
  postToAPI = false;
  persistenceKey = "favorites";
  linksKey = "favoritesLinks";
  timestampKey = "favoritesTimestamps";
  tagsKey = "favoritesTags";
  favorites: FavoriteColorItem[];
  links: Record<string, string>;
  timestamps: Record<string, number>;
  tags: Record<string, string>;
  brand: string;
  favoritesMdmVersion: boolean;

  constructor() {
    this.brand = Utils.getCbgBrand();
    // Store the promise from initializeFavorites
    FavoritesController.initPromise = this.initializeFavorites();

    // Add event listener for parentEvent
    window.addEventListener("parentEvent", () => {
      this.handleModalClose();
    });
  }

  // Change to instance method instead of static
  getInitializationPromise() {
    return FavoritesController.initPromise;
  }

  private async initializeFavorites() {
    this.favorites = await this.read();
    const element = document.querySelector(
      '[data-cbg-cmp="favorites"]',
    ) as HTMLElement;

    //Hide Cart if Minwax
    const cartElement = document.querySelector<HTMLElement>(
      '[data-cbg-cmp="cart"]',
    );
    this.brand === "minwax" &&
      cartElement?.parentElement?.classList.add("hide-cart");

    this.favoritesMdmVersion = element?.dataset?.favoritesMdmVersion === "true";
    this.updateFavoriteCTAs();
    this.checkForCartId();
  }

  /*
   Initializes a CTA for any active favorite.
   */
  updateFavoriteCTAs(): void {
    (this.favorites || []).map((favorite) => {
      const id = FavoritesController.getFavoriteId(
        favorite,
        this.favoritesMdmVersion,
      );
      //Check for MDM only for favoritesMdmVersion alone
      const target = this.favoritesMdmVersion
        ? `.cbg-favorite-cta[data-mdmid="${id}"]`
        : `.cbg-favorite-cta[data-fav-color-id="${id}"]`;

      // Select elements based on the target
      const elements = document.querySelectorAll(target) as any;

      elements.forEach((element) => {
        if (element && element.dataset.cmpInit !== "true") {
          element.dataset.cmpInit = "true";
          new FavoriteCTA(<HTMLElement>element, favorite);
        }
      });
    });
  }

  setPostToApi(postToApiCookie: string) {
    this.postToAPI = postToApiCookie === "use-api";
  }

  checkForCartId() {
    const cartApiResponse = Cookie.get("cart-api");
    if (!this.cartId && cartApiResponse) {
      this.cartId = JSON.parse(cartApiResponse).cartId || null;
    }
  }

  static getFavoriteId(favorite: FavoriteColorItem, brand?: boolean): string {
    return brand
      ? favorite.mdmId || favorite.mdmColorId
      : favorite.isCollection
        ? favorite.collection
        : favorite.colorNumber;
  }

  addAndPersist(favorite: FavoriteColorItem) {
    const favoriteToSave = {
      collection: favorite.collection,
      collectionData: favorite.collectionData,
      collectionName: favorite.collectionName,
      channelId: favorite.channelId,
      colorId: favorite.colorId,
      colorName: favorite.colorName,
      colorNumber: favorite.colorNumber,
      colorFamily: favorite.colorFamily,
      id: favorite.id,
      mdmId: favorite.mdmId,
      swatchUrl: favorite.swatchUrl,
      cartId: null,
      hexColor: favorite.hex,
      href: favorite.href,
      tagEmblem: null,
      favorite: true,
      mdmType: favorite.mdmType,
      transparency: favorite.transparency,
    };
    // Push the incoming favorite to the global array.
    this.favorites.push(favoriteToSave);
    // Save the supplemental data for timestamps, tags, and links.
    const id = FavoritesController.getFavoriteId(
      favorite,
      this.favoritesMdmVersion,
    );
    if (typeof id !== "undefined") {
      this.tags[id] = favorite.tags || "";
      this.links[id] = favorite.href || "";
      this.timestamps[id] = favorite.timestamp || new Date().getTime();
    }

    // Persist the data.
    this.persistData();
  }

  add(favorite: FavoriteColorItem) {
    if (
      typeof favorite.collection === "undefined" &&
      typeof favorite.colorName === "undefined"
    ) {
      return;
    }

    const favItems = JSON.parse(
      window.localStorage.getItem(this.persistenceKey) || "[]",
    );
    const count = favItems.length;

    if (this.brand === "minwax" && count >= 8) {
      Utils.msg.publish(Message.addFavoritesErrorResponse, {
        colorName: "You've reached the maximum number of favorites allowed",
      });
      return;
    }

    this.addAndPersist(favorite);
    // Publish message a message that a favorite was added.

    //Message.addToFavorites, event triggeres the notification banner
    //Also updates the UI loading state which adds the active class to the CTA.
    Utils.msg.publish(Message.addToFavorites, favorite);
    //Message.addFavoritesResponse, event triggers the favorites badge count on headder only.
    Utils.msg.publish(Message.addFavoritesResponse, this.favorites);

    const itemID: string = ["minwax", "dutchboy"].includes(this.brand)
      ? favorite.mdmId
      : favorite.colorId;
    if (this.postToAPI) {
      try {
        const isColorCollection =
          favorite.colorId !== favorite.collection ||
          favorite.collection === "COTY";

        CartAPI.addFavorite(
          itemID,
          isColorCollection ? "color" : "collection",
          favorite.channelId,
          favorite.localeId,
        );
      } catch {
        console.warn("Trouble adding Favorite...");
      }
    }
  }

  async remove(colorId: string) {
    // This takes the colorID of the item to be removed, finds it in the array of favorites, gets the ID associated with it
    // which is unique each call, and sends it back in the remove call.
    const indexOfFaveWithMatchingColorId = this.favorites.findIndex((fave) => {
      return (
        fave.mdmId === colorId || //MdmID
        fave.mdmColorId === colorId || // MDM Id
        fave.colorId === colorId || // Color
        fave.collectionName === colorId // Collection
      );
    });

    if (indexOfFaveWithMatchingColorId > -1) {
      const removedFavorite =
        this.favorites[indexOfFaveWithMatchingColorId] || {};
      // Create a new array copy using spread operator
      const afterRemove = [...this.favorites];
      afterRemove.splice(indexOfFaveWithMatchingColorId, 1);

      this.persistData();
      // Publish messages.
      Utils.msg.publish(Message.removeFromFavorites, removedFavorite);
      Utils.msg.publish(Message.removeFromFavoritesResponse, afterRemove);
    }

    if (this.postToAPI) {
      // Find the index of the clicked item and grab its ID to send to the favoritesAPI along with the token.,
      const apiFavorites = await this.read();
      const clickedIndex = apiFavorites.findIndex((favoriteItem) => {
        return (
          favoriteItem.mdmId === colorId ||
          favoriteItem.mdmColorId === colorId || // MDM Id
          favoriteItem.colorNumber === colorId || // Color
          favoriteItem.collection === colorId // Collection
        );
      });
      try {
        await CartAPI.removeFromFavorites(apiFavorites[clickedIndex].id);
      } catch {
        console.warn("Trouble removing favorite");
      }
    }
  }

  private persistData() {
    // Save data for the favorites array.
    window.localStorage.setItem(
      this.persistenceKey,
      JSON.stringify(this.favorites),
    );

    // Save data for when a favorite was saved.
    window.localStorage.setItem(
      this.timestampKey,
      JSON.stringify(this.timestamps),
    );

    // Save data for the favorite's link.
    window.localStorage.setItem(this.linksKey, JSON.stringify(this.links));

    // Save data for the favorite's tags.
    window.localStorage.setItem(this.tagsKey, JSON.stringify(this.tags));

    // Notify React that localStorage has been updated
    const event = new Event("LOCAL_FAVORITE_UPDATE");
    window.dispatchEvent(event);
  }

  async read(): Promise<any[]> {
    let favorites = [];

    try {
      if (this.postToAPI) {
        // If we're posting to the API, fetch the cart directly
        const cartResponse = await CartAPI.getCart();
        favorites = cartResponse?.favorites ?? [];
      } else {
        // If API posting is disabled, read from local storage
        favorites = JSON.parse(
          window.localStorage.getItem(this.persistenceKey) ?? "[]",
        );
      }
    } catch (error) {
      console.warn(
        error,
        "A problem occurred while trying to retrieve Favorites. If testing, please turn off VPN and try again from an external dispatch site.",
      );
    }

    // Fetch the supplemental data for timestamps, tags, and links
    this.timestamps = JSON.parse(
      window.localStorage.getItem(this.timestampKey) ?? "[]",
    );
    this.links = JSON.parse(window.localStorage.getItem(this.linksKey) ?? "[]");
    this.tags = JSON.parse(window.localStorage.getItem(this.tagsKey) ?? "[]");

    // Map and extend each favorite with additional data (timestamp, href, tags)
    favorites = favorites.map((favorite) => {
      const id = FavoritesController.getFavoriteId(
        favorite,
        this.favoritesMdmVersion,
      );
      const timestamp = this.timestamps[id] ?? 0;
      const href = this.links[id] ?? "";
      const tags = this.tags[id] ?? "";

      return { ...favorite, timestamp, href, tags };
    });

    // Sort favorites by timestamp, most recent first
    return favorites.sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0));
  }

  async count(): Promise<number> {
    const favorites = await this.read();
    return favorites.length;
  }

  private handleModalClose(): void {
    // Add your modal close handling logic here
    // For example, refresh favorites list or update UI
    this.headerFavOnModalClose();
  }

  public async headerFavOnModalClose(): Promise<void> {
    const favorites = await this.read();
    Utils.msg.publish(Message.addFavoritesResponse, favorites);

    // Iterate over all registered FavoriteCTA instances
    FavoriteCTA.instances.forEach((ctaInstances, id) => {
      // Check if this CTA's color exists in favorites
      const isFavorited = favorites.some((fav) => {
        const favId = this.favoritesMdmVersion
          ? fav.mdmId || fav.mdmColorId
          : fav.isCollection
            ? fav.collection
            : fav.colorNumber;
        return favId === id;
      });

      // Update the state for all instances of this color ID
      ctaInstances.forEach((ctaInstance) => {
        if (isFavorited) {
          ctaInstance.updateComponentState(State.ADD);
        } else {
          ctaInstance.updateComponentState(State.REMOVE);
        }
      });
    });
  }
}

const FavoritesAPI = new FavoritesController();

export { FavoritesAPI, FavoriteColorItem };
