import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { SearchBoxWidgetParams } from "instantsearch.js/es/widgets/search-box/search-box";
import { HitsConnectorParams } from "instantsearch.js/es/connectors/hits/connectHits";
import { InfiniteHitsWidgetParams } from "instantsearch.js/es/widgets/infinite-hits/infinite-hits";
import { hits, index, configure } from "instantsearch.js/es/widgets";
import {
  connectSearchBox,
  connectHits,
  connectInfiniteHits,
} from "instantsearch.js/es/connectors";
import { State } from "../../enum/state";
import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";
import { AlgoliaIndexNames, APPID, APPKEY } from "./config/algolia";
import { SearchResults } from "./SearchResults";

enum Session {
  siteSearch = "siteSearchQuery",
}

enum Selector {
  hook = "data-cmp-hook-searchbar",
  xfHeader = ".cmp-experiencefragment--header",
}

enum Types {
  PRODUCT_LOOKUP = "productLookup",
  SITE_SEARCH = "siteSearch",
  STANDARD = "standard",
  WALL = "wall",
}

enum Classnames {
  resultsEmpty = "cmp-searchbar__results-inner-container-no-results",
  resultsInnerContainer = "cmp-searchbar__results-inner-container",
  resultsItem = "cmp-searchbar__results-item",
  resultsList = "cmp-searchbar__results-list",
  autocompleteItem = "cmp-searchbar__autocomplete-item",
}

class SearchBar {
  queryParamKey = "q";
  component: HTMLElement;
  id: string;

  form: HTMLFormElement;
  loadingIndicator: HTMLElement;
  resultsContainer: HTMLElement;
  clearButton: HTMLElement;
  toggleButton: HTMLElement;
  submitButton: HTMLElement;
  searchInputField: HTMLInputElement;
  searchResultsComponent: SearchResults;

  // 1.1 search
  isOnePointOne = false;
  searchInputOnePointOne: HTMLInputElement;
  clearBtnOnePointOne: HTMLElement;

  type: string;
  isStandardSearch = false;
  isSiteSearch = false;
  isProductLookup = false;
  isWallSearch = false;
  searchURL: string;
  siblingSearchbarCount = 0;
  useAlgolia = false;
  initialQuery = "";
  noResultsText = "";
  searchResultsContainerId = null;
  isAutocompleteActive = false;
  isAutocompleteNewlyOpened = false;
  algoliaIndexes: Record<string, string>;
  activatedAlgoliaForSearchBar = false;
  activatedAlgoliaForSearchResults = false;

  algoliaClient = null;

  private expanded = false;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    this.id = this.component.getAttribute("id");
    this.type = this.component.dataset?.searchbarType || "standard";
    this.useAlgolia = this.component.dataset?.useAlgolia == "true";
    this.noResultsText = this.component.dataset?.noResultsText;
    this.searchResultsContainerId =
      this.component.dataset?.searchresultsContainerId;

    if (this.useAlgolia) {
      this.algoliaIndexes = {
        autocomplete:
          this.component?.dataset?.algoliaIndexAutocomplete ||
          AlgoliaIndexNames.autoComplete,
        color:
          this.component.dataset.algoliaIndexColor || AlgoliaIndexNames.color,
        colorfamily:
          this.component.dataset.algoliaIndexColorfamily ||
          AlgoliaIndexNames.colorFamily,
        content:
          this.component.dataset.algoliaIndexContent ||
          AlgoliaIndexNames.content,
        product:
          this.component.dataset.algoliaIndexProduct ||
          AlgoliaIndexNames.product,
      };
    }

    if (this.searchResultsContainerId) {
      const searchResultsContainer = document.querySelector(
        `#${this.searchResultsContainerId}`,
      );
      this.searchResultsComponent = new SearchResults(
        searchResultsContainer as HTMLElement,
      );
    }

    // Force type to be Types.SITE_SEARCH when inside of the Header Experience Fragment.
    if (this.component.closest(Selector.xfHeader)) {
      this.type = Types.SITE_SEARCH;
    }

    // Set properties based on the search bar type. See apps/cbg-platform/components/platform/searchbar/_cq_dialog/.content.xml for defined types.
    this.isProductLookup = this.type === Types.PRODUCT_LOOKUP;
    this.isSiteSearch = this.type === Types.SITE_SEARCH;
    this.isStandardSearch = this.type === Types.STANDARD;
    this.isWallSearch = this.type === Types.WALL;

    // When other search bars of the same type are found and its not this one, take account of it.
    const otherSearchBarsSelector = `[data-cbg-cmp="searchbar"][data-searchbar-type="${this.type}"]:not(#${this.id})`;
    this.siblingSearchbarCount = document.querySelectorAll(
      otherSearchBarsSelector,
    ).length;

    // Set the element properties.
    this.toggleButton = this.component.querySelector(
      `[${Selector.hook}="toggle"]`,
    );
    this.clearButton = this.component.querySelector(
      `[${Selector.hook}="clear"]`,
    );

    this.clearBtnOnePointOne = this.component.querySelector(
      `[${Selector.hook}="clear-one-one"]`,
    );

    this.searchInputField = this.component.querySelector(
      `[${Selector.hook}="input"]`,
    );

    this.searchInputOnePointOne = this.component.querySelector(
      `[${Selector.hook}="input-1-point-1"]`,
    );

    this.submitButton = this.component.querySelector(
      `[${Selector.hook}="submit"]`,
    );

    this.form = this.component.querySelector(`[${Selector.hook}="form"]`);
    this.resultsContainer = this.component.querySelector(
      `[${Selector.hook}="results"]`,
    );
    this.loadingIndicator = this.component.querySelector(
      `[${Selector.hook}="loadingIndicator"]`,
    );

    // 1.1 search
    this.isOnePointOne = this.component.classList.contains(
      "search-one-point-one",
    );
    this.searchURL = this.component.dataset.cmpSearchurl;

    const searchParams = new URLSearchParams(location.search);
    this.initialQuery = searchParams.get("q");

    // Initialize Algolia search bar functionality.
    if (this.useAlgolia) {
      this.initializeAlgolia();
    }

    // Insert saved query into the search bar when available.
    if (this.isSiteSearch) {
      const savedValue = Cookie.get(Session.siteSearch);
      if (savedValue && this.searchInputField) {
        this.searchInputField.value = savedValue;
        Cookie.delete(Session.siteSearch);
      }
    }

    // Update the input field to use the initialQuery value.
    if (this.initialQuery && this.searchInputField) {
      this.searchInputField.value = this.initialQuery;
    }

    // If there is an input value, call function to display clear x
    if (this.searchInputField !== null) {
      if (this.searchInputField.value !== "") {
        this.updateClearButtonState(true);
      }
    }

    this.registerEventHandlers();

    this.registerMessageSubscribers();
  }

  /*
   * Initializes the Algolia Integration for Search Bar and Search Results
   *  See https://www.algolia.com/doc/guides/building-search-ui/installation/js/#with-a-build-system
   */
  private initializeAlgolia(activateSearchBar = false) {
    if (!this.algoliaClient) {
      this.algoliaClient = algoliasearch(APPID, APPKEY);
    }

    if (activateSearchBar && !this.activatedAlgoliaForSearchBar) {
      this.instantiateSearchBarWidgets();
      this.activatedAlgoliaForSearchBar = true;
    }

    if (!this.activatedAlgoliaForSearchResults && this.searchResultsComponent) {
      this.instantiateSearchResultsWidgets();
      this.activatedAlgoliaForSearchResults = true;
    }
  }

  private instantiateSearchBarWidgets() {
    const search = instantsearch({
      indexName: this.algoliaIndexes.autocomplete,
      searchClient: this.algoliaClient,
    });

    const cbgAlgoliaSearchBox = connectSearchBox(this.renderAlgoliaSearchBox);

    const rootClass = [`${Classnames.resultsInnerContainer}`];
    if (this.initialQuery) {
      rootClass.push(`${State.ACTIVE}`);
    }

    // Add the Algolia widgets:
    //  - A custom Algolia searchBox widget
    //    https://www.algolia.com/doc/api-reference/widgets/search-box/js/#create-and-instantiate-the-custom-widget
    //  - An Algolia hits widget
    //    https://www.algolia.com/doc/api-reference/widgets/hits/js/                                                                                                                             }*/
    search?.addWidgets([
      cbgAlgoliaSearchBox(<SearchBoxWidgetParams>{
        container: this.form,
        componentId: this.id,
        queryHook: this.debounce((query, search) => {
          if (query.length >= 3) {
            search(query);
          }
        }, 300),
      }),

      hits({
        container: this.resultsContainer,
        templates: {
          item: `
          <a href="/${this.searchURL}?${this.queryParamKey}={{ query }}" data-search-query="{{ query }}" class="${Classnames.autocompleteItem}">
            {{{ _highlightResult.query.value }}}
          </a>`,
          //add "for <q>{{ query }}</q>" to empty string if you want to add query
          empty: `
          ${this.noResultsText.replace("%term%", "{{ query }}")}
        `,
        },
        cssClasses: {
          emptyRoot: Classnames.resultsEmpty,
          root: rootClass,
          item: Classnames.resultsItem,
          list: Classnames.resultsList,
        },
        transformItems: (items) => {
          try {
            // Sort the results alphabetically/
            return items.sort((a, b) => {
              let order = 0;
              if (a.query && b.query) {
                if (a.query > b.query) order = 1;
                if (a.query < b.query) order = -1;
              }
              return order;
            });
          } catch (e) {
            console.warn("SearchBar Error: Sorting results", e);
          }
        },
      }),
      configure({
        query: this.initialQuery || "",
      }),
    ]);

    search.start();
  }

  private instantiateSearchResultsWidgets() {
    const search = instantsearch({
      indexName: this.algoliaIndexes.color,
      searchClient: this.algoliaClient,
    });

    // Prepare Algolia Widgets
    const cbgAlgoliaSearchBox = connectSearchBox(this.renderAlgoliaSearchBox);

    const hitsColor = connectInfiniteHits(
      this.searchResultsComponent.renderColorHits,
    );
    const hitsColorFamily = connectHits(
      this.searchResultsComponent.renderColorFamilyHits,
    );
    const hitsContent = connectInfiniteHits(
      this.searchResultsComponent.renderContentHits,
    );
    const hitsProduct = connectInfiniteHits(
      this.searchResultsComponent.renderProductHits,
    );

    const algoliaWidgets = [
      /**
       * Widget Configuration for the virtual search bar.
       */
      cbgAlgoliaSearchBox(<SearchBoxWidgetParams>{
        container: this.searchResultsComponent.virtualSearchBox,
        componentId: this.id,
        isVirtual: true,
      }),

      /**
       * Widget Configuration for Color Results
       */
      index({
        indexName: this.algoliaIndexes.color,
      }).addWidgets([
        hitsColor(<InfiniteHitsWidgetParams>{
          container: this.searchResultsComponent.colorHitsContainer,
          loadMoreButton: this.searchResultsComponent.colorLoadMoreButton,
          setResultCount: this.searchResultsComponent.setResultCount.bind(
            this.searchResultsComponent,
          ),
        }),
        configure({
          hitsPerPage: this.searchResultsComponent.getColorHitsPerPage(),
        }),
      ]),

      /**
       * Widget Configuration for Color Family Results
       */
      index({
        indexName: this.algoliaIndexes.colorfamily,
      }).addWidgets([
        hitsColorFamily(<HitsConnectorParams>{
          container: this.searchResultsComponent.colorFamilyHitsContainer,
          setResultCount: this.searchResultsComponent.setResultCount.bind(
            this.searchResultsComponent,
          ),
        }),
      ]),

      /**
       * Widget Configuration for Content Results
       */
      index({
        indexName: this.algoliaIndexes.content,
      }).addWidgets([
        hitsContent(<InfiniteHitsWidgetParams>{
          container: this.searchResultsComponent.contentHitsContainer,
          loadMoreButton: this.searchResultsComponent.contentLoadMoreButton,
          setResultCount: this.searchResultsComponent.setResultCount.bind(
            this.searchResultsComponent,
          ),
        }),
        configure({
          hitsPerPage: this.searchResultsComponent.getContentHitsPerPage(),
        }),
      ]),

      /**
       * Widget Configuration for Product Results
       */
      index({
        indexName: this.algoliaIndexes.product,
      }).addWidgets([
        hitsProduct(<InfiniteHitsWidgetParams>{
          container: this.searchResultsComponent.productHitsContainer,
          loadMoreButton: this.searchResultsComponent.productLoadMoreButton,
          setResultCount: this.searchResultsComponent.setResultCount.bind(
            this.searchResultsComponent,
          ),
        }),
        configure({
          hitsPerPage: this.searchResultsComponent.getProductHitsPerPage(),
        }),
      ]),

      configure({
        query: this.initialQuery || "",
      }),
    ];

    const rootClass = [`${Classnames.resultsInnerContainer}`];
    if (this.initialQuery) {
      this.searchResultsComponent.handleSearchQuery(this.initialQuery);
      rootClass.push(`${State.ACTIVE}`);
    } else {
      // Removes the configure element from the algoliaWidgets array.
      // Resolves a linting issue that prevents the configure type from
      //  being added to the widgets array; so the hackish fix is to initially
      //  add it to the array and remove it when its not needed.
      algoliaWidgets.pop();
    }

    // Add the Algolia widgets:
    //  - A custom Algolia searchBox widget
    //    https://www.algolia.com/doc/api-reference/widgets/search-box/js/#create-and-instantiate-the-custom-widget
    //  - An Algolia hits widget
    //    https://www.algolia.com/doc/api-reference/widgets/hits/js/
    search?.addWidgets(algoliaWidgets);

    search.start();
  }

  private expand() {
    if (this.isOnePointOne) {
      this.component.classList.remove("search-one-point-one");
      this.toggleButton.classList?.remove(State.DISPLAY_NONE);
      this.toggleButton.classList.add(State.DISPLAY_BLOCK);
      this.toggleButton?.addEventListener("click", this.collapse.bind(this));
    }

    this.component?.parentElement?.classList?.add(State.EXPANDED);
    this.component?.classList.add(State.EXPANDED);
    this.searchInputOnePointOne?.focus();
    this.searchInputField?.focus();
    this.expanded = true;

    if (this.useAlgolia) {
      this.showAutocompleteContainer();

      document
        .querySelector("body")
        ?.classList?.add(`searchbar-${State.EXPANDED}`);

      if (this.initialQuery) {
        Utils.msg.publish(Message.algoliaRefineResults, {
          query: this.initialQuery,
          sender: this.id,
          receiver: this.searchResultsContainerId,
        });
        this.initialQuery = null;
      }
    }
  }

  private collapse() {
    if (!this.expanded) return false;
    if (this.isOnePointOne) {
      this.searchInputField.value = "";
      this.component.classList.add("search-one-point-one");
      this.toggleButton.classList?.remove(State.DISPLAY_BLOCK);
      this.toggleButton.classList.add(State.DISPLAY_NONE);
      this.toggleButton?.addEventListener("click", this.expand.bind(this));
    }

    this.component.parentElement?.classList?.remove(State.EXPANDED);
    this.component.classList.remove(State.EXPANDED);
    this.expanded = false;
    if (this.useAlgolia) {
      if (this.searchInputField) this.searchInputField.value = "";
      if (this.resultsContainer) {
        this.resultsContainer.innerHTML = "";
        this.resultsContainer.classList.remove(State.ACTIVE);
      }

      this.updateClearButtonState(false);

      document
        .querySelector("body")
        .classList.remove(`searchbar-${State.EXPANDED}`);
    }
  }

  private handleInputFocus() {
    // When the user clicks inside of the input field, show the autocomplete results.
    if (
      this.useAlgolia &&
      this.searchResultsContainerId &&
      !this.isAutocompleteNewlyOpened
    ) {
      this.showAutocompleteContainer();
    }
  }

  private registerEventHandlers() {
    window.addEventListener("popstate", () => {
      const params = new URLSearchParams(location.search);
      const queryFromHistory = params.get(this.queryParamKey);

      if (queryFromHistory) {
        this.triggerSearch(queryFromHistory);
      }

      this.searchInputField.value = queryFromHistory;
    });

    this.toggleButton?.addEventListener("click", this.toggle.bind(this));

    this.searchInputField?.addEventListener(
      "input",
      this.handleInputUpdate.bind(this),
    );

    if (this.searchInputOnePointOne) {
      this.submitButton.addEventListener("click", (e) => {
        e.preventDefault();
        this.toggle();
      });

      this.searchInputOnePointOne?.addEventListener(
        "focus",
        this.toggle.bind(this),
      );
      this.searchInputOnePointOne?.addEventListener(
        "keypress",
        this.toggle.bind(this),
      );

      this.searchInputOnePointOne?.addEventListener(
        "click",
        ((event) => {
          event?.stopImmediatePropagation();
          this.toggle.bind(this);
        }).bind(this),
      );

      this.searchInputOnePointOne?.addEventListener(
        "input",
        this.toggle.bind(this),
      );
      this.clearBtnOnePointOne?.addEventListener(
        "click",
        ((event) => {
          // Only clear the input when the active element is the clear button to prevent false positives.
          if (
            document.activeElement &&
            document.activeElement == this.clearButton
          ) {
            event?.preventDefault();
            this.clearInputValue();
          }
        }).bind(this),
      );
    }

    this.clearButton?.addEventListener(
      "click",
      ((event) => {
        // Only clear the input when the active element is the clear button to prevent false positives.
        if (event.currentTarget === this.clearButton) {
          event?.preventDefault();
          this.clearInputValue();
          this.searchInputField.dispatchEvent(
            new Event("input", { bubbles: true }),
          );
          this.searchInputField.focus();
        }
      }).bind(this),
    );

    window.addEventListener("keydown", this.keyHandler.bind(this));
    window.addEventListener("click", this.globalClickHandler.bind(this));
    window.addEventListener("focusin", this.focusInHandle.bind(this));

    if (this.isWallSearch) {
      this.form.addEventListener(
        "submit",
        ((e) => {
          e.preventDefault();
          if (this.isWallSearch) {
            Utils.msg.publish(Message.wallSearchQuery, {
              query: this.searchInputField.value,
            });
          }
        }).bind(this),
      );
    }

    if (
      this.isStandardSearch &&
      this.useAlgolia &&
      this.searchResultsComponent
    ) {
      this.form.addEventListener("submit", (e) => {
        e.preventDefault();

        const query = this.searchInputField.value;

        // Handle search CTA.
        this.triggerSearch(query);

        // Update the page.
        this.updatePage(query);
      });
    }
  }

  private registerMessageSubscribers() {
    // Handle the clear input publish message.
    Utils.msg.subscribe(
      Message.searchClearInput,
      ((data) => {
        const { senderType } = data;
        if (this.type !== senderType) return;
        this.clearInputValue();
      }).bind(this),
    );

    // Handle the searchSyncValues message.
    Utils.msg.subscribe(
      Message.searchSyncValues,
      ((data) => {
        const { sender, value, type } = data;
        if (this.id === sender || this.type !== type) return;
        this.searchInputField.value = value;
      }).bind(this),
    );
  }

  private showAutocompleteContainer() {
    this.resultsContainer?.classList.add(State.ACTIVE);
    this.isAutocompleteActive = true;
    this.isAutocompleteNewlyOpened = true;
  }

  private hideAutocompleteContainer() {
    this.resultsContainer?.classList.remove(State.ACTIVE);
    this.isAutocompleteActive = false;
    this.isAutocompleteNewlyOpened = false;
  }

  private triggerSearch(query) {
    // Hide the autocomplete results, if they are still visible.
    if (this.isAutocompleteActive) {
      this.hideAutocompleteContainer();
    }

    // Update the Algolia results with the selected query.
    Utils.msg.publish(Message.algoliaRefineResults, {
      query,
      sender: this.id,
      receiver: this.searchResultsContainerId,
      updateResults: true,
    });
  }

  private updatePage(query) {
    // Update the input and results component with the selected query.
    this.searchInputField.value = query;
    this.searchResultsComponent.handleSearchQuery(query);

    // Update the title and history.
    const titleArray = document.title.split("|");
    let newTitle = `You searched for ${query}`;

    if (titleArray.length === 2) {
      const brand = titleArray[1].trim();
      newTitle = `You searched for ${query} | ${brand}`;
    }

    const params = new URLSearchParams(location.search);
    const currentTerm = params.get(this.queryParamKey);

    let newUrl = location.pathname;
    const newParamPair = `${this.queryParamKey}=${query}`;

    if (currentTerm) {
      const oldParamPair = `${this.queryParamKey}=${currentTerm.replace(
        /\s|%20/g,
        "+",
      )}`;
      const newParams = decodeURIComponent(params.toString()).replace(
        oldParamPair,
        newParamPair,
      );
      newUrl += `?${newParams}`;
    } else {
      // We have no current search term, but may have a blank q= as well as other query string params. In this case drop all other params
      // if (location.search.indexOf("?") > -1) {
      //   newUrl += `${location.search}&${this.queryParamKey}=${query}`;
      // } else {
      //  newUrl += `?${this.queryParamKey}=${query}`;
      // }
      newUrl += `?${this.queryParamKey}=${query}`;
    }

    window.history.pushState({}, newTitle, newUrl);

    // Trigger popstate event manually to update header searchbar
    const popState = new PopStateEvent("popstate", null);
    dispatchEvent(popState);
  }

  private globalClickHandler(event) {
    const target = <HTMLElement>event.target;
    const componentId = this.component.getAttribute("id");
    const elementIsNotWithinComponent = !target.closest(`#${componentId}`);

    // When the input is selected handle the event as a focus event.
    if (target == this.searchInputField) {
      this.handleInputFocus();
      return;
    }

    if (this.isSiteSearch) {
      // Check to see if the expanded header should be collapsed.
      if (this.expanded) {
        // Loop through the array of logo classes to determine if the logo was clicked.
        const logoClasses = ["cmp-image", "cmp-image__image", "image"];
        const isLogo =
          logoClasses.filter((_class) => target.classList.contains(_class))
            .length > 0;

        // When click detected outside of the search bar and when not clicking the logo,
        //  Then, collapse the nav
        if (elementIsNotWithinComponent && !isLogo) {
          this.collapse();
          return;
        }

        // Handle clicks outside of the SearchBar component
        if (
          "cmpHookSearchbar" in target.dataset &&
          target.dataset.cmpHookSearchbar == "results"
        ) {
          this.collapse();
          return;
        }
      }
    }

    // Don't continue if the click doesn't happen from within the component.
    if (elementIsNotWithinComponent) {
      this.hideAutocompleteContainer();
      return;
    }

    // Handle search results clicks.
    if ("searchQuery" in target.dataset) {
      event.preventDefault();
      const query = target.dataset.searchQuery;

      // When there is an accompanying Search Results component, trigger the search.
      if (this.searchResultsComponent) {
        if (target.classList.contains(Classnames.autocompleteItem)) {
          this.triggerSearch(query);

          // Update the page.
          this.updatePage(query);

          return;
        }
      }

      // When this is a global Search Bar, set the cookie.
      if (this.isSiteSearch) {
        Cookie.set(Session.siteSearch, query, 30);
      }

      // Redirect to the authored search page using the queryurl.
      location.href =
        "queryurl" in target.dataset
          ? target.dataset.queryurl
          : target.getAttribute("href");
    }
  }

  private keyHandler(evt: KeyboardEvent) {
    if (evt.key && evt.key === "Escape") {
      this.collapse();
    }
  }

  private focusInHandle(evt: FocusEvent) {
    const focusEventTarget = <HTMLElement>evt.target;
    const componentId = this.component.getAttribute("id");
    const elementIsNotWithinComponent = !focusEventTarget.closest(
      `#${componentId}`,
    );
    if (elementIsNotWithinComponent) {
      this.collapse();
    }
  }

  private toggle() {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  private clearInputValue() {
    // Clear the search input field.
    this.searchInputField.value = "";

    // Update the clear button state.
    this.updateClearButtonState(false);
  }

  private updateClearButtonState(active: boolean) {
    if (active) {
      if (this.expanded) {
        this.clearBtnOnePointOne?.classList.add(State.ACTIVE);
        this.clearBtnOnePointOne?.removeAttribute("tabindex");
      }
      this.clearButton?.classList.add(State.ACTIVE);
      this.clearButton?.removeAttribute("tabindex");
    } else {
      this.clearBtnOnePointOne?.classList.remove(State.ACTIVE);
      this.clearBtnOnePointOne?.setAttribute("tabindex", "-1");
      this.clearButton?.classList.remove(State.ACTIVE);
      const firstChild = this.resultsContainer?.firstChild as HTMLElement;
      if (firstChild && firstChild.classList) {
        firstChild.classList.remove(State.ACTIVE);
      }
      this.clearButton?.setAttribute("tabindex", "-1");
    }
  }

  private handleInputUpdate(event) {
    const target = event.target;
    const value = target.value;
    const charCount = value.trim().length;

    // Activate Autocomplete Results for Algolia if not active.
    if (value.length > 0) {
      if (this.useAlgolia && !this.activatedAlgoliaForSearchBar) {
        this.initializeAlgolia(true);
      }
    }

    // Only display the inner results container when an item is searched.
    const innerResultsContainer = this.resultsContainer?.querySelector(
      `.${Classnames.resultsInnerContainer}`,
    );

    const debounceShowResults = () => {
      clearTimeout(target._timer);
      target._timer = setTimeout(() => {
        if (charCount >= 3) {
          innerResultsContainer?.classList.add(State.ACTIVE);
        } else {
          innerResultsContainer?.classList.remove(State.ACTIVE);
        }
      }, 400);
    };

    target.addEventListener("input", debounceShowResults());

    this.updateClearButtonState(charCount > 0);

    if (this.useAlgolia) {
      if (this.searchResultsContainerId) {
        this.showAutocompleteContainer();
      }

      if (charCount >= 3) {
        Utils.msg.publish(Message.algoliaRefineResults, {
          query: value,
          sender: this.id,
          receiver: this.searchResultsContainerId,
        });
      }
    }

    // When there are siblings, publish a message with the value to sync other search bars.
    if (this.siblingSearchbarCount > 0) {
      Utils.msg.publish(Message.searchSyncValues, {
        sender: this.id,
        value,
        type: this.type,
      });
    }
  }

  /*
   * renderAlgoliaSearchBox
   *   - Called by Algolia's connectSearchBox method
   *   - Attaches Algolia event handlers to SearchBar elements
   *   - See https://www.algolia.com/doc/api-reference/widgets/search-box/js/#create-and-instantiate-the-custom-widget
   */
  private renderAlgoliaSearchBox = (renderOptions, isFirstRender) => {
    // Use destructuring to pull the refine method from the incoming renderOptions object

    const { refine, query } = renderOptions;
    const {
      componentId,
      updateResultsWithQuery,
      isVirtual = false,
    } = renderOptions.widgetParams;

    if (updateResultsWithQuery && query) {
      updateResultsWithQuery(query);
    }

    if (isFirstRender) {
      // Call Algolia's render function when the algoliaRefineResults message is published
      // - See: SearchBar.clearInputValue and SearchBar.
      Utils.msg.subscribe(Message.algoliaRefineResults, (data) => {
        const { query, sender, updateResults = false } = data;
        if ((isVirtual && !updateResults) || sender !== componentId) return;
        refine(query);
      });
    }
  };

  private debounce = (func, delay) => {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
}

export { SearchBar };
