import { Cookie } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";
import { Utils } from "../../site/scripts/utils";
import { Cart } from "./Cart";

// Core interfaces
interface CartItem {
  channelId: number;
  collection?: string;
  colorName?: string;
  colorNumber?: string;
  colors?: CartItem[] | string[];
  hexColor?: string;
  id: number;
}

interface AskValAPIResponse {
  cart: CartItem[];
  cartItems: CartItem[];
  favorites: CartItem[];
  id?: number;
  requestStatus?: string;
  token?: string;
}

// Enums for configuration
export enum ChannelId {
  lowes = 1001,
  "lowes_ca" = 1002,
  ir = 1003,
  hgsw = 1006,
  minwax = 1007,
  krylon = 1008,
}

export enum LocaleId {
  "en_US" = 1001,
  "en_CA" = 1002,
  "es_MX" = 1004,
  "fr_CA" = 1005,
}

enum Verbs {
  addToCart = "cart/add",
  addToCollection = "cart/add-collection",
  removeFromCart = "cart/delete",
  getCart = "cart/get",
  getToken = "user/token",
  addFavorite = "cart/add-favorite",
  addFavoriteCollection = "cart/add-favorite-collection",
  postShippingAddress = "order/shipping",
  confirmOrder = "order/submit",
}

class AskVal {
  private readonly domain: string;
  private readonly tokenCookie: string;
  public host: string;
  private token: string;
  private readonly pathPrefix = "cbg-cart-api/api/v3";
  private readonly brand: string;
  private isFetchingToken = false;
  private tokenFetchPromise: Promise<void> | null = null;
  private cartPromise: Promise<AskValAPIResponse> | null = null;

  constructor(host: string = "https://chip-orders.valspar.com") {
    const cart = document.querySelector("[data-cbg-cmp='cart']");

    this.domain = this.getDomain(location.hostname);
    this.brand = Utils.getCbgBrand();
    this.host = host;
    this.tokenCookie = `_avu${this.brand}`;
    this.token = Cookie.get(this.tokenCookie) || null;

    if (!cart) return;

    this.initializeCart();
  }

  private initializeCart(): void {
    this.getCart(true);
    window.addEventListener("click", this.globalClickHandler.bind(this));
  }

  private getDomain(hostName: string): string {
    const domainSegments = hostName.split(".");
    const lastIndex = domainSegments.length - 1;
    return lastIndex > 0
      ? `${domainSegments[lastIndex - 1]}.${domainSegments[lastIndex]}`
      : hostName;
  }

  private globalClickHandler(event: Event): void {
    const target = event.target as HTMLElement;
    const href = target?.getAttribute("href") || "";
    if (href.includes(`orders.${this.domain}`)) {
      window.localStorage.setItem("last-cart-view", new Date().toString());
    }
  }

  private static getIdList(items: CartItem[]): string {
    return items
      .sort((a, b) => (a.id || 0) - (b.id || 0))
      .map((item) => item.id)
      .join("~");
  }

  static getRetailerCookie(brand: string): string {
    return Cookie.get(`cbg:${brand}/cookies/retailer`);
  }

  static getLocaleId(language: string, country: string): string | undefined {
    if (!language || !country) return undefined;

    const locale = `${language}_${country.toUpperCase()}`;

    // use es_MX for US Spanish
    return locale === "es_US" ? LocaleId["es_MX"] : LocaleId[locale];
  }

  static getChannel(
    retailerCookie: string,
    brand: string,
    country: string,
  ): string {
    if (!retailerCookie && brand !== "valspar") return brand;

    const channel =
      retailerCookie?.substring(retailerCookie.lastIndexOf("/") + 1) || "lowes";

    if (channel === "independent-retailer") return "ir";
    if (channel === "lowes" && country === "ca") return `${channel}_${country}`;

    return channel;
  }

  public static getChannelId(brand: string, country: string): string {
    const retailerCookie = AskVal.getRetailerCookie(brand);
    return ChannelId[AskVal.getChannel(retailerCookie, brand, country)];
  }

  private async ensureTokenExists(): Promise<void> {
    if (this.token) return;

    if (!this.isFetchingToken) {
      this.isFetchingToken = true;
      this.tokenFetchPromise = this.setToken().finally(() => {
        this.isFetchingToken = false;
        this.tokenFetchPromise = null;
      });
    }
    await this.tokenFetchPromise;
  }

  private async setToken(): Promise<void> {
    const url = this.buildUrl(Verbs.getCart, null);
    const response = await fetch(url);
    const data = await response.json();
    this.token = data?.token;
    Cookie.setWithDomain(this.tokenCookie, this.token, 10 * 365, this.domain);
  }

  private buildUrl(
    verb: Verbs,
    token: string | null,
    ...params: Array<string | undefined>
  ): string {
    return [this.host, this.pathPrefix, verb, token, ...params]
      .filter(Boolean)
      .join("/");
  }

  public async getCart(
    cartResponsePublish = false,
  ): Promise<AskValAPIResponse> {
    await this.ensureTokenExists();

    if (this.cartPromise) return this.cartPromise;

    const url = this.buildUrl(Verbs.getCart, this.token);

    this.cartPromise = fetch(url, { cache: "no-cache" })
      .then((res) => res.json())
      .then((response) => {
        const transformedResponse = this.transformResponseData(response);
        if (cartResponsePublish) {
          Utils.msg.publish(Message.getCartResponse, transformedResponse);
        }
        return transformedResponse;
      })
      .catch((error) => {
        console.error("Error fetching cart:", error);
        throw error;
      })
      .finally(() => {
        this.cartPromise = null;
      });

    return this.cartPromise;
  }

  private transformResponseData(
    response: AskValAPIResponse,
  ): AskValAPIResponse {
    const transformedResponse = JSON.parse(JSON.stringify(response));

    const transformItems = (items: CartItem[] = []): CartItem[] => {
      return items
        .map((item) => ({
          ...item,
          colors: item.colors?.map((color) =>
            typeof color === "string" ? color : color.hexColor,
          ),
        }))
        .reverse();
    };

    transformedResponse.cart = transformItems(transformedResponse.cart);
    transformedResponse.cartItems = transformItems(
      transformedResponse.cartItems,
    );
    transformedResponse.favorites = transformItems(
      transformedResponse.favorites,
    );

    return transformedResponse;
  }

  async removeFromCart(itemId: number): Promise<AskValAPIResponse | null> {
    if (!itemId || itemId < 1) {
      console.warn("Invalid item ID for cart removal");
      return null;
    }

    await this.ensureTokenExists();
    const url = this.buildUrl(Verbs.removeFromCart, this.token, String(itemId));

    try {
      const response = await fetch(url, { method: "DELETE" });
      const data = await response.json();
      const transformedResponse = this.transformResponseData(data);

      Utils.msg.publish(Message.removeFromCartResponse, transformedResponse);
      return transformedResponse;
    } catch (error) {
      console.error("Error removing from cart:", error);
      return null;
    }
  }

  async addToCart(
    itemId: string,
    channelId: string,
    localeId?: string,
    collection?: string,
    favoriteType?: "color" | "collection",
  ): Promise<AskValAPIResponse | null> {
    if (!itemId) {
      console.warn("No item ID provided for cart addition");
      return null;
    }

    await this.ensureTokenExists();

    const verb =
      favoriteType === "color" ? Verbs.addToCart : Verbs.addToCollection;
    const url = this.buildUrl(
      verb,
      this.token,
      itemId,
      channelId,
      localeId,
      collection,
    );

    try {
      const response = await fetch(url, { method: "POST" });
      const data = await response.json();

      // If status is 400, merge with latest cart data
      const responseData =
        response.status === 400 ? { ...data, ...(await this.getCart()) } : data;
      const transformedResponse = this.transformResponseData(responseData);
      Utils.msg.publish(Message.addToCartResponse, transformedResponse);
      return transformedResponse;
    } catch (error) {
      console.error("Error adding to cart:", error);
      return null;
    }
  }

  async addFavorite(
    itemId: string,
    favoriteType: "color" | "collection",
    channelId: string,
    localeId?: string,
  ): Promise<AskValAPIResponse | null> {
    if (!itemId) {
      console.warn("No item ID provided for favorite addition");
      return null;
    }

    await this.ensureTokenExists();

    const verb =
      favoriteType === "color"
        ? Verbs.addFavorite
        : Verbs.addFavoriteCollection;
    const url = this.buildUrl(verb, this.token, itemId, channelId, localeId);

    try {
      const response = await fetch(url, { method: "POST" });
      const data = await response.json();
      const transformedResponse = this.transformResponseData(data);
      //Message.addFavoritesResponse, event triggers the favorites badge count on header only.
      Utils.msg.publish(Message.addFavoritesResponse, transformedResponse);
      return transformedResponse;
    } catch (error) {
      console.error("Error adding favorite:", error);
      return null;
    }
  }

  public async orderToInventory(): Promise<any> {
    await this.ensureTokenExists();
    const url = this.buildUrl(Verbs.confirmOrder, this.token);

    try {
      const response = await fetch(url, { method: "POST" });
      if (!response.ok) {
        throw new Error(`Inventory API error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error sending order to inventory:", error);
      throw error;
    }
  }

  async removeFromFavorites(itemId: number): Promise<AskValAPIResponse | null> {
    if (!itemId || itemId < 1) {
      console.warn("Cannot remove from Favorites: No valid item provided.");
      return null;
    }

    await this.ensureTokenExists();
    const url = this.buildUrl(Verbs.removeFromCart, this.token, String(itemId));

    try {
      const response = await fetch(url, { method: "DELETE" });
      const data = await response.json();
      const transformedResponse = this.transformResponseData(data);

      Utils.msg.publish(
        Message.removeFromFavoritesResponse,
        transformedResponse,
      );
      return transformedResponse;
    } catch (error) {
      this.showFailedMessage(
        "Failed to remove favorite. Please try again later.",
      );
      throw error;
    }
  }

  async postShippingAddress(
    selectedData: Record<string, any>,
  ): Promise<AskValAPIResponse> {
    await this.ensureTokenExists();
    const url = this.buildUrl(Verbs.postShippingAddress, this.token);

    try {
      const response = await fetch(url, {
        method: "POST",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(selectedData),
      });

      return await response.json();
    } catch (error) {
      console.error("Error in postShippingAddress:", error);
      throw error;
    }
  }

  private showFailedMessage(message: string): void {
    Cart.showModal();
    const messageElem = document.querySelector(
      ".cart--content-container__message",
    );
    if (messageElem) {
      messageElem.textContent = message;
    }
  }
}

// Initialize cart API instance
const cartElement = document.querySelector<HTMLElement>(
  '[data-cbg-cmp="cart"]',
);
const apiEndpoint = cartElement?.dataset.endpoint;
let CartAPI: AskVal | null = null;

if (Utils.getCartVersionBoolean()) {
  CartAPI = new AskVal(apiEndpoint);
}

export { AskVal, CartAPI, CartItem };
