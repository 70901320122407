import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";

enum Classnames {
  hasOptions = "has__options",
  hideOptions = "hide-options",
  hideAdditionalOptions = "hide-additional-options",
  displayNone = "display-none",
  colorFilterHidden = "filter-option--color-hidden",
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-filter-option",
}

// Data type for which select group radios belong to: retail, category etc

class FilterOption {
  // Elements
  toggleButton: HTMLElement;
  showMoreButton: HTMLElement;
  showLessButton: HTMLElement;
  additionalHiddenOptions: HTMLElement;
  component: HTMLElement;
  colorFilters: NodeListOf<HTMLElement>;
  filterContainer: HTMLElement;

  // Checkboxes
  checkboxes: NodeListOf<HTMLElement>;
  radioEl: NodeListOf<HTMLElement>;

  // Radio Buttons
  radioButtons: NodeListOf<HTMLElement>;

  // Property
  showLimit = -1;
  style = "none";
  cookieCategory: string;
  storedCookie: string;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    this.toggleButton = this.component.querySelector(
      `[${Selector.hook}="toggle-options-button"]`,
    );

    this.cookieCategory = this.component.dataset.cookieCategory;
    this.selectOptionFromCookie();

    this.showMoreButton =
      this.component.querySelector(`[${Selector.hook}="show-more"]`) || null;

    this.showLessButton =
      this.component.querySelector(`[${Selector.hook}="show-less"]`) || null;

    this.additionalHiddenOptions =
      this.component.querySelector(`[${Selector.hook}="hidden-options"]`) ||
      null;

    this.checkboxes = this.component.querySelectorAll(
      `[${Selector.hook}="checkbox"]`,
    );

    this.radioButtons = this.component.querySelectorAll(
      `[${Selector.hook}="radio"]`,
    );

    this.colorFilters = this.component.querySelectorAll(
      `[${Selector.hook}="color-filter"]`,
    );

    this.filterContainer = this.component.querySelector(
      `[${Selector.hook}="toggle-options-filters"]`,
    );

    this.manageFocusableElements();

    const showLimitValue = +this.component.dataset.showLimit;
    if (!isNaN(showLimitValue) && showLimitValue > 0) {
      this.showLimit = showLimitValue;
    }

    this.style = this.component.dataset.style;
    if (this.style == "style-color" && this.showLimit > 0) {
      // Hide the extra filters.
      this.updateVisibleColorFilters("hide");
    }

    this.registerEventHandlers();
    this.registerMessageSubscribers();

    Utils.ready(() => {
      this.handlePrecheckedInputs();
    });
  }

  private updateInertState(
    element: HTMLElement,
    inertChildElement: HTMLElement = null,
  ) {
    if (!element) return;

    const isHidden =
      element.classList.contains("hide-options") ||
      element.classList.contains("hide-additional-options");
    const inertElement = inertChildElement || element;

    if (isHidden) {
      inertElement.setAttribute("inert", "");
    } else {
      inertElement.removeAttribute("inert");
    }
  }

  private toggleTabindex(element: HTMLElement, isHidden: boolean) {
    if (isHidden) {
      element.setAttribute("tabindex", "-1");
    } else {
      element.removeAttribute("tabindex");
    }
  }

  private updateFocusableState(
    element: HTMLElement,
    inertChildElement: HTMLElement = null,
  ) {
    if (!element) return;

    const isHidden =
      element.classList.contains("hide-options") ||
      element.classList.contains("hide-additional-options");
    const inertElement = inertChildElement || element;
    const isButton = inertElement?.tagName === "BUTTON";

    if (isButton) {
      this.toggleTabindex(inertElement, isHidden);
    } else {
      const inertInputs =
        inertElement.querySelectorAll<HTMLInputElement>("input");

      inertInputs.forEach((input) => {
        this.toggleTabindex(input, isHidden);
      });
    }
  }

  private updateInertElements() {
    this.updateInertState(this.component, this.filterContainer);
    this.updateInertState(this.additionalHiddenOptions);
    this.updateInertState(this.showMoreButton);
    this.updateInertState(this.showLessButton);
  }

  private updateFocusableElements() {
    this.updateFocusableState(this.component, this.filterContainer);
    this.updateFocusableState(this.additionalHiddenOptions);
    this.updateFocusableState(this.showMoreButton);
    this.updateFocusableState(this.showLessButton);
  }

  private manageFocusableElements() {
    const supportsInert = "inert" in HTMLElement.prototype;

    if (supportsInert) {
      this.updateInertElements();
    } else {
      this.updateFocusableElements();
    }
  }

  getFocusable;

  private selectOptionFromCookie() {
    if (this.cookieCategory) {
      const thiscookie = Cookie.get(this.cookieCategory);
      if (thiscookie) {
        this.storedCookie =
          "cbg:" + thiscookie.substring(thiscookie.lastIndexOf("/") + 1);
      }
    }
  }

  // Added "subtype" property below to account for select multiple colors in filtering
  private sendMessageForInput(input) {
    const { name, checked, id, value, type } = input;

    if (input.classList.contains("filter-color")) {
      checked == true
        ? input.parentNode.classList.add("activeClass")
        : input.parentNode.classList.remove("activeClass");
    }
    const subtype = input.dataset.filterOptionSubtype || null;
    const groupId = input.dataset?.groupId;
    const subColor = input.dataset?.groupColor;
    Utils.msg.publish(Message.filterChanged, {
      type,
      subtype,
      checked,
      id,
      name,
      value,
      groupId,
      subColor,
    });
  }

  private handleInputChange(evt) {
    const target = evt.target;
    this.sendMessageForInput(target);
  }

  private setAriaExpanded(element: HTMLElement) {
    if (!element) return;

    const isExpanded = element.getAttribute("aria-expanded") === "true";
    element.setAttribute("aria-expanded", (!isExpanded).toString());
  }

  private registerEventHandlers() {
    if (this.toggleButton) {
      this.toggleButton.addEventListener(
        "click",
        (() => {
          this.component.classList.toggle(Classnames.hideOptions);
          this.setAriaExpanded(this.toggleButton);
          this.manageFocusableElements();
        }).bind(this),
      );
    }

    if (this.showMoreButton) {
      this.showMoreButton.addEventListener(
        "click",
        ((e) => {
          e.stopPropagation();

          // Handle showing more color filters, when applicable.
          this.updateVisibleColorFilters("show");

          // Toggle the show more, show less, and additional filter classes as needed.
          this.showMoreButton?.classList.toggle(
            Classnames.hideAdditionalOptions,
          );
          this.showLessButton?.classList.toggle(
            Classnames.hideAdditionalOptions,
          );
          this.additionalHiddenOptions?.classList.toggle(
            Classnames.hideAdditionalOptions,
          );
          const firstAdditionalOption =
            this.additionalHiddenOptions.querySelector<HTMLElement>(
              "li:first-child .filter-checkbox-variations__input",
            );

          this.manageFocusableElements();
          firstAdditionalOption.focus();
        }).bind(this),
      );
    }

    if (this.showLessButton) {
      this.showLessButton.addEventListener(
        "click",
        ((e) => {
          e.stopPropagation();

          // Handle showing more color filters, when applicable.
          this.updateVisibleColorFilters("hide");

          // Toggle the show more, show less, and additional filter classes as needed.
          this.showMoreButton?.classList.toggle(
            Classnames.hideAdditionalOptions,
          );
          this.showLessButton?.classList.toggle(
            Classnames.hideAdditionalOptions,
          );
          this.additionalHiddenOptions?.classList.toggle(
            Classnames.hideAdditionalOptions,
          );
          this.manageFocusableElements();
          this.showMoreButton.focus();
        }).bind(this),
      );
    }
    // if checkboxes, listen for change event on them then call method that handles input change
    // To handle space and enter for ADA Accesbility
    if (this.checkboxes) {
      this.checkboxes.forEach((el) => {
        el.addEventListener("change", this.handleInputChange.bind(this));
        el.addEventListener(
          "keydown",
          this.handleKeyPressonCheckbox.bind(this),
        );
      });
    }
    // if radios, listen for change event on them then call method that handles input change
    if (this.radioButtons) {
      // Store radio buttons in an array and extract the default checked element
      const radioArray = Array.prototype.slice.call(this.radioButtons);
      const defaultRadio = radioArray.filter((el) => {
        if (el.defaultChecked) {
          return el;
        }
      });
      //set radioEl to default checked radio and listen for change event
      this.radioEl = defaultRadio;
      this.radioButtons.forEach((el) => {
        el.addEventListener("change", this.handleInputChange.bind(this));
      });
    }
  }

  updateCookieView() {
    this.handlePrecheckedInputs();
  }

  private handleKeyPressonCheckbox(event) {
    //On enter or space update the checked value and manually trigger change
    if (event.key === "Enter") {
      event.target.checked = !event.target.checked;
      event?.target.dispatchEvent(new Event("change"));
    }
  }

  private updateVisibleColorFilters(state = "hide") {
    // Hide the extra filters.
    this.colorFilters?.forEach((filter, index) => {
      const hasHiddenClassname = filter.classList.contains(
        Classnames.colorFilterHidden,
      );
      const isHiddenColor = index + 1 > this.showLimit || hasHiddenClassname;

      switch (state) {
        case "hide":
          if (isHiddenColor) {
            filter.classList.add(Classnames.displayNone);
            if (hasHiddenClassname) {
              filter.classList.add(Classnames.colorFilterHidden);
            }
          }
          break;
        case "show":
          filter.classList.remove(Classnames.displayNone);
          break;
      }
    });
  }

  private uncheckCheckboxForFilter(filter) {
    const { id } = filter;

    const filterOptions: HTMLElement[] = Array.from(
      document.querySelectorAll(`[id='${id}']`),
    );
    const el: HTMLElement[] = filterOptions.filter((item) => {
      return item.dataset.groupId === filter.groupId;
    });

    if (el) {
      try {
        const input = <HTMLInputElement>el[0];
        const uncheckEvt = new Event("change");
        input.checked = false;
        input.dispatchEvent(uncheckEvt);
      } catch (e) {
        console.error("Error: Update input error", e);
      }
    }
  }

  private registerMessageSubscribers() {
    // Subscribe to the filterUnchecked event
    Utils.msg.subscribe(
      Message.filterUnchecked,
      this.uncheckCheckboxForFilter.bind(this),
    );

    // Subscribe to the filterUnchecked event
    Utils.msg.subscribe(
      Message.filterClearAll,
      this.clearAllFilters.bind(this),
    );

    Utils.msg.subscribe(Message.cookieUpdated, (response) => {
      this.updateCookieView();
    });
  }

  static clearMatchingInputs(inputs) {
    // if inputs, loop through and separate default checked from checked.
    if (inputs) {
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i] as HTMLInputElement;
        const groupId = input.dataset?.groupId;

        if (input.defaultChecked) {
          input.checked = true;
        } else {
          input.checked = false;
          // Remove activeClass from color filter button(s)
          input.parentElement.classList.remove("activeClass");
        }
        // store input info and send to Utils publish method
        const { type, checked, id, name, value } = input;
        Utils.msg.publish(Message.filterChanged, {
          type,
          checked,
          id,
          name,
          value,
          groupId,
        });
      }
    }
  }

  private clearAllFilters() {
    // get all checkboxes and send them to be cleared out
    FilterOption.clearMatchingInputs(this.checkboxes);

    // if there is a default radio, send it to be cleared out,
    // else send all radio to be cleared out.
    const radios = this.radioButtons ? this.radioEl : this.radioButtons;
    FilterOption.clearMatchingInputs(radios);
  }

  private sendIfChecked(el) {
    const input = <HTMLInputElement>el;
    const { checked, type, id } = input;
    if (checked) {
      this.sendMessageForInput(input);
    }
  }

  private handlePrecheckedInputs() {
    this.selectOptionFromCookie();

    if (this.checkboxes) {
      this.checkboxes.forEach((value) => {
        if (value.classList.contains(this.storedCookie)) {
          const thisone: HTMLInputElement = document.getElementById(
            value.id,
          ) as HTMLInputElement;
          if (thisone) {
            thisone.checked = true;
          }
        }
      });

      this.checkboxes.forEach(this.sendIfChecked.bind(this));
    }

    if (this.radioButtons) {
      this.radioButtons.forEach((value) => {
        if (value.classList.contains(this.storedCookie)) {
          const thisone: HTMLInputElement = document.getElementById(
            value.id,
          ) as HTMLInputElement;
          if (thisone) {
            thisone.checked = true;
          }
        }
      });

      this.radioButtons.forEach(this.sendIfChecked.bind(this));
    }
  }
}

export { FilterOption };
