class Accordion {
  component: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.component = component;

    if (!this.component) {
      return null;
    }

    setTimeout(() => {
      const bvBtnContainer = Array.from(
        document.querySelectorAll(".bv_button_component_container"),
      ) as HTMLElement[];
      const bvAskAQuestion = bvBtnContainer[1];
      const accordionItem = Array.from(
        document.querySelectorAll(".cmp-accordion__item"),
      ) as HTMLElement[];
      const findQAButtonContainer = accordionItem.filter((button) => {
        if (button.innerText === "Q&A") {
          return button;
        }
      });

      function openQuestions() {
        if (bvAskAQuestion) {
          bvAskAQuestion.addEventListener("click", function () {
            const accordion = findQAButtonContainer[0].querySelector(
              ".cmp-accordion__button",
            );
            const panel = findQAButtonContainer[0].querySelector(
              ".cmp-accordion__panel",
            );
            if (accordion && panel) {
              accordion.setAttribute("aria-expanded", "true");
              accordion.classList.add("cmp-accordion__button--expanded");

              panel.setAttribute("aria-expanded", "false");
              panel.classList.add("cmp-accordion__panel--expanded");
            }
            const getCurrentURL = window.location.href;
            const accordionId = findQAButtonContainer[0].id;
            const newUrl = `${getCurrentURL}#${accordionId}`;
            window.location.href = newUrl;
          });
        }
      }
      openQuestions();
    }, 1000);
  }
}

export { Accordion };
