import { ColorResultsContainer } from "./containers/ColorResultsContainer";
import { ColorFamilyResultsContainer } from "./containers/ColorFamilyResultsContainer";
import { ProductResultsContainer } from "./containers/ProductResultsContainer";
import { ContentResultsContainer } from "./containers/ContentResultsContainer";

import { State } from "../../enum/state";

const ResultsContainerFactory = {
  color: ColorResultsContainer,
  colorFamily: ColorFamilyResultsContainer,
  content: ContentResultsContainer,
  product: ProductResultsContainer,
};

enum Selector {
  hook = "data-cmp-hook-searchresults",
  container = "data-results-container-type",
}

class SearchResults {
  component: HTMLElement;
  header: HTMLElement;
  resultsCountContainer: HTMLElement;
  queryContainer: HTMLElement;
  noResultsText: string;
  searchPlaceholderText: string;
  resultsCount: number;
  resultsContainer: HTMLElement;
  resultsType = "standard";
  resultsCountsByType: Record<string, number>;

  // Containers
  colorHitsContainer: HTMLElement;
  colorFamilyHitsContainer: HTMLElement;
  contentHitsContainer: HTMLElement;
  productHitsContainer: HTMLElement;
  emptyResultsContainer: HTMLElement;
  noResultsTextContainer: HTMLElement;
  resultsPlaceholderContainer: HTMLElement;
  resultsPlaceholderTextContainer: HTMLElement;
  virtualSearchBox: HTMLElement;

  // Load More Buttons
  colorLoadMoreButton: HTMLButtonElement;
  contentLoadMoreButton: HTMLButtonElement;
  productLoadMoreButton: HTMLButtonElement;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    const resultsCountFromData =
      this.component.dataset["data-cmp-hook-searchresults-count"];
    this.resultsCountsByType = {};
    this.resultsCount = isNaN(parseFloat(resultsCountFromData))
      ? 0
      : parseFloat(resultsCountFromData);
    this.resultsContainer = this.component.querySelector(
      `[${Selector.hook}="results-container"]`,
    );
    this.header = this.component.querySelector(`[${Selector.hook}="header"]`);
    this.resultsCountContainer = this.component.querySelector(
      `[${Selector.hook}="resultscount"]`,
    );
    this.queryContainer = this.component.querySelector(
      `[${Selector.hook}="searchquery"]`,
    );
    this.emptyResultsContainer = this.component.querySelector(
      `[${Selector.hook}="no-results-container"]`,
    );
    this.noResultsTextContainer = this.component.querySelector(
      `[${Selector.hook}="no-results-text"]`,
    );
    this.noResultsText = this.noResultsTextContainer?.dataset?.emptyResultsText;
    this.virtualSearchBox = this.component.querySelector(
      `[${Selector.hook}="virtual-searchbox"]`,
    );
    this.resultsPlaceholderContainer = this.component.querySelector(
      `[${Selector.hook}="results-placeholder-container"]`,
    );
    this.resultsPlaceholderTextContainer = this.component.querySelector(
      `[${Selector.hook}="search-results-placeholder"]`,
    );
    this.searchPlaceholderText =
      this.resultsPlaceholderTextContainer?.dataset?.searchPlaceholderText;

    // Initialize Containers
    this.colorHitsContainer = this.component.querySelector(
      `[${Selector.container}="color"]`,
    );
    this.colorFamilyHitsContainer = this.component.querySelector(
      `[${Selector.container}="color-family"]`,
    );
    this.contentHitsContainer = this.component.querySelector(
      `[${Selector.container}="content"]`,
    );
    this.productHitsContainer = this.component.querySelector(
      `[${Selector.container}="product"]`,
    );

    // Initialize Load More Buttons
    this.colorLoadMoreButton = this.component.querySelector(
      `[${Selector.hook}="loadmore-button--color"]`,
    );
    this.contentLoadMoreButton = this.component.querySelector(
      `[${Selector.hook}="loadmore-button--content"]`,
    );
    this.productLoadMoreButton = this.component.querySelector(
      `[${Selector.hook}="loadmore-button--product"]`,
    );

    if (this.component.dataset["data-cmp-hook-searchresults-type"]) {
      this.resultsType =
        this.component.dataset["data-cmp-hook-searchresults-type"];
    }
  }

  private updateResults(type: string, results) {
    // Grab the associated container class based on the type and call the updateWithResults method.
    const resultsContainerClass = ResultsContainerFactory[type];
    if (!resultsContainerClass) {
      return;
    }
    const resultsContainer = new resultsContainerClass(type);

    // Use the container class' results index to filter the results.
    const resultsIndex = resultsContainer.resultsIndexKey;

    // Get all results for the matching type.
    const filteredResults = results
      .filter((result) => {
        return result.index == resultsIndex;
      })
      ?.shift();

    // Update the page with the filtered results.
    resultsContainer.updateWithResults(filteredResults);
  }

  /**
   * Handle search queries.
   */
  public handleSearchQuery(query = "") {
    this.queryContainer.textContent = `"${query}"`;

    if (this.noResultsTextContainer) {
      this.noResultsTextContainer.textContent = this.noResultsText?.replace(
        "%term%",
        `"${query}"`,
      );
    }

    if (this.resultsPlaceholderTextContainer) {
      this.resultsPlaceholderTextContainer.textContent =
        this.searchPlaceholderText?.replace("%term%", `"${query}"`);
    }

    this.resultsPlaceholderContainer?.classList?.add(State.ACTIVE);
  }

  /*
   * renderColorFamilyHits
   *   - Called by Algolia's connectHits method
   *   - Attaches Algolia event handlers to the Color Family element
   *   - See https://www.algolia.com/doc/api-reference/widgets/hits/js/#connector
   */
  public renderColorFamilyHits(renderOptions, isFirstRender) {
    const { hits, results } = renderOptions;
    const { container, setResultCount } = renderOptions.widgetParams;

    let colorFamilies = false;
    let colorFamilyCount = 0;
    let colorCollections = false;
    let colorCollectionCount = 0;

    hits.forEach((hit) => {
      const hPostType = hit.post_type;
      let hColorCollectionXf = hit.color_collection_xf;

      //appending color collection card to the search results container
      if (
        hPostType != null &&
        hPostType === "colorCollection" &&
        hColorCollectionXf != null
      ) {
        colorCollections = true;
        colorCollectionCount += 1;

        hColorCollectionXf += ".html";
        try {
          fetch(hColorCollectionXf)
            .then(function (response) {
              // The API call was successful!
              return response.text();
            })
            .then(function (html) {
              // Convert the HTML string into a document object
              const parentElement = document.querySelector(
                '[data-searchresult-hook = "color-collection"]',
              );

              parentElement.insertAdjacentHTML("afterbegin", html);
            })
            .catch(function (err) {
              // There was an error
              console.warn("Could not render collection card.", err.text);
            });
        } catch (error) {
          console.warn("Issue adding Color Collection Results", error);
        }
      }
    });

    const cleanFamiliesArray = [];
    hits.forEach((hit) => {
      const postType = hit.post_type;
      if (postType != null && postType === "colorCollection") {
        // ignore
      } else {
        // add to clean collection
        colorFamilies = true;
        colorFamilyCount += 1;
        cleanFamiliesArray.push(hit);
      }
    });

    setResultCount(
      "color-family",
      colorFamilyCount + colorCollectionCount,
      isFirstRender,
    );

    if (hits.length === 0) {
      container.innerHTML = "";
      container.classList.remove(State.ACTIVE);
      container.parentElement.classList.remove(State.ACTIVE);
      return false;
    }

    if (colorFamilies === false) {
      container.innerHTML = `
          <div class="cbg-cmp-searchresult--color-collection" data-searchresult-hook="color-collection">
          </div>
      `;
    } else {
      const firstHit = cleanFamiliesArray[0];
      const slug = firstHit.color_family_slug;
      const colorFamily = firstHit.color_family;
      const viewAllColors = container.dataset.viewAllColorsText;

      container.innerHTML = `
          <div class="cbg-cmp-searchresult--color-collection" data-searchresult-hook="color-collection">
          </div>
          <div class="cbg-cmp-searchresult--color-family" data-searchresult-hook="color-family">
              <a class="display-flex" href="${slug}">
                  <div class="cbg-cmp-searchresult--color-family-swatches">
                      <div class="swatches__color-family">
                      ${cleanFamiliesArray
                        .map(
                          (color) => `
                        <div class="cbg-cmp-searchresult--color-family-swatch ${
                          cleanFamiliesArray.length < 8
                            ? "cbg-swatch-fullHeight"
                            : "cbg-swatch-adjustedHeight"
                        } cbg__hidden-text"
                            style="background-color: ${color.hex}">
                            ${color.post_title}
                        </div>
                      `,
                        )
                        .join("")}
                      </div>
                  </div>
                  <div class="cbg-cmp-searchresult--color-family-content">
                      <p class="title">${colorFamily}</p>
                      <p class="description">${viewAllColors}</p>
                  </div>
              </a>
        </div>
      `;
    }

    container.classList.add(State.ACTIVE);
    container.parentElement.classList.add(State.ACTIVE);
    container.dispatchEvent(
      new CustomEvent("searchRendered", {
        bubbles: true,
        detail: {
          searchType: "colorFamily",
          results: results,
          container: container,
        },
      }),
    );
  }

  /*
   * renderColorHits
   *   - Called by Algolia's connectInfiniteHits method
   *   - Attaches Algolia event handlers to the Color element
   *   - See https://www.algolia.com/doc/api-reference/widgets/hits/js/#connector
   */
  public renderColorHits(renderOptions, isFirstRender) {
    const { hits, showMore, isLastPage, results } = renderOptions;
    const { container, loadMoreButton, setResultCount } =
      renderOptions.widgetParams;

    if (isFirstRender) {
      loadMoreButton?.addEventListener("click", () => {
        showMore();
      });
    }

    setResultCount("color", results?.nbHits || 0, isFirstRender);

    SearchResults.updateContainer(
      container,
      loadMoreButton,
      isLastPage,
      hits.length === 0,
    );

    if (hits.length === 0) {
      container.innerHTML = "";
      container.classList.remove(State.ACTIVE);
      container.parentElement.classList.remove(State.ACTIVE);
      return false;
    }

    // Update the container markup with the hits.
    container.innerHTML = hits
      .map((color) => {
        const hex = color.hex;
        const rgb = color.rgb;
        const bgColor = hex || hex.trim() !== "" ? hex : rgb;
        const allowedRetailers = ["Independent Retailer", "Lowe's"];
        const displayRetailer = allowedRetailers.includes(color.retailer);
        const isColorOfTheYear = color.coty_word ? true : false;
        const colorOfTheYearText = color.coty_word;
        const colorSwatch = color.color_swatch_url;
        return `
        <div class="cbg-cmp-searchresult--color cbg-cmp-card__swatch-container swatch-container">
            <a href="${color.slug}">
                <div class="cbg-color-swatch" style="background-color: ${bgColor};">
                ${
                  colorSwatch != null
                    ? `
                    <img src="${colorSwatch}"/>
                  `
                    : ""
                }            
                </div>
                <div class="cbg-colorCard__info">
                    <h4 class="cbg-colorCard__color-name">${
                      color.post_title
                    }</h4>
                    <h5 class="cbg-colorCard__color-id">${
                      color.color_number
                    }</h5>
                    ${
                      isColorOfTheYear && displayRetailer
                        ? `
                    <h5 class="cbg-colorCard__color-id">${color.retailer}</h5>
`
                        : ""
                    }
                  ${
                    isColorOfTheYear && colorOfTheYearText
                      ? `
                    <div class="cbg-cmp-card__tagemblem cbg-cmp-card__tagemblem-color">
                        <div class="cbg-cmp--tag-emblem cbg-cmp--tag-emblem__long-format">
                            <span class="cbg-cmp--tag-emblem-inner display-ib">
                                <span class="cbg-cmp--tag-emblem-text">${colorOfTheYearText}</span>
                            </span>
                        </div>
                    </div>
                  `
                      : ""
                  }
                </div>
            </a>
        </div>
    `;
      })
      .join("");

    container.classList.add(State.ACTIVE);
    container.parentElement.classList.add(State.ACTIVE);
    container.dispatchEvent(
      new CustomEvent("searchRendered", {
        bubbles: true,
        detail: {
          searchType: "color",
          results: results,
          container: container,
        },
      }),
    );
  }

  /*
   * renderContentHits
   *   - Called by Algolia's connectInfiniteHits method
   *   - Attaches Algolia event handlers to the Content element
   *   - See https://www.algolia.com/doc/api-reference/widgets/hits/js/#connector
   */
  public renderContentHits(renderOptions, isFirstRender) {
    const { hits, showMore, isLastPage, results } = renderOptions;
    const { container, loadMoreButton, setResultCount } =
      renderOptions.widgetParams;

    if (isFirstRender) {
      loadMoreButton.addEventListener("click", () => {
        showMore();
      });
    }

    setResultCount("content", results?.nbHits || 0, isFirstRender);

    SearchResults.updateContainer(
      container,
      loadMoreButton,
      isLastPage,
      hits.length === 0,
    );

    if (hits.length === 0) {
      container.innerHTML = "";
      container.classList.remove(State.ACTIVE);
      container.parentElement.classList.remove(State.ACTIVE);
      return false;
    }

    // Update the container markup with the hits.
    container.innerHTML = hits
      .map(
        (hit) => `
        <div class="cbg-cmp-searchresult--content">
            <h2 class="title"><a href="${hit.slug}">${hit.post_title}</a></h2>
            <p class="description"><a href="${hit.slug}">${hit._yoast_wpseo_metadesc}</a></p>
        </div>
    `,
      )
      .join("");

    container.classList.add(State.ACTIVE);
    container.parentElement.classList.add(State.ACTIVE);
    container.dispatchEvent(
      new CustomEvent("searchRendered", {
        bubbles: true,
        detail: {
          searchType: "content",
          results: results,
          container: container,
        },
      }),
    );
  }

  /*
   * renderProductHits
   *   - Called by Algolia's connectInfiniteHits method
   *   - Attaches Algolia event handlers to the Product element
   *   - See https://www.algolia.com/doc/api-reference/widgets/hits/js/#connector
   */
  public renderProductHits(renderOptions, isFirstRender) {
    const { hits, showMore, isLastPage, results } = renderOptions;
    const { container, loadMoreButton, setResultCount } =
      renderOptions.widgetParams;

    const availableAt = container.dataset.availableAtText;

    if (isFirstRender) {
      loadMoreButton.addEventListener("click", () => {
        showMore();
      });
    }

    setResultCount("product", results?.nbHits || 0, isFirstRender);

    SearchResults.updateContainer(
      container,
      loadMoreButton,
      isLastPage,
      hits.length === 0,
    );

    if (hits.length === 0) {
      container.innerHTML = "";
      container.classList.remove(State.ACTIVE);
      container.parentElement.classList.remove(State.ACTIVE);
      return false;
    }

    // Update the container markup with the hits.
    const renderForDisplay = (retailer) => {
      return retailer
        .replace("Independent Retailer", "Independent Retailers")
        .replace(/\|/g, ", ");
    };

    container.innerHTML = hits
      .map(
        (hit) => `
        <div class="cbg-cmp-searchresult--product">
          ${
            hit.prod_image
              ? `
            <div class="img-container">
                <a href="${hit.slug}">
                    <img src="${hit.prod_image}" />
                </a>
            </div>`
              : ""
          }
          
            <div class="item-info">
             ${
               hit.post_title
                 ? `<h3 class="title"><a href="${hit.slug}">${hit.post_title}</a></h3>`
                 : ""
             }
             
             ${
               hit.prod_description
                 ? `<p class="description"><a href="${hit.slug}">${hit.prod_description}</a></p>`
                 : ""
             }
             
             ${
               hit.retailer
                 ? `<p class="description"><a href="${
                     hit.slug
                   }">${availableAt} ${renderForDisplay(hit.retailer)}</a></p>`
                 : ""
             }
            </div>
          </a>
        </div>
    `,
      )
      .join("");

    container.classList.add(State.ACTIVE);
    container.parentElement.classList.add(State.ACTIVE);
    container.dispatchEvent(
      new CustomEvent("searchRendered", {
        bubbles: true,
        detail: {
          searchType: "product",
          results: results,
          container: container,
        },
      }),
    );
  }

  public setResultCount(type: string, count: number, isFirstRender: boolean) {
    this.resultsCountsByType[type] = count;

    // Update the total results.
    this.resultsCount = Object.values(this.resultsCountsByType).reduce(
      (accumulator: number, value: number) => accumulator + value,
    );
    if (this.resultsCountContainer) {
      this.resultsCountContainer.innerHTML = `${this.resultsCount}`;
    }

    if (this.resultsCount === 0) {
      this.header?.classList?.add("display-none");
      if (!isFirstRender) {
        this.emptyResultsContainer?.classList?.add(State.ACTIVE);
        this.resultsPlaceholderContainer?.classList?.remove(State.ACTIVE);
      }
    } else {
      this.header?.classList?.remove("display-none");
      this.emptyResultsContainer?.classList?.remove(State.ACTIVE);
      this.resultsPlaceholderContainer?.classList?.remove(State.ACTIVE);
    }
  }

  public getColorHitsPerPage(): number {
    return SearchResults.getHitsPerPageForWidget(
      <HTMLElement>this.colorLoadMoreButton,
    );
  }

  public getContentHitsPerPage(): number {
    return SearchResults.getHitsPerPageForWidget(
      <HTMLElement>this.contentLoadMoreButton,
    );
  }

  public getProductHitsPerPage(): number {
    return SearchResults.getHitsPerPageForWidget(
      <HTMLElement>this.productLoadMoreButton,
    );
  }

  static getHitsPerPageForWidget(widget: HTMLElement): number {
    let hitsPerPage = 4;
    const dataValue = parseInt(widget.dataset?.hitsPerPage);
    if (!isNaN(dataValue)) {
      hitsPerPage = dataValue;
    }
    return hitsPerPage;
  }

  static updateContainer(
    container: HTMLElement,
    loadMoreButton: HTMLButtonElement,
    isLastPage: boolean,
    disabled = false,
  ): void {
    // Disable the load more button if its the last page, otherwise, enable it.
    if (loadMoreButton) loadMoreButton.disabled = isLastPage || disabled;

    if (disabled) {
      container.innerHTML = "";
      container.classList.remove(State.ACTIVE);
      container.parentElement.classList.remove(State.ACTIVE);
      return null;
    }
  }
}

export { SearchResults };
