import { Message } from "../../site/scripts/Message";
import { Cookie, Utils } from "../../site/scripts/utils";
import { AskVal, CartAPI, CartItem } from "./AskValV3";

interface CartResponse {
  cart: CartItem[];
  cartItems: CartItem[];
  favorites: CartItem[];
}

type CartCtaState = "add" | "remove";

enum Classnames {
  displayNone = "display-none",
  iconAdd = "icon-add",
  iconRemove = "icon-remove",
  loading = "loading",
  stateAdd = "cart-cta--add-state",
  stateRemove = "cart-cta--add-remove",
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-cart-cta",
}

class CartCTA {
  //Elements
  chipIcon: HTMLElement;
  chipTextAdd: HTMLElement;
  chipTextRemove: HTMLElement;
  component: HTMLElement;

  // Properties
  apiCollectionName: string;
  brand: string;
  cartCtaState: CartCtaState;
  cartResponseCookie: CartResponse;
  channelId: string;
  collection: string;
  collectionName: string;
  colorCartId: number;
  colorId: string;
  colorName: string;
  country: string;
  language: string;
  locale: string;
  tags: string;
  isV3Brand: boolean;
  currentBrand: string;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    const dataset = this.component.dataset;

    this.apiCollectionName = dataset.apiCollectionName;
    this.brand = dataset.brand || "valspar";
    this.channelId = dataset.channelId;
    this.collection = dataset.collection;
    this.collectionName = dataset.collectionName;
    this.colorId = dataset.colorId;
    this.colorName = dataset.colorName || this.colorId;
    this.country = dataset.country || "us";
    this.language = dataset.language || "en";
    this.locale = dataset.locale;
    this.tags = dataset.colorTags;

    this.component.setAttribute("data-cmp-init", "true");
    this.currentBrand = Utils.getCbgBrand();
    this.isV3Brand = Utils.getCartVersionBoolean();

    this.initializeData();
    this.initializeUI();
    this.registerEventHandlers();
    this.registerMessageSubscribers();
  }

  private initializeData() {
    if (this.isV3Brand) {
      // Fetch the cart data directly using the API method
      (async () => {
        try {
          const cartResponse = await CartAPI.getCart();
          this.cartResponseCookie = cartResponse || {
            cart: [],
            cartItems: [],
            favorites: [],
          };
          this.updateComponentState(this.cartResponseCookie);
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      })();
    }
  }
  private initializeUI(): void {
    this.chipTextAdd = this.component.querySelector(
      `[${Selector.hook}="chip-text-add"]`,
    );

    this.chipTextRemove = this.component.querySelector(
      `[${Selector.hook}="chip-text-remove"]`,
    );

    this.chipIcon = this.component.querySelector(
      `[${Selector.hook}="chip-icon"]`,
    );
  }

  private registerEventHandlers(): void {
    this.component?.addEventListener("click", this.initiateRequest.bind(this));
  }

  private registerMessageSubscribers(): void {
    // Subscribe to the color chip update publisher.
    Utils.msg.subscribe(
      Message.addToCartResponse,
      this.updateComponentState.bind(this),
    );

    Utils.msg.subscribe(
      Message.removeFromCartResponse,
      this.updateComponentState.bind(this),
    );

    Utils.msg.subscribe(
      Message.getCartResponse,
      this.updateComponentState.bind(this),
    );
  }

  private updateComponentState(response: CartResponse): void {
    // Check whether the color is in the "cart" array.
    const cartItems = this.isV3Brand
      ? response.cart || []
      : response.cartItems || [];

    const itemFromCartItems = (
      cartItems.filter(
        (item) =>
          item.colorNumber === this.colorId ||
          item.colorNumber === this.collection,
      ) || []
    ).pop();
    const isInCartItems =
      itemFromCartItems != null || typeof itemFromCartItems != "undefined";

    // Update the state of the button.
    this.cartCtaState = isInCartItems ? "remove" : "add";

    // Update the UI of the button.
    this.updateUI(this.cartCtaState);

    // Update the colorCartId, if needed.
    this.colorCartId = itemFromCartItems?.id || 0;

    // Remove the loading class.
    this.component.classList.remove(Classnames.loading);
  }

  private updateUI(cartCtaState: CartCtaState): void {
    const inverseCartCtaState: CartCtaState =
      cartCtaState === "add" ? "remove" : "add";

    // Clean up for proper class state, need to look at diff for better solution
    this.component?.classList[cartCtaState](Classnames.stateAdd);
    this.component?.classList[inverseCartCtaState](Classnames.stateRemove);

    // Show the add text.
    this.chipTextAdd?.classList[inverseCartCtaState](Classnames.displayNone);

    // Hide the remove text.
    this.chipTextRemove?.classList[cartCtaState](Classnames.displayNone);

    // Update the icon class.
    this.chipIcon?.classList[cartCtaState](Classnames.iconAdd);
    this.chipIcon?.classList[inverseCartCtaState](Classnames.iconRemove);
  }

  private initiateRequest(): void {
    // Add a loading class
    this.component.classList.add(Classnames.loading);

    // Make the request based on the state.
    let dataToSend = null;
    let itemType: "color" | "collection" = "color";

    switch (this.cartCtaState) {
      case "add":
        dataToSend = this.colorId || null;

        if (dataToSend == null && this.collection?.length > 0) {
          dataToSend = this.collection;
          this.colorName = this.collection;
          itemType = "collection";
        }

        if (dataToSend) {
          if (this.channelId == null) {
            this.channelId = AskVal.getChannelId(this.brand, this.country);
          }

          // Send a message that this color is being added.
          Utils.msg.publish(Message.addToCart, { colorName: this.colorName });

          // Send the request to the API.
          CartAPI.addToCart(
            dataToSend,
            this.channelId,
            this.locale || AskVal.getLocaleId(this.language, this.country),
            this.apiCollectionName,
            itemType,
          ).then((_res) => {
            return;
          });
        }
        break;
      case "remove":
        // Send a message that this color is being removed.
        Utils.msg.publish(Message.removeFromCart, {
          colorName: this.colorName,
        });

        // Send the request to the API.
        CartAPI.removeFromCart(+this.colorCartId).then((_res) => {
          return;
        });
        break;
      default:
        console.warn(
          "Couldn't figure out the button state. No request sent!",
          this.cartCtaState,
        );
    }
  }
}

export { CartCTA };
