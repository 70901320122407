import { FilterOption } from "./FilterOption";
import { OptionsButton } from "./OptionsButton";

// Initialize all options.
document.querySelectorAll('[data-cbg-cmp="filterOption"]').forEach((el) => {
  new FilterOption(<HTMLElement>el);
});

// Initialize option button.
document.querySelectorAll("[data-shows-options]").forEach((el) => {
  new OptionsButton(<HTMLElement>el);
});
