class ColorVisualizer {
  private window = window as any;
  private prismComponent = this.window.PRISM;
  private readonly brand: string;
  private readonly buyNowButtonText: string;
  private readonly buyNowUrlString: string;
  private readonly container: HTMLElement;

  constructor(component: HTMLElement) {
    this.container = component;
    this.brand = this.container.dataset.brand;
    this.buyNowButtonText = this.container.dataset.buyNowButtonText;

    //Implemented Buy Now button Jan-10-2025
    this.prismComponent.embed(this.container, this.getBuyNowScript());
  }

  private getBuyNowUrl(
    brand: string,
    colorName: string,
    brandKey: string,
    colorNumber: string,
  ): string {
    let result: string;
    switch (brand) {
      case "hgtv":
        result = `https://www.lowes.com/configure/paint/${colorName}/${brandKey}${colorNumber}?paintType=Interior&sheenType=Satin&sizeType=1-gallon`;
        break;
      default:
        result = "";
    }
    return result;
  }

  private getBuyNowScript(): Record<string, any> {
    return {
      bindCallback: ({ publish, subscribe }) => {
        subscribe("prism-new-color", (color) => {
          publish("PRISM/in/update-color-ctas", [
            {
              text: `${this.buyNowButtonText}`,
              link: this.getBuyNowUrl(
                this.brand,
                color.name,
                color.brandKey,
                color.colorNumber,
              ),
              attributes: {
                target: "_blank",
              },
            },
          ]);
        });
      },
    };
  }
}

export { ColorVisualizer };
