enum Classnames {
  fadeIn = "fade-in",
}

class CarouselNotificationsBanner {
  // Elements
  container: HTMLElement;
  loadDelay: number;
  eventToFireOn: string;
  focusableElements: Element[] = [];

  constructor(component: HTMLElement) {
    this.container = component;
    if (!this.container) {
      return null;
    }

    const carouselNotificationsContainer = this.container;

    const carouselNotificationsBannerBtn = this.container.querySelector(
      ".cbg-cmp-carouselNotificationsBanner-btn-close",
    );
    const nextBtn = this.container.querySelector(
      ".carousel-notification-banner-slide-arrow-next",
    );
    const prevBtn = this.container.querySelector(
      ".carousel-notification-banner-slide-arrow-prev",
    );
    const track: HTMLElement = this.container.querySelector(
      ".carouselNotificationsBanner-message-track",
    );

    const NUM_SLIDES = this.container.querySelectorAll(
      ".carouselNotificationsBanner-slide",
    ).length;

    const uniqueID = this.container.dataset.bannerId;
    const headerPadding = document.querySelector("header") as HTMLElement;

    if (sessionStorage.getItem(uniqueID + "-closed") === "true") {
      this.hideNotificationHandler(uniqueID);
      this.container.style.display = "none";
      headerPadding && (headerPadding.style.paddingTop = "0");
      return;
    } else {
      this.container.style.display = "flex";
      if (headerPadding) {
        headerPadding.style.paddingTop = "3.125rem";
        headerPadding.style.transition = "opacity 0.3s ease-in-out";
      }
    }

    carouselNotificationsBannerBtn.addEventListener("click", () => {
      this.hideNotificationHandler(uniqueID);
    });

    let currSlide = 0;
    const slideLength: number = parseInt(
      this.container.dataset.notificationsSlideLength,
    );
    const slideInterval: number = slideLength ? slideLength * 1000 : 5000;
    let autoSlide = true;

    function slideTrack() {
      track.style.transform = `translateX(${-currSlide * 100}%)`;
    }

    if (nextBtn) {
      nextBtn.addEventListener("click", () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (currSlide < NUM_SLIDES - 1) {
          currSlide += 1; // Increment slide
          autoSlide = false;
          slideTrack(); // Update slide track
        }
        toggleButtonVisibility();
      });
    }

    if (prevBtn) {
      prevBtn.addEventListener("click", () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (currSlide > 0) {
          currSlide -= 1; // Decrement slide
          autoSlide = false;
          slideTrack(); // Update slide track
        }
        toggleButtonVisibility();
      });
    }

    // Function to toggle visibility of buttons
    function toggleButtonVisibility() {
      if (nextBtn) {
        (nextBtn as HTMLElement).style.display =
          currSlide < NUM_SLIDES - 1 ? "block" : "none";
      }
      if (prevBtn) {
        (prevBtn as HTMLElement).style.display =
          currSlide > 0 ? "block" : "none";
      }
    }

    // Initial button visibility check
    toggleButtonVisibility();

    function startAutoSlide() {
      if (NUM_SLIDES) {
        setInterval(() => {
          if (autoSlide) {
            currSlide = (currSlide + 1) % NUM_SLIDES;
            slideTrack();
            toggleButtonVisibility();
          }
        }, slideInterval);
      }
    }

    startAutoSlide();

    carouselNotificationsContainer.addEventListener("mouseover", () => {
      autoSlide = false;
    });
    carouselNotificationsContainer.addEventListener("mouseout", () => {
      autoSlide = true;
    });
    carouselNotificationsContainer.addEventListener("focusin", () => {
      autoSlide = false;
    });
    carouselNotificationsContainer.addEventListener("focusout", () => {
      autoSlide = true;
    });

    const data = this.container.dataset;
    this.loadDelay =
      data && !isNaN(parseFloat(data.carouselNotificationsBannerDelay))
        ? parseFloat(data.carouselNotificationsBannerDelay)
        : 0;
    this.eventToFireOn = data.carouselNotificationsBannerEvent
      ? data.carouselNotificationsBannerEvent
      : "add";

    this.getFocusableElements();
  }

  private hideNotificationHandler(uniqueID): void {
    const headerPadding = document.querySelector("header") as HTMLElement;
    const banner = document.querySelector(
      '[data-banner-id="' + uniqueID + '"]',
    );
    banner.classList.remove(Classnames.fadeIn);
    (banner as HTMLElement).style.display = "none";
    headerPadding && (headerPadding.style.paddingTop = "0");

    this.setTabIndex(false);
    sessionStorage.setItem(uniqueID + "-closed", "true");
  }

  private getFocusableElements(): void {
    const focusableSelectors: string[] = [
      "input",
      "select",
      "textarea",
      "a",
      "button",
      "area",
    ];
    focusableSelectors.forEach((item) => {
      const elements = this.container.querySelectorAll(item);
      elements.forEach((element) => {
        this.focusableElements.push(element);
      });
    });
  }

  private setTabIndex(placeInTabOrder): void {
    this.focusableElements.forEach((element) => {
      element.setAttribute("tabindex", placeInTabOrder == true ? "0" : "-1");
    });
  }
}

export { CarouselNotificationsBanner };
