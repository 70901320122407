export enum State {
  ACTIVE = "active",
  INACTIVE = "inactive",
  EXPANDED = "expanded",
  NAV_OPEN = "nav-open",
  SUBNAV_OPEN = "subnav-open",
  SUBNAV_CLOSING = "subnav-closing",
  ADD = "add",
  REMOVE = "remove",
  LOADING = "loading",
  DISPLAY_NONE = "toggle-display-none",
  DISPLAY_BLOCK = "toggle-display-block",
}
