enum Classnames {
  optionsHide = "option__hide",
}

enum Selector {
  options = '[data-cbg-cmp="options"]',
}

class OptionsButton {
  // Elements
  element: HTMLElement;

  constructor(component: HTMLElement) {
    this.element = component;

    if (!this.element) {
      return;
    }

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    this.element.addEventListener("click", (evt) => {
      let optionSelector =
        (this.element && this.element.dataset.showsOptions) || "";
      optionSelector = optionSelector.replace(/^(\s+)/g, "");

      if (optionSelector.indexOf("#") === -1) {
        return;
      }

      const optionContainer = document.querySelector(optionSelector);
      if (optionContainer) {
        const options = optionContainer.querySelector(Selector.options);
        if (options) {
          evt.preventDefault();
          options.classList.add(Classnames.optionsHide);
          document.querySelector("body").classList.add(Classnames.optionsHide);
        }
      }
    });
  }
}

export { OptionsButton };
