import { State } from "../../enum/state";

interface Store {
  store_number: string;
  name: string;
  address1: string;
  city: string;
  state: string;
  postalcode: string;
  _distance: number;
  country: string;
  province: string;
  _distanceuom: string;
}

enum Selector {
  hook = "data-location-searchbar",
}

class PreferredLocation {
  stores = [];
  component: HTMLElement;
  clearButton: HTMLElement;
  searchInputField: HTMLInputElement;
  private apiKey: string;
  private method: string;
  private url: string;
  private storesDisplayed: number;
  private errorMessage: string;
  private showMoreText: string;

  // private locationErrorMessage: string;
  private selectedLocationName: string;

  constructor(
    url: string,
    method: string,
    apiKey: string,
    errorMessage: string,
    showMoreText: string,
    component: HTMLElement,
  ) {
    if (!url || !method || !apiKey) {
      const errorMessage = `Missing url, method or apiKey ${JSON.stringify({
        url,
        method,
        apiKey,
      })}`;
      throw new Error(errorMessage);
    }
    this.component = component.querySelector(
      '[data-cbg-cmp="preferredLocation"]',
    );
    this.url = url;
    this.method = method;
    this.apiKey = apiKey;
    this.errorMessage = errorMessage;
    this.selectedLocationName = this.component.dataset.selectedLocationName;
    this.showMoreText = showMoreText;

    this.searchInputField = this.component.querySelector(
      `[${Selector.hook}="input"]`,
    );

    this.clearButton = this.component.querySelector(
      `[${Selector.hook}="clear"]`,
    );
    this.registerEventHandlers();
  }

  generateRequest(input) {
    return {
      request: {
        appkey: this.apiKey,
        formdata: {
          geolocs: {
            geoloc: [
              {
                addressline: input,
              },
            ],
          },
          searchradius: 50,
          radiusuom: "mile",
          where: {
            and: {
              lowes: {
                eq: "Y",
              },
            },
          },
        },
      },
    };
  }

  createErrorDiv(errorMessage) {
    const errorDiv = document.createElement("div");
    errorDiv.style.display = "flex";
    errorDiv.classList.add("error-message");
    errorDiv.innerText = errorMessage;
    return errorDiv;
  }

  createResultsDiv(showMore: boolean, locationResultsDiv: HTMLDivElement) {
    const stores: Store[] = this.stores;

    let storeListElement = "";
    let storeCount = 0;
    let storesToShow = 5;

    if (showMore == true && this.storesDisplayed < 15) {
      storesToShow = this.storesDisplayed + 5;
    }

    for (const store of stores.slice(0, storesToShow)) {
      let distance = store._distance;
      let distanceMetric = store._distanceuom;
      let stateOrProvince = store.state;
      if (store.country == "CA") {
        stateOrProvince = store.province;
      }
      const region = window.location.hostname.split(".");
      const languageCode = window.location.pathname.split("/")[1];
      if (languageCode == "es") {
        distanceMetric = "milla";
      }

      if (region[region.length - 1].toLowerCase() == "ca") {
        distance = distance * 1.61;
        if (languageCode == "es") {
          distanceMetric = "kilómetro";
        } else if (languageCode == "en") {
          distanceMetric = "kilometer";
        } else if (languageCode == "fr") {
          distanceMetric = "kilomètre";
        }
      }
      const distanceLabel =
        store._distance > 1 ? distanceMetric + "s" : distanceMetric;
      const selected = storeCount == 0 ? "checked" : "";
      storeListElement +=
        "<div class='preferred-location' tabindex='0'>" +
        "	<label class='styled-radio'>" +
        `		<input type='radio' name=${this.selectedLocationName} value=${store.store_number} ${selected} />` +
        "		<span class='checkmark'></span>" +
        "	</label>" +
        "	<div class='address-box'>" +
        `		<span class='address-name'>${store.name}</span>` +
        `		<span class='address-street'>${store.address1}</span>` +
        `		<span class='address-city'>${store.city}, ${stateOrProvince} ${store.postalcode}</span>` +
        "	</div>" +
        "	<div class='distance'>" +
        `	<span class='number'>${Math.round(distance * 100) / 100}</span>` +
        `		<label>${distanceLabel}</label>` +
        "	</div>" +
        "</div>";

      storeCount++;
    }

    locationResultsDiv.innerHTML = storeListElement;

    this.storesDisplayed = storeCount;

    this.storesDisplayed = storeCount;

    if (stores.length > storeCount && this.storesDisplayed < 15) {
      const showMoreDiv = document.createElement("div");
      showMoreDiv.classList.add("show-more");
      const showMoreLink = document.createElement("a");
      //todo: probably this too
      showMoreLink.innerText = this.showMoreText;
      showMoreLink.href = "#";
      showMoreLink.classList.add("styled-link");
      showMoreDiv.appendChild(showMoreLink);

      showMoreLink.addEventListener("click", (x) => {
        x.preventDefault();
        this.createResultsDiv(true, locationResultsDiv);
      });

      locationResultsDiv.appendChild(showMoreDiv);
    }
  }

  async submit(addressValue) {
    if (!addressValue) {
      console.error(`Missing addressValue: ${addressValue}`);
    }

    const response = await fetch(this.url, {
      method: this.method,
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(this.generateRequest(addressValue)),
    });

    const responseData = await response.json();

    if (response.ok) {
      this.stores = responseData.response.collection;
    } else {
      console.error(response);
      throw "Response not ok";
    }

    // if (responseData.response.message) {
    //   //todo: this error message
    //   this.errorMessage =
    //     "No stores have been found nearby. Please update your search and try again.";
    //   this.errorMessage = errorMessage;
    // }
  }

  renderResults(locationResultsDiv: HTMLDivElement) {
    if (this.errorMessage) {
      const div = this.createErrorDiv(this.errorMessage);
      this.errorMessage = null;
      locationResultsDiv.innerHTML = "";
      locationResultsDiv.appendChild(div);
      return;
    }

    if (this.stores && this.stores.length > 0) {
      this.createResultsDiv(false, locationResultsDiv);
      return;
    }
  }

  registerEventHandlers() {
    this.searchInputField.addEventListener(
      "input",
      this.registerClearBtn.bind(this),
    );
    this.clearButton?.addEventListener(
      "click",
      ((event) => {
        // Only clear the input when the active element is the clear button to prevent false positives.
        if (event.currentTarget && event.currentTarget == this.clearButton) {
          event?.preventDefault();
          this.searchInputField.classList.remove("clear-input");
          this.clearInputValue();
        }
      }).bind(this),
    );
  }

  private registerClearBtn() {
    if (
      this.searchInputField.value !== null ||
      this.searchInputField.value !== ""
    ) {
      this.searchInputField.classList.add("clear-input");
    }
    if (
      this.searchInputField.value === null ||
      this.searchInputField.value === ""
    ) {
      this.searchInputField.classList.remove("clear-input");
    }
  }

  private updateClearButtonState(active: boolean) {
    if (active) {
      this.clearButton?.classList.add(State.ACTIVE);
    } else {
      this.clearButton?.classList.remove(State.ACTIVE);
    }
  }

  private clearInputValue() {
    // Clear the search input field.
    this.searchInputField.value = "";

    // Update the clear button state.
    this.updateClearButtonState(false);
  }
}

export { PreferredLocation };
