class ProductSpecs {
  component: HTMLElement;
  loadMoreBtn: HTMLButtonElement;
  tableRows: NodeListOf<HTMLElement>;
  limitRows = false;
  tableHeadCells: NodeListOf<HTMLTableHeaderCellElement>;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    this.loadMoreBtn = this.component.querySelector(
      '[data-cbg-cmp-hook-productspecs="loadMoreButton"]',
    );

    this.tableHeadCells = this.component.querySelectorAll("th");
    if (this.loadMoreBtn) {
      const numberRowsToShow = this.loadMoreBtn?.dataset.rows;
      const numberOfRows = parseInt(numberRowsToShow);

      this.tableRows = this.component.querySelectorAll(".product-specs-row");
      if (this.tableRows && numberOfRows >= this.tableRows.length) {
        const btnEl = this.component.querySelector(".button-container");
        btnEl.classList.add("hidden");
      }

      this.tableRows.forEach((row, idx) => {
        if (idx >= numberOfRows) {
          row.classList.add("hidden");
        }
      });
    }
    this.registerEventHandlers();
  }

  private showMore() {
    if (this.tableRows.length) {
      this.tableRows.forEach((row) => {
        row.classList.remove("hidden");
      });
    }

    const btnEl = this.component.querySelector(".button-container");
    btnEl.classList.add("hidden");
  }

  private manageSortIcon(event: MouseEvent | KeyboardEvent) {
    const currentCell = <HTMLElement>event.target;
    const currentCellText = currentCell.innerText;
    document.querySelectorAll("table .header_arrow").forEach((spanElement) => {
      if (spanElement.parentElement.innerText !== currentCellText) {
        spanElement.classList.add("sorting");
      } else {
        spanElement.classList.remove("sorting");
      }
    });
  }

  private manageSortEnter(event: KeyboardEvent) {
    if (event.key === "Enter") {
      (event.currentTarget as HTMLElement).click();
    }
  }

  private convertMeasurementToDecimal(measurement: string): string {
    // Split the measurement into individual parts based on spaces
    const measurementParts = measurement.split(" ");

    // Map each part to its decimal value, if applicable
    return measurementParts
      .map((part) => {
        const decimalValue = this.convertFractionToDecimal(part);
        return isNaN(decimalValue) ? "" : decimalValue.toString();
      })
      .filter((decimal) => decimal !== "") // Remove empty strings from conversion
      .join(" "); // Join the parts back into a single string
  }

  private convertFractionToDecimal(fraction: string): number {
    // Remove any trailing non-numeric characters except for hyphens and slashes
    const sanitizedFraction = fraction.replace(/[^0-9/-]/g, "").trim();

    if (sanitizedFraction.includes("-")) {
      const [wholePart, fractionalPart] = sanitizedFraction.split("-");
      if (fractionalPart.includes("/")) {
        const [numerator, denominator] = fractionalPart.split("/");
        return (
          parseFloat(wholePart) +
          parseFloat(numerator) / parseFloat(denominator)
        );
      }
      return parseFloat(wholePart); // Handle case like "1-"
    } else if (sanitizedFraction.includes("/")) {
      const [numerator, denominator] = sanitizedFraction.split("/");
      return parseFloat(numerator) / parseFloat(denominator);
    } else {
      return parseFloat(sanitizedFraction) || NaN; // Handle plain numbers or invalid input
    }
  }

  private addCustomKeyAttribute(): void {
    this.component.querySelectorAll(".product-specs-data").forEach((td) => {
      const textContent = td.textContent || "";
      const decimalValue = this.convertMeasurementToDecimal(textContent);
      td.setAttribute("sorttable_customkey", decimalValue);
    });
  }

  private registerEventHandlers(): void {
    if (this.loadMoreBtn) {
      this.loadMoreBtn.addEventListener("click", this.showMore.bind(this));
    }
    if (this.tableHeadCells) {
      this.tableHeadCells.forEach((tableHeadCell) => {
        tableHeadCell.addEventListener("click", this.manageSortIcon.bind(this));
        tableHeadCell.addEventListener(
          "keydown",
          this.manageSortEnter.bind(this),
        );
      });
    }
    this.addCustomKeyAttribute(); // Call this method to set custom attributes
  }
}

export { ProductSpecs };
