class Gif {
  container: HTMLElement;

  constructor(component: HTMLElement) {
    const checkbox = <HTMLInputElement>document.getElementById("gifCheckbox");

    //checking for accessibility criteria.
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce))");
    const details = document.querySelector(".gif-container > .clicktogif");

    if (mediaQuery.matches) {
      details.classList.add("hide-gif-image");
    }
    //end off accessibility criteria

    //build trigger
    if (checkbox.checked) {
      setTimeout(() => {
        checkbox.checked = false;
        checkbox.disabled = false;
      }, 5000);
    }
  }
}

export { Gif };
