import { Session } from "../../site/scripts/utils";

class Brandify {
  component: HTMLElement;
  iframe: HTMLElement;
  cookieId: string;

  constructor(component) {
    this.component = component;

    if (!this.component) {
      return null;
    }

    const addrRegex = /[?|&]addressline/;
    this.iframe = this.component.querySelector(
      '[data-cmp-hook-location="iframe"]',
    );
    this.cookieId = this.component.dataset.fieldCookieId;

    let iframeUrl =
      this.iframe.getAttribute("src") || this.iframe.dataset.iframeSrc;

    const savedValue = Session.get(this.cookieId);
    if (savedValue) {
      if (addrRegex.test(iframeUrl)) {
        iframeUrl += ` ${savedValue}`;
      } else {
        if (iframeUrl.indexOf("?") > -1) {
          iframeUrl += `&addressline=${savedValue}`;
        } else {
          iframeUrl += `?addressline=${savedValue}`;
        }
      }
    }

    if (iframeUrl) {
      this.iframe.setAttribute("src", iframeUrl);
    }

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    const method = window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer = window[method];
    const messageEvent = method == "attachEvent" ? "onmessage" : "message";

    eventer(
      messageEvent,
      ((event) => {
        if (!event || !event.data || typeof event.data !== "string") {
          return;
        }

        const isHeightUpdated =
          event.data.indexOf("w2gi:iframeHeightUpdated") > -1;
        const isScroll = event.data?.indexOf("w2gi:scrollPage") > -1;

        if (isHeightUpdated) {
          const dimensions = event.data.split("//");
          this.autoResize(dimensions[1], dimensions[2]);
        }

        if (isScroll) {
          const offsets = this.iframe.getBoundingClientRect();
          const values = event.data.split("//");
          const destination = values[1];
          const offset = values[2];
          window.scrollTo(0, destination + offsets.top);
        }
      }).bind(this),
    );
  }

  private autoResize(newHeight, newWidth) {
    this.iframe.style.height = `${parseInt(newHeight)}px`;
  }
}

export { Brandify };
