import { Cookie, Utils } from "../../site/scripts/utils";
import { CartAPI } from "../cart/AskValV3";

type FormData = {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string; // Optional field
  city: string;
  state: string;
  postalCode: string;
  email: string;
  optIn?: boolean;
};

class Form {
  // Elements
  private readonly formElement: HTMLFormElement;
  private readonly isSignUp: boolean;

  // Sign Up Cookie
  private signUpFormCookie: void;
  private signUpFormCookieValue: string;
  private readonly isStandardFormSubmit: boolean;
  isOnCart: boolean;
  onSuccess: string;

  constructor(component: HTMLFormElement) {
    this.formElement = component;

    if (!this.formElement) {
      return null;
    }

    const data = this.formElement.dataset;

    this.isStandardFormSubmit =
      data.formType === "formSubmit" || data.formType === undefined;
    this.isSignUp = data.cmpSignUpForm === "true";
    this.isOnCart = data.onCart === "true";
    this.onSuccess = data.successPage;
    this.signUpFormCookieValue = Cookie.get("showSignUpModal");
    if (this.isOnCart) {
      const savedShippingInfo = this.readEncodedDataFromDOM();
      if (savedShippingInfo) this.prefillForm(savedShippingInfo);
    }

    this.registerEventHandlers();
  }

  private registerEventHandlers(): void {
    if (this.isStandardFormSubmit && !this.isOnCart) {
      this.formElement.addEventListener(
        "submit",
        this.submitHandler.bind(this),
      );
    }

    if (this.isSignUp) {
      this.formElement.addEventListener(
        "submit",
        this.signUpRequired.bind(this),
      );
      return;
    }
    if (this.isOnCart) {
      this.formElement.addEventListener("submit", (event) => {
        if (!this.formElement.checkValidity()) {
          event.preventDefault();
          return false;
        }
        this.submitCartShipping(event);
      });
    }
  }

  private readEncodedDataFromDOM() {
    const parentDom = window.parent.document.querySelector(
      ".modal-slider",
    ) as Element;
    const hiddenInput = parentDom.querySelector(
      "#encodedShipInfo",
    ) as HTMLInputElement | null;
    if (hiddenInput) {
      return this.decodeEncodedData(hiddenInput.value);
    } else {
      return null;
    }
  }

  private decodeEncodedData(encodedData: string): any {
    try {
      const decodedURIString = atob(encodedData);
      const decodedJSON = decodeURIComponent(decodedURIString);
      return JSON.parse(decodedJSON);
    } catch (e) {
      console.error("Failed to decode the data:", e);
      return null;
    }
  }

  private prefillForm(data: FormData): void {
    const form = this.formElement;

    Object.keys(data).forEach((key) => {
      const input = form.querySelector(`[name="${key}"]`) as
        | HTMLInputElement
        | HTMLSelectElement
        | null;
      if (input) {
        if (input.type === "checkbox") {
          (input as HTMLInputElement).checked = data[key] as boolean;
        } else if (input.tagName === "SELECT") {
          (input as HTMLSelectElement).value = data[key] as string;
        } else {
          input.value = data[key] as string;
        }
      }
    });
  }

  private async submitCartShipping(event: Event): Promise<void> {
    event.preventDefault(); // Prevent default form submission
    this.toggleElementDisplay(true);

    const form = this.formElement as HTMLFormElement;

    // Define the fields to extract
    const fieldsToExtract = [
      "firstName",
      "lastName",
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
      "email",
      "optIn",
      "country",
    ];
    const formDataObj: Record<string, any> = {};

    // Loop through the fields and append their values to selectedData
    fieldsToExtract.forEach((field) => {
      const input = form.elements.namedItem(field) as
        | HTMLInputElement
        | HTMLSelectElement;
      if (input) {
        if (input instanceof HTMLInputElement && input.type === "checkbox") {
          formDataObj["optIn"] = input.checked; // Assign the checkbox value to "optIn"
        } else {
          formDataObj[field] = input.value;
        }
      }
    });
    // Append static values
    formDataObj["retailer"] = "lowes";

    try {
      const response = await CartAPI?.postShippingAddress(formDataObj);
      if (response.requestStatus && response.requestStatus == "ok") {
        this.storeEncodedDataInDOM(response);
        window.location.href = this.onSuccess; // Redirect on success
      } else {
        this.toggleElementDisplay(false, response);
      }
    } catch (error) {
      console.warn("Trouble posting address", error);
    }
  }

  private storeEncodedDataInDOM(response: any) {
    function encodeData(data: Record<string, any>): string {
      const jsonString: string = JSON.stringify(data);
      const encodedURI: string = encodeURIComponent(jsonString);
      return btoa(encodedURI);
    }

    const modifiedResponse = { ...response };
    delete modifiedResponse.cart;
    const b64Data = encodeData(modifiedResponse);
    const parentDom = window.parent.document.querySelector(
      ".modal-slider",
    ) as Element;
    let hiddenInput = parentDom.querySelector(
      "#encodedShipInfo",
    ) as HTMLInputElement | null;
    if (!hiddenInput) {
      hiddenInput = document.createElement("input");
      hiddenInput.type = "hidden";
      hiddenInput.id = "encodedShipInfo";
      parentDom.appendChild(hiddenInput);
    }
    hiddenInput.value = b64Data;
  }

  private signUpRequired(): void {
    this.signUpFormCookie = Cookie.set("showSignUpModal", "never", 10000);
  }

  private toggleElementDisplay(condition: boolean, response?) {
    const element = window.parent.document.querySelector(
      ".modal-slider.open .loading",
    ) as HTMLElement;
    element.style.display = condition ? "flex" : "none";
    const popMessage = this.formElement.querySelector(".textTool");
    popMessage.querySelector(".error-message")?.remove();
    if (response?.errors?.length) {
      const errorContainer = document.createElement("div");
      errorContainer.className = "error-message";
      response.errors.forEach((error) => {
        const divMessage = document.createElement("div");
        divMessage.textContent = error.message;
        errorContainer.appendChild(divMessage);
      });
      popMessage.appendChild(errorContainer);
      popMessage.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }

  // Prevents default form submit event in order to validate input fields first
  private submitHandler(event: Event) {
    event.preventDefault();
    if (!this.formElement.checkValidity()) {
      return false;
    }

    this.formElement.submit();
  }
}

export { Form };
