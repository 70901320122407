class DynamicPdpColorChips {
  private tabs: NodeListOf<HTMLAnchorElement>;
  private tabPanes: NodeListOf<HTMLElement>;
  private dynamicPdpColorChips: HTMLElement;

  constructor(component: HTMLElement) {
    this.dynamicPdpColorChips = document.querySelector(
      ".dynamic-pdp-container",
    );
    this.tabs = component.querySelectorAll(".tab-list a");
    this.tabPanes = component.querySelectorAll(".tab-pane");
    this.registerEventHandlers();
    this.activateFirstTab();
  }

  private registerEventHandlers() {
    if (this.tabs.length === 0) {
      this.dynamicPdpColorChips.style.display = "none";
    } else {
      this.tabs.forEach((tab) => {
        tab.addEventListener("click", (e) => {
          e.preventDefault();
          const href = tab.getAttribute("href");
          const target = document.querySelector(href);

          if (target) {
            this.tabs.forEach((t) => t.classList.remove("active"));
            this.tabPanes.forEach((pane) => pane.classList.remove("active"));

            tab.classList.add("active");
            target.classList.add("active");
          } else {
            console.error(`No element found for href: ${href}`);
          }
        });
      });
    }
  }

  private activateFirstTab() {
    if (this.tabs.length > 0) {
      this.tabs[0].classList.add("active");
      this.tabPanes[0].classList.add("active");
    }
  }
}

export { DynamicPdpColorChips };
