// TODO (performance refactor suggestion seth.pearson@vmlyr.com): Move logic to Sling Model

class Banner {
  container: HTMLElement;
  video: NodeListOf<Element>;
  textContent: HTMLCollectionOf<Element>;
  textWrapper: HTMLCollectionOf<Element>;
  bannerTitle: HTMLElement;
  bannerSubTitle: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.container = component;

    if (!this.container) {
      return null;
    }
    this.bannerSubTitle = this.container.querySelector(".banner-subtitle");
    this.bannerTitle = this.container.querySelector(".banner-title");
    const buttonZone = this.container.querySelector(".banner-button-zone");
    const videoOption = this.container.querySelector(".banner-video-zone");
    const buttonZoneArray = Array.from(buttonZone.children);
    const videoArray = Array.from(videoOption.children);
    const hasVideo = videoArray.filter((e) => e.childElementCount > 0).length;
    const hasBtnElements = buttonZoneArray.filter(
      (e) => e.childElementCount > 0,
    ).length;
    const bannerColor = this.container?.getAttribute("data-background-color");
    if (bannerColor) {
      this.container.style.setProperty("--banner-bg-color", bannerColor);
    }

    if (hasVideo == 0) {
      videoOption.classList.add("hideElement");
    } else {
      videoOption.classList.remove("hideElement");
    }

    if (hasBtnElements == 0) {
      buttonZone.classList.add("hideElement");
      // TODO: refactor this to use classes instead of inline styles
      const h1 = this.container && this.container.querySelector("h1");
      if (h1) this.container.querySelector("h1").style.maxWidth = "100%";
    } else {
      buttonZone.classList.remove("hideElement");
    }
  }
}

export { Banner };
