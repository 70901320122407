import { Cookie } from "../../site/scripts/utils";
import * as _ from "lodash";

const LEFT_HAND_ATTRIBUTE = "data-left-hand-display";
const BRAND_ATTRIBUTE = "data-brand";
const LEFT_HAND_VALUE = "left-hand";
const RIGHT_HAND_VALUE = "right-hand";
const COLOR_WALL_FACET = "ColorWallChunkChipFacet";

class Prism {
  private window = window as any;
  private document = document as any;
  private brand: string;
  private prismComponent = this.window.PRISM;
  private readonly wallDirQueryParamName;
  private readonly wallDirCookieName;
  private readonly language: string;
  private readonly languagePath: string;
  private readonly region: string;
  private readonly facet: string;
  private readonly prismCloseEvent: Event;
  private readonly container: HTMLElement;
  private crossSearch: string;

  constructor(component: HTMLElement) {
    this.container = component;
    this.facet = this.container.dataset.prismFacet;
    this.wallDirQueryParamName = this.container.dataset.wallDirQueryParamName;
    this.wallDirCookieName = this.container.dataset.wallDirCookieName;
    this.brand = this.container.dataset.brand;
    this.language = this.container.dataset.language || "en-US";
    this.languagePath = this.getLanguagePath().split("-").shift() || "en";
    this.region = this.getRegion() || "us";
    this.prismCloseEvent = new Event("prismSearchClose");
    this.crossSearch = this.container.dataset.crossSearch;

    if (this.brand === "valspar") {
      this.configWallDirComponent(this.container);
    }

    if (this.brand === "rona") this.setBrandName();

    const facetCallbacks = this.getFacetCallbacks();
    this.prismComponent.embed(this.container, facetCallbacks);
  }

  /* Accommodate localhost URLs for local testing */
  private getBaseHostUrl(): string {
    let baseHostUrl = this.window.location.origin;
    if (baseHostUrl.indexOf("localhost") > -1) {
      baseHostUrl = `${baseHostUrl}/content/cbg-${this.brand}`;
    }
    return baseHostUrl;
  }

  /* HGSW color paths are different from others */
  private getColorSeparatorSnippet(): string {
    return `${this.brand === "hgsw" ? "-hgsw" : "-"}`;
  }

  /* Return the correct PRISM callback object based on facet */
  private getFacetCallbacks(): Record<string, any> {
    if (this.facet === COLOR_WALL_FACET) {
      return this.getColorWallCallbacks();
    } else {
      return this.getColorSearchCallbacks();
    }
  }

  /* Return the callbacks object needed for the Color Wall facet */
  private getColorWallCallbacks(): Record<string, unknown> {
    return {
      colorDetailPageRoot: (color) =>
        `${this.getBaseHostUrl()}/${this.languagePath}/colors/${
          color.colorFamilyNames[0]
        }/${_.kebabCase(color.name)}${this.getColorSeparatorSnippet()}${
          color.colorNumber
        }`.toLowerCase(),
      colorWallPageRoot: ({ brandKey, colorNumber }) =>
        `${this.getBaseHostUrl()}/${
          this.languagePath
        }/color-wall/#/color-locator/${brandKey}${colorNumber}`,
      wallBanner: this.getColorWallBanner(),
    };
  }

  /* Get the appropriate banner for the given color wall */
  private getColorWallBanner(): string {
    let wallBanner;
    if (this.brand == "hgsw") {
      wallBanner =
        "/content/dam/cbg-hgsw/lowes-inspiration/LBPCBG1227_21_QRCode_Inspiration_ChipLocator_right_hand_wall_HGSW.png";
    }

    if (this.brand == "valspar" && this.region == "us") {
      wallBanner =
        "/content/dam/cbg-valspar/lowes-inspiration/LBPCBG1227_21_QRCode_Inspiration_ChipLocator_right_hand_wall_Valspar.png";
      if (this.getWallDirCookie() === LEFT_HAND_VALUE) {
        wallBanner =
          "/content/dam/cbg-valspar/lowes-inspiration/LBPCBG1227_21_QRCode_Inspiration_ChipLocator_left_hand_wall_Valspar.png";
      }
    }

    return wallBanner;
  }

  /* Return the callbacks object needed for the Color Search facet */
  private getColorSearchCallbacks(): Record<string, unknown> {
    this.crossSearch =
      typeof this.crossSearch === "undefined" ? "true" : this.crossSearch;

    return {
      colorDetailPageRoot: (color) =>
        `${this.getBaseHostUrl()}/${this.languagePath}/colors/${
          color.colorFamilyNames[0]
        }/${_.kebabCase(color.name)}${this.getColorSeparatorSnippet()}${
          color.colorNumber
        }`.toLowerCase(),
      colorWallPageRoot: ({ brandKey, colorNumber }) =>
        `${this.getBaseHostUrl()}/${
          this.languagePath
        }/color-wall/#/color-locator/${brandKey}${colorNumber}`,
      bindCallback: ({ publish, subscribe }) => {
        subscribe("prism-close-color-search", () => {
          this.window.dispatchEvent(this.prismCloseEvent);
        });
        this.document
          .querySelector('[data-opens-modal="#prism-search-modal"]')
          .addEventListener("click", () =>
            publish("prism-focus-color-search-bar"),
          );
      },

      /* Only include this property if the facet is ColorSearch and option enabled */
      ...(this.crossSearch === "true" && {
        crossSearchChipLocator: this.getCrossSearchObject(),
      }),
    };
  }

  /* Get the left-hand wall query param value, if any */
  private getWallDirQueryParam(): string {
    const params = new URLSearchParams(this.window.location.search);
    return params.get(this.wallDirQueryParamName) || "";
  }

  /* Return "left" if leftHandWall cookie is set, "right" otherwise" */
  private getWallDirCookie(): string {
    const result = Cookie.get(this.wallDirCookieName);
    return result;
  }

  /* Configure wall direction cookie based on query param/existing cookie */
  private configWallDirComponent(component: HTMLElement): void {
    const wallDirQueryParam = this.getWallDirQueryParam();
    const wallDirCookie = this.getWallDirCookie();

    if (
      wallDirQueryParam === LEFT_HAND_VALUE ||
      wallDirCookie === LEFT_HAND_VALUE
    ) {
      this.container.setAttribute(LEFT_HAND_ATTRIBUTE, "true");
    } else if (
      wallDirQueryParam === RIGHT_HAND_VALUE ||
      wallDirCookie === RIGHT_HAND_VALUE
    ) {
      this.container.setAttribute(LEFT_HAND_ATTRIBUTE, "false");
    }
  }

  /* Get the page root for cross search */
  private getColorWallPageRoot(): string {
    let pageOrigin = this.window.location.origin;
    const colorWallPath = `/${this.languagePath}/color-wall`;
    if (pageOrigin.includes("valspar")) {
      if (pageOrigin.includes("localhost")) {
        return `http://localhost:4502/content/cbg-hgsw/${this.region}/${this.languagePath}/color-wall.html`;
      }
      pageOrigin = pageOrigin.replace("valspar", "hgtvhomebysherwinwilliams");
    } else {
      if (pageOrigin.includes("localhost")) {
        return `http://localhost:4502/content/cbg-valspar/${this.region}/${this.languagePath}/color-wall.html`;
      }
      pageOrigin = pageOrigin.replace("hgtvhomebysherwinwilliams", "valspar");
    }
    return `${pageOrigin}${colorWallPath}`;
  }

  /* Get the cross-brand search callback object */
  private getCrossSearchObject(): Record<string, unknown> {
    const colorWallPageRoot = `${this.getBaseHostUrl()}/${
      this.languagePath
    }/color-wall`;
    if (this.brand === "valspar") {
      return {
        brand: { id: "hgsw", name: "HGTV HOME® by Sherwin-Williams" },
        onClickFindChip: ({ brandKey, colorNumber }) =>
          `${this.getBaseHostUrl()}/prism-templates/templates/cbg/hgsw-qr-color-wall.html#/color-locator/\${brandKey}\${colorNumber}`,
        onClickViewColor: (color) =>
          `${this.getBaseHostUrl()}/${
            this.languagePath
          }/colors/\${color.colorFamilyNames[0]}/\${kebabCase(color.name)}-hgsw-\${color.colorNumber}`.toLowerCase(),
        text: "Looking for HGTV Home® by Sherwin-Williams colors at Lowe’s?",
        colorWallPageRoot: this.getColorWallPageRoot(),
      };
    } else if (this.brand === "hgsw") {
      return {
        brand: { id: "valspar", name: "Valspar" },
        onClickFindChip: ({ brandKey, colorNumber }) =>
          `${this.getBaseHostUrl()}/prism-templates/templates/cbg/valspar-qr-color-wall.html#/color-locator/${brandKey}${colorNumber}`,
        onClickViewColor: (color) =>
          `${this.getBaseHostUrl()}/${this.languagePath}/colors/${
            color.colorFamilyNames[0]
          }/${_.kebabCase(color.name)}-${color.colorNumber}`.toLowerCase(),
        text: "Looking for Valspar colors at Lowe’s?",
        colorWallPageRoot: this.getColorWallPageRoot(),
      };
    } else {
      return {};
    }
  }

  /* Gets the region path based upon the supplied language chosen in the component */
  private getRegion(): string {
    return this.language.split("-").pop().toLowerCase();
  }

  /* Gets the language path based upon the supplied language chosen in the component */
  private getLanguagePath(): string {
    return this.language.toLowerCase();
  }

  private setBrandName() {
    if (this.brand === "rona") {
      this.brand += "-valspar";
    }
  }
}

export { Prism };
