import { debounce } from "lodash";
import { tns } from "node_modules/tiny-slider/src/tiny-slider.module.js";

enum Selector {
  hook = "data-cbg-cmp-hook-carousel",
  pagination = "data-cbg-cmp-hook-pagination",
}

enum CarouselClasses {
  active = "cmp'-carousel__item--active",
}

enum PaginationSeparators {
  fraction = "/",
  of = " of ",
}

class Carousel {
  // Properties
  config = {
    autoplay: false,
    autopauseDisabled: false,
    delay: 5000,
  };
  // Elements
  container: HTMLElement;
  viewport: HTMLElement;
  private prevButton: HTMLButtonElement;
  private nextButton: HTMLButtonElement;
  private playButton: HTMLButtonElement;
  private pauseButton: HTMLButtonElement;
  private slider: any;
  items: Array<HTMLElement>;
  // State
  previousItem: number;
  currentItem: number;
  totalItems: number;
  paused = false;
  // Pagination
  private pagination: HTMLElement;
  private paginationSeparator = "/";
  // Autoplay
  private autoplayEnabled = false;
  private autoplayIntervalId = null;

  constructor(component: HTMLElement) {
    // Elements
    this.container = component;
    if (!this.container) {
      return null;
    }
    const simpleNumbers = this.container.querySelector(".simple-numbering");
    this.prevButton = this.container.querySelector(
      ".cmp-carousel__action--previous",
    );
    this.nextButton = this.container.querySelector(
      ".cmp-carousel__action--next",
    );

    const slider = this.container.querySelector(".my-slider");
    if (slider) {
      //  This is where an instance of the tiny slider is
      this.slider = tns({
        container: slider,
        items: 1,
        slideBy: 1,
        mouseDrag: true,
        navPosition: "bottom",
        controls: true,
        nav: true,
        controlsPosition: "bottom",
        prevButton: this.prevButton,
        nextButton: this.nextButton,
        loop: false,
      });

      const carouselActionBar = this.container.querySelector(
        ".cmp-carousel__actions",
      );

      const indicatorNumber = this.container.querySelector(
        ".cmp-carousel__indicators",
      );
      const lastItem = this.container.querySelector(".carousel-last-item");
      const tnsPrevButton = this.container.querySelector(
        ".cmp-carousel__action--previous",
      );
      const tnsNextButton = this.container.querySelector(
        ".cmp-carousel__action--next",
      );
      const tnsNav = this.container.querySelector(".tns-nav");
      const slideNumbers = this.container.querySelector(".tns-visually-hidden");
      slideNumbers.firstChild.remove();
      const currentSlide = slideNumbers.firstChild;
      const currentSlideSpan = this.container.querySelector(".current");
      if (simpleNumbers) {
        indicatorNumber.insertBefore(currentSlide, lastItem);
        tnsNav.classList.add("hideBasic");
      }
      if (!simpleNumbers) {
        const navArray: any = Array.from(tnsNav.children);
        const sliderElement = this.container.querySelector(".my-slider");
        const sliderArray: any = Array.from(sliderElement.children);

        for (let i = 0; i < sliderArray.length; i++) {
          const colorSwatch = sliderArray[i].querySelector(".cbg-color-swatch");
          if (colorSwatch) {
            const swatchStyle = getComputedStyle(colorSwatch);
            const swatchBackground = swatchStyle.backgroundColor;
            navArray[i].style.backgroundColor = swatchBackground;
          }
        }

        carouselActionBar.insertBefore(tnsNav, tnsNextButton);
        tnsNextButton.classList.add("advanced-numbers");
        tnsPrevButton.classList.add("advanced-numbers");
        currentSlideSpan.classList.add("hideBasic");
        slideNumbers.classList.add("hideBasic");
      }
    }
    const slides = this.container.querySelectorAll(`[${Selector.hook}="item"]`);
    this.items = Array.prototype.slice.call(slides);
    if (this.items.length == 0) {
      return null;
    }
    this.viewport = this.container.querySelector(
      `[${Selector.hook}="viewport"]`,
    );
    this.prevButton = this.container.querySelector(
      `[${Selector.hook}="previous"]`,
    );
    this.nextButton = this.container.querySelector(`[${Selector.hook}="next"]`);
    this.setViewportHeight();
    // State
    this.previousItem = 0;
    this.currentItem =
      parseInt(this.container.dataset.carouselCurrentItem, 10) || 0;
    this.totalItems =
      parseInt(this.container.dataset.carouselTotalItems, 10) || 0;
    // Pagination
    this.pagination = this.container.querySelector(`[${Selector.pagination}]`);
    if (this.pagination && this.pagination.dataset.cmpCbgHookPagination) {
      const separatorKey = this.pagination.dataset.cmpCbgHookPagination;
      if (PaginationSeparators[separatorKey]) {
        this.paginationSeparator = PaginationSeparators[separatorKey];
      }
    }
    // Update the state
    this.update();
    // Data Properties
    const defaults = {
      autoplay: {
        value: false,
        transform: function (value) {
          const autoplay = !(value === null || typeof value === "undefined");
          this.autoplayEnabled = autoplay;
          return autoplay;
        },
      },
      delay: {
        value: 5000,
        transform: function (value) {
          return !isNaN(parseFloat(value)) ? parseFloat(value) : null;
        },
      },
      autopauseDisabled: {
        value: false,
        transform: function (value) {
          return !(value === null || typeof value === "undefined");
        },
      },
    };
    Object.keys(defaults).forEach((opt) => {
      const cappedOptionName = opt.charAt(0).toUpperCase() + opt.slice(1);
      const dataAttr = this.container.dataset[`cmp${cappedOptionName}`];
      this.config[opt] = defaults[opt].value;
      if (dataAttr !== "undefined") {
        if (typeof defaults[opt].transform === "function") {
          this.config[opt] = defaults[opt].transform(dataAttr);
        }
      }
    });
    this.autoplayEnabled = this.config.autoplay;
    if (this.autoplayEnabled) {
      this.playButton = this.container.querySelector(
        `[${Selector.hook}="play"]`,
      );
      this.pauseButton = this.container.querySelector(
        `[${Selector.hook}="pause"]`,
      );
      this.startAutoplay();
    }
    // Event Handlers
    this.registerEventHandlers();
  }

  /* --------- END OF CONSTRUCTOR --------- */
  private setViewportHeight() {
    let tallest = 0;
    this.items.forEach(function (slide) {
      if (slide.clientHeight > tallest) {
        tallest = slide.clientHeight;
      }
    });
    if (tallest) {
      this.viewport.style.height = `${tallest}px`;
    }
  }

  private startAutoplay() {
    if (this.config.autopauseDisabled || !this.paused) {
      this.resetAutoplayInterval();
    }
    this.updatePlayPauseButtonState();
  }

  /**
   * Clears automatic slide transition interval
   *
   * @private
   */
  private clearAutoplayInterval() {
    window.clearInterval(this.autoplayIntervalId);
    this.autoplayIntervalId = null;
  }

  /**
   * Starts/resets automatic slide transition interval
   *
   * @private
   */
  private resetAutoplayInterval() {
    if (this.paused || !this.autoplayEnabled) {
      return;
    }
    this.clearAutoplayInterval();
    this.autoplayIntervalId = window.setInterval(
      function () {
        //Do not set an interval is the document is not active or is paused.
        if ((document.visibilityState && document.hidden) || this.paused) {
          return;
        }
        this.update("next");
      }.bind(this),
      this.config.delay,
    );
  }

  private pause() {
    this.paused = true;
    this.clearAutoplayInterval();
    this.updatePlayPauseButtonState();
  }

  private play() {
    this.paused = false;
    this.updatePlayPauseButtonState();
    this.resetAutoplayInterval();
  }

  private registerEventHandlers() {
    this.prevButton?.addEventListener("click", this.update.bind(this, "prev"));
    this.nextButton?.addEventListener("click", this.update.bind(this, "next"));
    const removeAnimationClasses = function (ev: AnimationEvent) {
      const el = <HTMLElement>ev.target;
      if (el && el.classList) {
        el.classList.remove(
          "cmp-carousel__item--show-left",
          "cmp-carousel__item--show-right",
          "cmp-carousel__item--hide-left",
          "cmp-carousel__item--hide-right",
        );
      }
    };
    this.items.forEach((item) => {
      item.addEventListener("animationend", removeAnimationClasses);
      item.addEventListener("transitionend", removeAnimationClasses);
    });
    if (this.config.autopauseDisabled) {
      this.viewport?.addEventListener(
        "mouseenter",
        function () {
          this.pause();
        }.bind(this),
      );
      this.viewport?.addEventListener(
        "mouseleave",
        function () {
          this.play();
        }.bind(this),
      );
    }
    if (this.pauseButton && this.playButton) {
      this.pauseButton?.addEventListener(
        "click",
        function () {
          this.pause();
          this.playButton?.focus();
        }.bind(this),
      );
      this.playButton.addEventListener(
        "click",
        function () {
          this.actionsDiv.classList.add("advanced");
          this.play();
          this.pauseButton?.focus();
        }.bind(this),
      );
    }
    window.addEventListener(
      "resize",
      debounce(
        function () {
          this.setViewportHeight();
        }.bind(this),
        750,
      ),
    );
    // TODO: add a mutationobserver for class name updates to update the pagination, specifically for the authoring environment
  }

  private updatePlayPauseButtonState() {
    if (this.paused) {
      if (this.pauseButton) this.pauseButton.disabled = true;
      if (this.playButton) this.playButton.disabled = false;
    } else {
      if (this.pauseButton) this.pauseButton.disabled = false;
      if (this.playButton) this.playButton.disabled = true;
    }
  }

  private updateNavButtonState() {
    if (this.prevButton) {
      if (this.currentItem === 1) {
        this.prevButton.disabled = true;
      } else if (this.prevButton.disabled) {
        this.prevButton.disabled = false;
      }
    }
    if (this.nextButton) {
      if (this.currentItem === this.totalItems) {
        this.nextButton.disabled = true;
      } else if (this.nextButton.disabled) {
        this.nextButton.disabled = false;
      }
    }
  }

  private updateActiveItem() {
    const direction = this.previousItem < this.currentItem ? "left" : "right";
    const activeItem = (
      this.items.filter(
        (el) => el.dataset.itemNumber === `${this.previousItem}`,
      ) || []
    ).shift();
    const nextItem = (
      this.items.filter(
        (el) => el.dataset.itemNumber === `${this.currentItem}`,
      ) || []
    ).shift();
    if (activeItem && activeItem.classList) {
      activeItem.classList.add(`cmp-carousel__item--hide-${direction}`);
      activeItem.classList.remove(CarouselClasses.active);
    }
    if (nextItem && nextItem.classList) {
      nextItem.classList.add(`cmp-carousel__item--show-${direction}`);
      nextItem.classList.add(CarouselClasses.active);
    }
  }

  private updatePagination() {
    if (!this.pagination) return;
    const text = [this.currentItem, this.paginationSeparator, this.totalItems];
    this.pagination.innerText = text.join("");
  }

  private update(type?: string) {
    if (this.paused) {
      return;
    }
    this.slider.goTo(type);
    // const currentItem = this.currentItem || this.getCurrentItem();
    // // Update the current item.
    // let newItem = type === "next" ? currentItem + 1 : currentItem - 1;
    //
    // if (newItem < 1) {
    //     newItem = 1;
    // } else if (newItem > this.totalItems) {
    //     if (this.autoplayEnabled) {
    //         newItem = 1;
    //     } else {
    //         newItem = this.totalItems;
    //     }
    // }
    //
    // this.previousItem = this.currentItem;
    // this.currentItem = newItem;
    // Update carousel items when type has a value; in other words, no need to update
    //  carousel items on the first call to update from the constructor.
    // if (type) {
    //     this.updateActiveItem();
    // }
    // Update the button state.
    //  this.updateNavButtonState();
    // Update pagination text.
    //   this.updatePagination();
  }

  private getCurrentItem() {
    const currentSlide = <HTMLElement>(
      this.container.querySelector(`${CarouselClasses.active}`)
    );
    if (!currentSlide || isNaN(parseInt(currentSlide.dataset.itemNumber, 10)))
      return 1;
    return parseInt(currentSlide.dataset.itemNumber, 10);
  }
}

export { Carousel };
