import { CartAPI, CartItem } from "./AskValV3";
import { Message } from "../../site/scripts/Message";
import { Utils } from "../../site/scripts/utils";
import { Cookie } from "../../site/scripts/utils";

enum Selector {
  hook = "data-cmp-hook-cart",
}

enum Classnames {
  displayNone = "display-none",
  modalActive = "cart-modal--active",
}

class Cart {
  component: HTMLElement;
  addedToCartMessage: string;
  maxChipMessage: string;
  maxChipMsgContainer: HTMLElement;
  maxCollectionMessage: string;
  maxCollectionMsgContainer: HTMLElement;
  closeButton: HTMLElement;
  contentDialog: any;
  overlay: HTMLElement;
  cartIcon: HTMLAnchorElement;
  productTitle: HTMLElement;
  itemAddedMessageContainer: HTMLElement;
  itemRemovedMessageContainer: HTMLElement;
  maximumReachedMessageContainer: HTMLElement;
  maxItemsAllowed = 10;
  maxCollectionsError = 1010;
  globalCartCount = 0;
  isChipsMaxedOut = false;
  isCollectionsMaxedOut = false;
  cartState: string;
  colorToAdd: string;
  colorToRemove: string;
  cartResponseCookie: {
    cart: CartItem[];
    cartItems: CartItem[];
    favorites: CartItem[];
  };
  itemCount: HTMLElement;
  badgeCount: HTMLElement;
  navHeader: HTMLElement;
  newCartSlider: boolean;
  originalHref: string = "";

  //public fields
  public static contentDialogField: any;
  public static overlayField: HTMLElement;
  public static yPosField: number;
  isV3Brand: boolean;
  brand: string;
  apiVersionV3: boolean;
  viewCartModalButton: any;

  constructor(component: HTMLElement) {
    if (!component) {
      return null;
    }

    this.component = component;
    this.newCartSlider = this.component.dataset?.newCart === "true";

    // Initialize brand and version information
    this.brand = Utils.getCbgBrand();
    this.apiVersionV3 = Utils.getCartVersionBoolean();
    this.isV3Brand = this.newCartSlider;
    this.initializeData();
    this.initializeUI();
    this.registerEventHandlers();
    this.registerMessageSubscribers();
  }

  private initializeData(publish = false) {
    if (this.isV3Brand) {
      // Fetch the cart data directly using the API method
      (async () => {
        const cartResponse = await CartAPI.getCart(publish);
        this.cartResponseCookie = cartResponse || {
          cart: [],
          cartItems: [],
          favorites: [],
        };
        this.updateComponentState(this.cartResponseCookie);
      })();
    }
  }

  private initializeUI() {
    // Initialize the authored message to display when a user adds an item.
    this.addedToCartMessage = this.component.dataset?.addedToCartMessage;

    // Initialize the container that holds the display message when a user adds an item.
    this.itemAddedMessageContainer = this.component.querySelector(
      `[${Selector.hook}="item-added"]`,
    );

    // Initialize the container that holds the remove message when a user adds an item.
    this.itemRemovedMessageContainer = this.component.querySelector(
      `[${Selector.hook}="item-removed"]`,
    );

    // Initialize the container that holds the display message when a user maxes out their cart chips.
    this.maxChipMsgContainer = this.component.querySelector(
      `[${Selector.hook}="maximum-chips-reached"]`,
    );
    this.maxChipMessage = this.maxChipMsgContainer?.dataset?.message || "";

    // Initialize the container that holds the display message when a user maxes out their cart collections.
    this.maxCollectionMsgContainer = this.component.querySelector(
      `[${Selector.hook}="maximum-collections-reached"]`,
    );
    this.maxCollectionMessage =
      this.maxCollectionMsgContainer?.dataset?.message || "";

    this.closeButton = this.component.querySelector(
      `[${Selector.hook}="close-button"]`,
    );

    this.contentDialog = this.component.querySelector(
      `[${Selector.hook}="content"]`,
    );

    Cart.contentDialogField = this.component.querySelector(
      `[${Selector.hook}="content"]`,
    );

    this.overlay = this.component.querySelector(`[${Selector.hook}="overlay"]`);

    Cart.overlayField = this.component.querySelector(
      `[${Selector.hook}="overlay"]`,
    );

    this.cartIcon = this.component.querySelector(
      `[${Selector.hook}="cart-icon"]`,
    );
    this.originalHref = this.cartIcon?.href || "";
    this.newCartSlider && this.cartIcon && (this.cartIcon.href = "");

    this.productTitle = this.component.querySelector(
      `[${Selector.hook}="product-title"]`,
    );

    this.itemCount = this.component.querySelector(
      `[${Selector.hook}="item-count--button"]`,
    );

    this.badgeCount = this.component.querySelector(
      `[${Selector.hook}="item-count--badge"]`,
    );

    this.viewCartModalButton = this.component.querySelector(
      `[${Selector.hook}="view-cart-button"]`,
    );

    this.navHeader =
      document.querySelector(".header-home") ||
      document.querySelector("header");
  }

  private registerEventHandlers() {
    this.newCartSlider &&
      this.cartIcon.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        // Broadcast a message with the response.
        Utils.msg.publish(Message.loadSliderModal, this.originalHref);
        return false;
      });

    this.closeButton?.addEventListener("click", () => {
      this.hideModal();
    });

    this.overlay?.addEventListener("click", () => {
      this.hideModal();
    });

    this.viewCartModalButton?.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.hideModal();
      this.cartIcon?.click();
    });

    window.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "Escape") this.hideModal();
    });

    this.contentDialog.addEventListener("focusout", (event: FocusEvent) => {
      if (!event.relatedTarget) return;

      const isOutsideHeader = !this.navHeader?.contains(
        event.relatedTarget as HTMLElement,
      );

      if (isOutsideHeader) {
        this.hideModal();
      }
    });
  }

  /**
   * Registers subscribers for published messages.
   */
  private registerMessageSubscribers() {
    Utils.msg.subscribe(Message.addToCart, this.updateColorToAdd.bind(this));

    Utils.msg.subscribe(Message.addToCartResponse, (response) => {
      this.updateComponentState(response, "add");
    });

    Utils.msg.subscribe(
      Message.removeFromCart,
      this.updateColorToRemove.bind(this),
    );

    Utils.msg.subscribe(Message.removeFromCartResponse, (response) => {
      this.updateComponentState(response, "remove");
    });

    Utils.msg.subscribe(Message.getCartResponse, (response) => {
      this.updateComponentState(response, "get");
    });

    window.addEventListener("parentEvent", () => {
      this.handleModalClose();
    });
  }

  private handleModalClose(): void {
    this.initializeData(true);
  }

  private updateColorToAdd(data) {
    const { colorName } = data;
    if (colorName) {
      this.colorToAdd = colorName;
    }
  }

  private updateColorToRemove(data) {
    const { colorName } = data;
    if (colorName) {
      this.colorToRemove = colorName;
    }
  }

  public static showModal(): void {
    if (document.body.classList.contains("slider-iframe-class")) {
      return;
    }
    document.documentElement.style.scrollBehavior = "auto";
    Cart.overlayField.classList.remove(Classnames.displayNone);

    if (window.matchMedia("(max-width: 768px)")) {
      Cart.yPosField = window.scrollY;
      document.body.style.top = `-${Cart.yPosField}px`;
    }

    document.body.classList.add(Classnames.modalActive);
    Cart.contentDialogField.show();
  }

  hideModal() {
    this.contentDialog.close();
    document.body.classList.remove(Classnames.modalActive);

    if (window.matchMedia("(max-width: 768px)")) {
      window.scrollTo({
        top: Cart.yPosField,
      });
      document.body?.style?.removeProperty("top");
    }

    this.overlay.classList.add(Classnames.displayNone);
    document.documentElement.style.scrollBehavior = "smooth";
  }

  showBadge(showMe = false): void {
    if (this.badgeCount) {
      if (showMe) this.badgeCount.classList.remove(Classnames.displayNone);
      else this.badgeCount.classList.add(Classnames.displayNone);
    }
  }

  private updateProductTitle(productTitle = ""): void {
    if (productTitle && productTitle?.length > 0) {
      this.productTitle.innerHTML = `${productTitle} `;
    }
  }

  private updateComponentState(
    response = { cart: [], cartItems: [], favorites: [] },
    action = "get",
  ) {
    const { cart, cartItems } = response;
    const count = this.isV3Brand ? cart?.length || 0 : cartItems?.length || 0;

    let colorName = null;

    if (action == "add") {
      colorName = this.colorToAdd;
      this.colorToAdd = null;
    }

    if (action == "remove") {
      colorName = this.colorToRemove;
      this.colorToRemove = null;
    }
    if (action == "get") {
      this.globalCartCount = count;
    }

    this.updateCartUI(
      {
        colorName,
        count,
        forceAddedMessage: false,
        action,
      },
      response,
    );

    if (action == "add") {
      Cart.showModal();
    }
  }

  private updateProductCount(count = 0) {
    if (this.itemCount) this.itemCount.innerHTML = `(${count})`;
    if (this.badgeCount) this.badgeCount.innerHTML = ` ${count} `;
    this.showBadge(count !== 0);
  }

  private updateModalContent(action, response, colorName = "") {
    const { cart, cartItems } = response;
    const errorCode = response.errors?.[0]?.code ?? null;

    this.isChipsMaxedOut = this.globalCartCount === this.maxItemsAllowed;
    this.isCollectionsMaxedOut = errorCode === this.maxCollectionsError;
    this.globalCartCount = this.isV3Brand ? cart?.length : cartItems?.length;

    if (action === "add") {
      this.updateAddedMessage(this.isChipsMaxedOut);
    }
    // Show the maxed out messages, when maxed out.
    this.updateMaxChipContainer(this.isChipsMaxedOut, colorName);
    this.updateMaxCollectionContainer(this.isCollectionsMaxedOut);
  }

  private updateAddedMessage(showMessage = false): void {
    // this.maxCollectionMsgContainer.classList.add(Classnames.displayNone);
    if (showMessage) {
      this.itemAddedMessageContainer.classList.add(Classnames.displayNone);
    } else if (!showMessage) {
      this.itemAddedMessageContainer.classList.remove(Classnames.displayNone);
    }
  }

  private updateMaxChipContainer(showMessage = false, colorName = ""): void {
    if (this.maxChipMsgContainer) {
      if (showMessage) {
        if (
          this.maxChipMessage.length > 0 &&
          this.maxChipMessage.indexOf("%color_name%") > -1
        ) {
          const message = this.maxChipMessage.replace(
            "%color_name%",
            colorName,
          );
          this.maxChipMsgContainer.innerHTML = message;
        }
        this.itemAddedMessageContainer.classList.add(Classnames.displayNone);
        this.maxCollectionMsgContainer.classList.add(Classnames.displayNone);
        this.maxChipMsgContainer.classList.remove(Classnames.displayNone);
      } else {
        this.itemAddedMessageContainer.classList.remove(Classnames.displayNone);
        this.maxChipMsgContainer.classList.add(Classnames.displayNone);
        this.maxCollectionMsgContainer.classList.add(Classnames.displayNone);
      }
    }
  }

  private updateMaxCollectionContainer(showMessage = false): void {
    if (this.maxCollectionMsgContainer) {
      if (showMessage) {
        if (this.maxCollectionMessage.length > 0) {
          this.maxCollectionMsgContainer.innerHTML = this.maxCollectionMessage;
          this.itemAddedMessageContainer.classList.add(Classnames.displayNone);
          this.maxCollectionMsgContainer.classList.remove(
            Classnames.displayNone,
          );
          this.maxChipMsgContainer.classList.add(Classnames.displayNone);
        } else {
          this.itemAddedMessageContainer.classList.add(Classnames.displayNone);
          this.maxChipMsgContainer.classList.remove(Classnames.displayNone);
          this.maxCollectionMsgContainer.classList.add(Classnames.displayNone);
        }
      }
    }
  }

  private updateCartUI(
    options = {
      colorName: "",
      count: 0,
      forceAddedMessage: false,
      action: "add",
    },
    response,
  ) {
    const { forceAddedMessage, colorName, count, action } = options;
    // Update the interface with the color name.
    this.updateProductTitle(colorName);

    if (count <= this.maxItemsAllowed) {
      // Update the product count.
      this.updateProductCount(count);
    }

    // Update the messages.
    this.updateModalContent(action, response, colorName);
  }
}

export { Cart };
