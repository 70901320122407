enum Selector {
  active = ".tns-nav-active",
  addChipBtn = ".cmp-carousel__content > .paint-splotch-btn-container > button",
  hook = ".tns-carousel",
  selectedSplotchData = ".tns-slide-active .splotch-color-data",
  selectedSplotchBtns = ".tns-slide-active .paint-splotch-btn-container",
  selectedSplotchFavBtn = ".tns-slide-active .cbg-favorite-cta",
  selectedSplotchChipToCartBtn = ".tns-slide-active .paint-splotch-chip-btn",
  selectedSplotchChipToCartTxt = ".paintSplotch .paint-splotch-chip-btn > span:first-child",
  splotchFavBtn = ".cmp-carousel__content > .splotch-color-data > .splotch-color-data--right > .cbg-favorite-cta",
  splotch = ".splotch-img",
  splotchContainer = ".splotch-img-container",
  splotchColorData = ".cmp-carousel__content > .splotch-color-data",
  splotchColorBtnContainer = ".cmp-carousel__content > .paint-splotch-btn-container",
}

class PaintSplotchCarousel {
  component: HTMLElement;
  regEvents: boolean;
  id: string;
  activeColor: HTMLElement;
  colorSplotch: HTMLElement;
  colorSplotchContainer: HTMLElement;
  splotchColorData: HTMLElement;
  splotchColorBtns: HTMLElement;
  favIcon: HTMLElement;
  carousel: HTMLElement;
  chipBtn: HTMLElement;
  chipBtnTxt: Array<HTMLElement>;

  constructor(component: HTMLElement, regEvents: boolean) {
    // Elements
    this.component = component;
    this.regEvents = regEvents;

    if (!this.component) {
      return null;
    }

    this.id = this.component.id;
    this.updateColorData();
    this.updateSplotchColor();
    if (this.regEvents) this.registerEvents();
  }

  private updateSplotchColor() {
    this.activeColor = document.querySelector(
      this.buildSelector(Selector.active),
    );
    this.colorSplotch = document.querySelector(
      this.buildSelector(Selector.splotch),
    );
    this.colorSplotchContainer = document.querySelector(
      this.buildSelector(Selector.splotchContainer),
    );

    this.colorSplotch.setAttribute(
      "style",
      this.activeColor.getAttribute("style"),
    );
    this.colorSplotchContainer.setAttribute(
      "style",
      this.activeColor.getAttribute("style"),
    );
  }

  private updateColorData() {
    this.splotchColorData = document.querySelector(
      this.buildSelector(Selector.splotchColorData),
    );
    this.splotchColorBtns = document.querySelector(
      this.buildSelector(Selector.splotchColorBtnContainer),
    );

    this.splotchColorData.innerHTML = document.querySelector(
      this.buildSelector(Selector.selectedSplotchData),
    ).innerHTML;

    this.addFavEventListener();

    this.splotchColorBtns.innerHTML = document.querySelector(
      this.buildSelector(Selector.selectedSplotchBtns),
    ).innerHTML;

    this.addChipToCartListener();
  }

  private buildSelector(selector) {
    return this.component.id == ""
      ? `.paintSplotchCarousel ${selector}`
      : `.paintSplotchCarousel > #${this.component.id} ${selector}`;
  }

  private registerEvents() {
    this.carousel = this.component.querySelector(
      `${this.buildSelector(Selector.hook)}`,
    );

    this.carousel.addEventListener(
      "transitionend",
      this.updateSplotch.bind(this),
    );

    const observer = new MutationObserver(PaintSplotchCarousel.cartBtnWatcher);

    this.chipBtnTxt = Array.from(
      document.querySelectorAll(
        `${this.buildSelector(Selector.selectedSplotchChipToCartTxt)}`,
      ),
    );

    this.chipBtnTxt.forEach((el) => {
      observer.observe(el, { attributes: true });
    });

    PaintSplotchCarousel.cartBtnWatcher(this.chipBtnTxt);

    this.addFavEventListener();
  }

  private addFavEventListener() {
    this.favIcon = this.component.querySelector(
      `${this.buildSelector(Selector.splotchFavBtn)}`,
    );

    this.favIcon.addEventListener(
      "click",
      PaintSplotchCarousel.clickActiveFav.bind(
        "click",
        this.buildSelector(Selector.selectedSplotchFavBtn),
        this,
      ),
    );
  }

  private addChipToCartListener() {
    this.chipBtn = document.querySelector(
      `${this.buildSelector(Selector.addChipBtn)}`,
    );

    this.chipBtn.addEventListener(
      "click",
      PaintSplotchCarousel.clickActiveChipToCartBtn.bind(
        "click",
        this.buildSelector(Selector.selectedSplotchChipToCartBtn),
        this,
      ),
    );
  }

  private static clickActiveFav(sel, carousel, e) {
    e.preventDefault();
    e.stopPropagation();
    carousel.activeFav = document.querySelector(sel);
    carousel.activeFav.click();
    carousel.updateSplotch(carousel);
  }

  private static clickActiveChipToCartBtn(sel, carousel, e) {
    e.preventDefault();
    e.stopPropagation();
    carousel.chipBtn = document.querySelector(sel);
    carousel.chipBtn.click();
    carousel.updateSplotch(carousel);
  }

  private updateSplotch(this, e?) {
    this.updateColorData();
    this.updateSplotchColor();
  }

  private static cartBtnWatcher(el) {
    if (el[0].attributeName === "class") {
      new PaintSplotchCarousel(
        <HTMLElement>(
          el[0].target.closest(".paintSplotchCarousel .cbg-cmp-carousel")
        ),
        false,
      );
    }
  }
}
export { PaintSplotchCarousel };
