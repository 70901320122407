interface NewsItem {
  date: string;
  title: string;
  pdfOrPageLocation: string;
  openInNewWindow: boolean;
  summary: string;
  readMore: boolean;
}

export class NewsLetter {
  private container: HTMLElement;
  dateFormat: string;

  constructor(container: HTMLElement) {
    this.container = container;
    this.dateFormat = this.container.dataset.dateFormat;
    this.formatNewsletterFromHTML();
  }

  public formatDate(date: string | Date, format: string): string {
    const languageCode = document.documentElement.lang || "en-US";
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();

    const monthShort = new Intl.DateTimeFormat(languageCode, { month: "short" })
      .format(d)
      .replace(".", "");
    const monthFull = new Intl.DateTimeFormat(languageCode, {
      month: "long",
    }).format(d);

    const suffix = (day: number) => ["st", "nd", "rd"][(day % 10) - 1] || "th";

    switch (format) {
      case "MM/DD/YYYY":
        return `${(month + 1).toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`;
      case "DD/MM/YYYY":
        return `${day.toString().padStart(2, "0")}/${(month + 1).toString().padStart(2, "0")}/${year}`;
      case "Jan 1st, YYYY":
        return languageCode.startsWith("es")
          ? `${day} de ${monthShort} de ${year}`
          : `${monthShort} ${day}${suffix(day)}, ${year}`;
      case "January 1st, YYYY":
        return languageCode.startsWith("es")
          ? `${day} de ${monthFull} de ${year}`
          : `${monthFull} ${day}${suffix(day)}, ${year}`;
      default:
        throw new Error("Unknown format type");
    }
  }

  // Function to extract and format news data
  private formatNewsletterFromHTML(): void {
    // Extract the HTML content
    const newsContent = this.container.querySelector(".newsletter-content");
    if (!newsContent) {
      console.error("Newsletter content not found.");
      return;
    }

    // Parse news items from HTML
    const newsItems: NewsItem[] = Array.from(
      newsContent.querySelectorAll<HTMLElement>(".news-item"),
    ).map((item) => ({
      date: item.querySelector(".news-date")?.textContent?.trim() || "",
      title: item.querySelector(".news-item-title")?.textContent?.trim() || "",
      pdfOrPageLocation:
        item.querySelector(".news-item-title")?.getAttribute("href") || "",
      openInNewWindow: item.dataset.openinnewwindow === "true",
      readMore: item.dataset.readmore === "true",
      summary: item.querySelector(".news-item-description")?.innerHTML || "",
    }));

    // Group by year and sort items within each year
    const newsItemsByYear = newsItems.reduce<{ [year: string]: NewsItem[] }>(
      (acc, item) => {
        const year = new Date(item.date).getFullYear().toString();
        acc[year] = [...(acc[year] || []), item].sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        );
        return acc;
      },
      {},
    );

    // Sort years in descending order and map to an array
    const sortedNewsItemsByYear = Object.entries(newsItemsByYear)
      .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA))
      .map(([year, items]) => ({ year, items }));

    // Create HTML content
    const htmlContent = sortedNewsItemsByYear
      .map(
        ({ year, items }) => `
      <h2 class="news-year">${year}</h2>
      ${items
        .map((item) => {
          // Set the target attribute value
          const target = item.openInNewWindow ? "_blank" : "_self";

          // Update anchor tags in the summary to add or modify the target attribute
          const summary = item.summary
            ? item.summary.replace(
                /<a(?![^>]*\btarget=)/g,
                `<a target="${target}"`,
              )
            : "";

          return `
          <article class="news-item">
            <div class="news-date">${this.formatDate(item.date, this.dateFormat)}</div>
            <h3 class="newsletter-source">
              ${
                item.title
                  ? `
                <a href="${item.pdfOrPageLocation}" class="news-item-title" target="${target}">
                  ${item.title}
                </a>
              `
                  : ""
              }
              <div class="news-item-description">${summary}</div>
            </h3>
          </article>
        `;
        })
        .join("")}
    `,
      )
      .join("");

    newsContent.innerHTML = htmlContent;
  }
}
