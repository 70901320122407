import { Message } from "../../site/scripts/Message";
import { Cookie, Utils } from "../../site/scripts/utils";
import { PdfGenerator } from "../../site/scripts/PdfGenerator";

enum Classnames {
  displayNone = "display-none",
  iconAdd = "icon-add",
  iconRemove = "icon-remove",
  addFavStyle = "fav-add",
}

enum Cookiename {
  cart = "cart",
}

enum channelIds {
  "cbg:lowes" = 1001,
  "cbg:independent-retailer" = 1003,
  "cbg:hgsw" = 1006,
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-colorDetail",
}

class ColorDetail {
  //Elements
  component: HTMLElement;
  chipButton: HTMLElement;
  chipTextRemove: HTMLElement;
  chipTextAdd: HTMLElement;

  tagEmblem: string;
  href: string;
  colorNumber: string;
  collection: string;
  tagsAsAString: string;

  // Properties
  channelId: number;
  colorId: string;
  colorTitle: string;
  chipCookie: string;
  tags: string;
  pdfLogo: string;
  pdfDisclaimer: string;

  constructor(component: HTMLElement) {
    this.component = component;

    if (!this.component) {
      return;
    }

    this.chipCookie = Cookie.get(Cookiename.cart);
    this.colorId = this.component.dataset.colorId;
    this.colorTitle = this.component.dataset.colorTitle;
    this.tags = this.component.dataset.colorTags;
    this.channelId = this.channelId = this.getChannelId(this.tags);
    this.pdfLogo = this.component.dataset.pdfLogo;
    this.pdfDisclaimer = this.component.dataset.pdfDisclaimer;

    this.initializeUI();
    this.registerEventHandlers();
    this.registerMessageSubscribers();

    if (this.chipCookie) {
      this.updatedCta();
    }

    const originalElement = document.querySelector(
      ".cmp-container",
    ) as HTMLElement;

    const sharingButton: HTMLElement = this.component.querySelector(
      ".cmp-colordetail__sharing-button",
    ) as HTMLElement;
    if (sharingButton) {
      sharingButton.addEventListener("click", () => {
        const hiddenDiv: HTMLDivElement = document.createElement("div");
        hiddenDiv.style.width = "600px";
        hiddenDiv.style.height = "1113px";
        hiddenDiv.className = "pdf-sharing pdf-colordetail";
        document.body.appendChild(hiddenDiv);

        const pdfGenerator = new PdfGenerator({
          sharingButtonClass: "cmp-colordetail__sharing-button",
          fileName: this.colorTitle + ".pdf",
        });
        this.generatePdfLayout(hiddenDiv);
        pdfGenerator.generatePdf(hiddenDiv);
      });
    }
  }

  private generatePdfLayout(pdf: HTMLDivElement): void {
    const dynamicFlexContainers = document.querySelectorAll<HTMLElement>(
      ".dynamicFlexContainer",
    );

    //header
    const logoContainer: HTMLDivElement = document.createElement("div");
    logoContainer.classList.add("logo-container");
    const logo = document.createElement("img");
    logo.src = this.pdfLogo;
    logo.classList.add("cmp-image__image");
    logoContainer.appendChild(logo.cloneNode(true));
    pdf.appendChild(logoContainer);

    //top half flex container
    const topHalf: HTMLDivElement = document.createElement("div");
    topHalf.classList.add("top-half");

    //featured color
    const featuredColor: HTMLDivElement = document.createElement("div");
    const featuredColorTitle: HTMLDivElement = document.createElement("div");
    featuredColorTitle.classList.add("cmp-dynamicFlexContainer__title");
    featuredColorTitle.textContent = "Featured Color";
    featuredColor.appendChild(featuredColorTitle);
    featuredColor.classList.add("featured-color");
    const featuredColorHexContainer: HTMLDivElement = document.querySelector(
      ".colordetail .colordetail-block.card-hex-div",
    );

    featuredColor.appendChild(featuredColorHexContainer.cloneNode(true));
    const featuredColorHex: string =
      featuredColorHexContainer.style.backgroundColor;
    const featuredInfoContainer: HTMLDivElement = document.createElement("div");
    featuredInfoContainer.classList.add("cmp-dynamicCard__card-info-container");
    const featuredColorName: HTMLDivElement = document.createElement("div");
    featuredColorName.classList.add("cmp-dynamicCard__card-color-name");
    featuredColorName.textContent = this.colorTitle;
    featuredColorName.style.color = this.getDynamicTextColor(featuredColorHex);
    featuredInfoContainer.appendChild(featuredColorName);
    const featuredColorId: HTMLDivElement = document.createElement("div");
    featuredColorId.classList.add("cmp-dynamicCard__card-color-id");
    featuredColorId.textContent = this.colorId;
    featuredColorId.style.color = this.getDynamicTextColor(featuredColorHex);
    featuredInfoContainer.appendChild(featuredColorId);
    const pdfFeaturedColorSwatch =
      featuredColor.querySelector(".colordetail-block");
    pdfFeaturedColorSwatch.appendChild(featuredInfoContainer);

    topHalf.appendChild(featuredColor.cloneNode(true));

    //Coordinating colors
    const coordinatingColorsContainer = Array.from(dynamicFlexContainers).find(
      (container) => container.textContent?.includes("Coordinating Colors"),
    );
    topHalf.appendChild(coordinatingColorsContainer.cloneNode(true));
    pdf.appendChild(topHalf);

    //Related Shades
    const relatedShades: HTMLDivElement = document.createElement("div");
    relatedShades.classList.add("related-shades");
    const relatedShadesContainer = Array.from(dynamicFlexContainers).find(
      (container) => container.textContent?.includes("Related Shades"),
    );
    relatedShades.appendChild(relatedShadesContainer.cloneNode(true));
    pdf.appendChild(relatedShades);

    //Updating swatch backgrounds and dynamic text color
    const colorSwatches = document.querySelectorAll(
      ".pdf-colordetail .coordinating-colors .cmp-dynamicCard__card-color-swatch, .pdf-colordetail .cmp-dynamicCard__card-color-swatch",
    );

    colorSwatches.forEach((swatch: HTMLElement) => {
      const color = swatch.style.backgroundColor;
      const imageContainer: HTMLElement = swatch
        .closest(".cmp-dynamicCard__card-container")
        ?.querySelector(".cmp-dynamicCard__image-container");

      if (color && imageContainer) {
        imageContainer.style.backgroundColor = color;

        const colorName: HTMLElement = imageContainer.querySelector(
          ".cmp-dynamicCard__card-color-name",
        );
        const colorId: HTMLElement = imageContainer.querySelector(
          ".cmp-dynamicCard__card-color-id",
        );

        if (colorName && colorId) {
          const textColor = this.getDynamicTextColor(color);
          colorName.style.color = textColor;
          colorId.style.color = textColor;
        }
      }
    });

    //disclaimer
    const disclaimer = document.createElement("p");
    disclaimer.classList.add("disclaimer");
    disclaimer.textContent = this.pdfDisclaimer;
    pdf.appendChild(disclaimer);
  }

  private getDynamicTextColor(backgroundColor) {
    const match = backgroundColor.match(/rgba?\((\d+), (\d+), (\d+)/);
    if (!match) return "black";

    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    return brightness > 128 ? "black" : "white";
  }

  private getChannelId(tags: string): number {
    // if no channelId can be determined, set to 0
    if (!tags) {
      return 0;
    }

    const key = Object.keys(channelIds).find((key) => tags.includes(key));

    return channelIds[key];
  }

  private initializeUI(): void {
    this.chipButton = this.component.querySelector(
      `[${Selector.hook}="chip-btn"]`,
    );

    this.chipTextAdd = this.component.querySelector(
      `[${Selector.hook}="chip-text-add"]`,
    );

    this.chipTextRemove = this.component.querySelector(
      `[${Selector.hook}="chip-text-remove"]`,
    );
  }

  private registerEventHandlers(): void {
    if (this.chipButton) {
      this.chipButton.addEventListener(
        "click",
        this.updateColorChip.bind(this),
      );
    }
  }

  private registerMessageSubscribers(): void {
    // Subscribe to the color chip update publisher.
    Utils.msg.subscribe(
      Message.updateColorChipState,
      this.updatedCta.bind(this),
    );
  }

  private getUpdatedCookie() {
    const cookie = Cookie.get(Cookiename.cart);
    const colorChipArray = JSON.parse(cookie);
    return colorChipArray;
  }

  private updatedCta(): void {
    const colorId = this.colorId;
    const channelId = this.channelId;
    const chipTextAdd = this.chipTextAdd;
    const chipTextRemove = this.chipTextRemove;

    if (chipTextRemove.classList.contains(Classnames.displayNone)) {
      const cookieArray = this.getUpdatedCookie();
      const itemToAdd = cookieArray.findIndex((i) => {
        return i.colorNumber === colorId;
      });
      if (itemToAdd > -1) {
        const itemToADD = cookieArray.filter((i) => {
          return i.colorNumber === colorId;
        });
        const colorID = itemToADD[0].id;
        chipTextAdd.classList.add(Classnames.displayNone);
        chipTextRemove.classList.remove(Classnames.displayNone);
        Utils.msg.publish(Message.addToCart, {
          colorNumber: `${colorID}`,
          channelId: channelId,
        });
      }
    } else {
      const cookieArray = this.getUpdatedCookie();
      const itemToRemove = cookieArray.filter((i) => {
        return i.colorNumber === colorId;
      });

      const colorID = itemToRemove[0].id;
      chipTextAdd.classList.remove(Classnames.displayNone);
      chipTextRemove.classList.add(Classnames.displayNone);
      Utils.msg.publish(Message.removeFromCart, {
        id: `${colorID}`,
      });
    }
  }

  private updateColorChip(): void {
    const colorId = this.colorId;
    const channelId = this.channelId;
    const chipTextAdd = this.chipTextAdd;
    const chipTextRemove = this.chipTextRemove;
    if (chipTextRemove.classList.contains(Classnames.displayNone)) {
      chipTextAdd.classList.add(Classnames.displayNone);
      chipTextRemove.classList.remove(Classnames.displayNone);
      Utils.msg.publish(Message.addToCart, {
        colorNumber: `${colorId}`,
        channelId: channelId,
      });
    } else {
      const cookieArray = this.getUpdatedCookie();
      const itemToRemove = cookieArray.filter((i) => {
        return i.colorNumber === colorId;
      });

      const colorID = itemToRemove[0].id;
      chipTextAdd.classList.remove(Classnames.displayNone);
      chipTextRemove.classList.add(Classnames.displayNone);
      Utils.msg.publish(Message.removeFromCart, {
        id: `${colorID}`,
      });
    }
  }
}

export { ColorDetail };
