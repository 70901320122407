import { throttle } from "lodash";
import { Cookie } from "../../site/scripts/utils";

class CookiePersist {
  private window = window as any;
  container: HTMLElement;
  queryParamName: string;
  queryParamValue: string;
  cookieName: string;
  cookieValue: string;
  cookiePersistDays: number;

  constructor(component: HTMLElement) {
    this.container = component;

    if (!this.container) {
      return;
    }

    this.window = window;
    this.queryParamName = this.container.dataset.queryParamName;
    this.queryParamValue = this.getQueryParamValue();
    this.cookieName = this.container.dataset.cookieName;
    this.cookiePersistDays = parseInt(this.container.dataset.cookiePersistDays);

    this.persistQueryParamValueAsCookie();
  }

  private getQueryParamValue(): string {
    const params = new URLSearchParams(this.window.location.search);
    return params.get(this.queryParamName) || "";
  }

  private persistQueryParamValueAsCookie(): void {
    if (this.queryParamValue) {
      Cookie.set(this.cookieName, this.queryParamValue, this.cookiePersistDays);
    }
  }
}

export { CookiePersist };
