enum AlgoliaIndexNames {
  autoComplete = "wp_index_query_suggestions",
  colorFamily = "wp_color_families_index",
  color = "wp_color_index",
  product = "wp_products_index",
  content = "wp_post_index",
}
const APPID = "M7SV5EUMGF";
const APPKEY = "945332ac424bfc8fd13586df10b641ee";

export { AlgoliaIndexNames, APPID, APPKEY };
