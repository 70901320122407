import { Gtm4 } from "../components/gtm4/gtm4";

// Google Tag Manager dataLayer
window.dataLayer = window.dataLayer || [];
window.gtm4 = new Gtm4();

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    gtm4: Gtm4;
  }
}

// Stylesheets
import "./main.scss";

// Javascript or Typescript
import "../components";
