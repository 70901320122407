class ContactUs {
  // Elements
  container: HTMLFormElement;

  programName: string;
  private endpoint: string;
  private successPage: string;
  private submitBtn: HTMLButtonElement;

  constructor(component: HTMLFormElement) {
    this.container = component;
    this.submitBtn = this.container.querySelector('button[type="submit"]');

    if (!this.container) {
      return null;
    }

    const data = this.container.dataset;

    this.endpoint = data.endpoint;
    this.successPage = data.successPage;

    this.container.addEventListener("submit", this.sendData.bind(this));
  }

  private beginLoading() {
    document.body.style.cursor = "wait";
    this.submitBtn.disabled = true;
  }

  private finishLoading() {
    document.body.style.cursor = "";
    this.submitBtn.disabled = false;
  }

  private sendData(event: Event): boolean {
    event.preventDefault();
    this.beginLoading();

    if (!this.container.checkValidity()) {
      this.finishLoading();
      return false;
    }

    const formDataValues: FormData = new FormData(this.container);
    const newFormData: FormData = new FormData();

    formDataValues.forEach((value, name) => {
      if (value) {
        newFormData.append(name, value);
      }
    });

    const { fromEmail, emailSubject, toEmails } = this.container.dataset;

    const userEmailFieldName =
      this.container.dataset?.userEmailFieldName || "email";
    const userEmail: HTMLInputElement = this.container.querySelector(
      `[name=${userEmailFieldName}]`,
    );
    newFormData.append("noReplyEmail", fromEmail);
    newFormData.append("userEmail", userEmail.value);
    newFormData.append("emailSubject", emailSubject);
    newFormData.append("recipientEmail", toEmails);

    this.postData(newFormData).then((res) => res);
  }

  private async postData(requestData): Promise<void> {
    if (!this.endpoint) {
      throw new Error("Endpoint is not set");
      this.finishLoading();
      return;
    }

    try {
      const response = await fetch(this.endpoint, {
        method: "POST",
        body: requestData,
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      });

      const data = await response.text();

      if (response.ok || (response.status >= 200 && response.status < 400)) {
        this.container.reset();
        if (this.successPage) {
          location.href = this.successPage;
        } else {
          this.finishLoading();
        }
      } else {
        this.finishLoading();
      }
    } catch (err) {
      this.finishLoading();
      throw err;
    }
  }
}

export { ContactUs };
