import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";

declare global {
  interface Window {
    BV: any;
    bvCallback: any;
  }
}

enum Classnames {
  modalActive = "modal__active",
}

class Button {
  container: HTMLElement;
  preselected: string;
  cookieCategory: string;
  buttonpath: string;
  tracklabel: string;
  tracktype: string;
  trackvalue: string;
  trackingData: any;
  hasRun = false;
  modalBackgroundCenter: number;
  modalButton: HTMLElement;
  buttonPosition: DOMRect;

  constructor(component: HTMLElement) {
    // Elements -Build
    this.container = component;
    this.preselected = this.container.dataset.preselected;
    this.cookieCategory = this.container.dataset.cookieCategory;
    this.buttonpath = this.container.dataset.buttonPath;
    this.tracklabel = this.container.dataset.cmpTrackLabel;
    this.tracktype = this.container.dataset.cmpTrackType;
    this.trackvalue = this.container.dataset.cmpTrackValue;
    this.trackingData = this.container.dataset.cmpTrackBazaarVoice;
    this.modalButton = this.container.querySelector(".modal-button");

    if (!this.container) {
      return null;
    }
    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    document
      .querySelector(".button-home")
      .addEventListener("click", this.bvTrackingClickHandler.bind(this));
    this.container.addEventListener(
      "click",
      this.globalClickHandler.bind(this),
    );
    if (this.modalButton !== null) {
      //Get the position of button before click
      this.buttonPosition = this.modalButton.getBoundingClientRect();
      this.modalButton.addEventListener(
        "click",
        this.preventScrollWhenModalOpen.bind(this),
      );
    }
  }

  private globalClickHandler(event) {
    const allpaths = event.target;
    let href = "";
    if (allpaths && typeof allpaths.forEach === "function") {
      allpaths.forEach((element) => {
        if (element.href) {
          href = element.href;
          if (href == "close-modal") {
            event.preventDefault();
          }
        }
      });
    }

    const preselectedArray = this.preselected
      ? JSON.parse(this.preselected)
      : null;

    if (preselectedArray) {
      if (this.cookieCategory) {
        Cookie.set(this.cookieCategory, preselectedArray[0].id, 30);
        Utils.msg.publish(Message.cookieUpdated, {});
      }
    }

    let filterkey = this.getFilterListKey(href);

    if (!filterkey) {
      filterkey = this.buttonpath;
    }
    //store these in the Cookie
    const cookieArray = this.preselected ? JSON.parse(this.preselected) : null;

    if (cookieArray && filterkey) {
      const cookieJSON = JSON.stringify(cookieArray);
      Cookie.set(filterkey, cookieJSON, 30);
    }
    if (this.buttonpath === "close-modal") {
      event.preventDefault();
      //this is a hack, refactor later
      this.closeThisModal("#lowes-confirmation");
      this.closeThisModal("#ir-confirmation");
      //MESP 8655 - To relaod on while switching between Lowes and IR
      if (Utils.getCbgBrand() === "valspar") {
        location.href = location.href.split("#")[0];
      }
    }
  }

  private closeThisModal(modalId) {
    if (modalId) {
      const gotSomething = document.querySelector(modalId);
      if (gotSomething) {
        const childNode = gotSomething.querySelector(".cbg-cmp-modal");
        if (childNode) {
          childNode.classList.remove(Classnames.modalActive);
        }
      }
    }
  }

  private bvTrackingClickHandler() {
    const ConversionData = {
      label: this.tracklabel,
      type: this.tracktype,
      value: this.trackvalue,
    };
    if (this.trackingData) {
      window.bvCallback = function (BV) {
        BV.pixel.trackConversion(ConversionData);
      };
    }
  }

  private getFilterListKey(fullURL: string) {
    const pathToTrim = fullURL;
    const nextSlash = pathToTrim.lastIndexOf("/");
    const ending = fullURL.indexOf(".html");
    let generatedFilterKey = "";
    if (ending > -1) {
      generatedFilterKey = fullURL.substring(nextSlash, ending);
    } else {
      generatedFilterKey = fullURL.substring(nextSlash);
    }
    return generatedFilterKey;
  }

  private preventScrollWhenModalOpen() {
    this.buttonPosition = this.modalButton.getBoundingClientRect();
    if (!this.hasRun) this.calcAndSetCenterPoint();
    document.body.style.top = `-${this.modalBackgroundCenter}px`;
    const modalId = this.modalButton?.dataset["opensModal"];
    const findModal: any = modalId ? document.querySelector(modalId) : document;
    findModal
      .querySelector(".modal__button--close")
      .addEventListener("click", this.calcAndSetCenterPoint.bind(this));
  }

  private calcAndSetCenterPoint() {
    const centerPosition = this.buttonPosition?.top - screen.height * 0.5;
    this.modalBackgroundCenter = centerPosition;
    this.hasRun = true;
  }
}

export { Button };
