import { RatingsReviews } from "./RatingsReviews";
import resizeRatings from "./ResizeRatings";

// Initialize all wall components.
document.querySelectorAll('[data-cbg-cmp="ratings-reviews"]').forEach((el) => {
  new RatingsReviews(<HTMLElement>el);

  if (el.classList.contains("ratings-reviews--bvReviews")) {
    resizeRatings();
  }
});
