import { Form } from "./Form";
import { FormText } from "./FormText";
import { Fieldset } from "./Fieldset";
import { Marketo } from "./Marketo";
import { LYCG } from "./LYCG";
import { Disclaimer } from "./Disclaimer";
import { ContactUs } from "./ContactUs";

document.querySelectorAll('[data-cbg-cmp="options"]').forEach((el) => {
  new Fieldset(<HTMLFieldSetElement>el);
});

document.querySelectorAll('[data-cbg-cmp="disclaimer"]').forEach((el) => {
  new Disclaimer(<HTMLDivElement>el);
});

document.querySelectorAll('[data-cbg-cmp="formText"]').forEach((el) => {
  new FormText(<HTMLElement>el);
});

document.querySelectorAll('[data-cbg-cmp="formContainer"]').forEach((el) => {
  new Form(<HTMLFormElement>el);
});

document.querySelectorAll('[data-form-type="contactUs"]').forEach((el) => {
  new ContactUs(<HTMLFormElement>el);
});

document.querySelectorAll('[data-is-marketo="true"]').forEach((el) => {
  new Marketo(<HTMLFormElement>el);
});

document.querySelectorAll('[data-is-lycg="true"]').forEach((el) => {
  new LYCG(<HTMLFormElement>el);
});
