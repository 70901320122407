enum InputTypes {
  DropDown,
  Radio,
  CheckBox,
  MultiDropDown,
}

class Fieldset {
  element: HTMLElement;
  requirementFulfilled: boolean;
  inputs: Array<HTMLInputElement>;
  firstInput: HTMLInputElement;
  selectElement: HTMLSelectElement;
  parentForm: HTMLFormElement;
  errorMessageSpan: HTMLSpanElement;
  inputType: InputTypes;

  get hasSelectElements(): boolean {
    return (
      this.inputType == InputTypes.DropDown ||
      this.inputType == InputTypes.MultiDropDown
    );
  }

  constructor(element: HTMLFieldSetElement) {
    this.element = element;
    this.setType();

    this.errorMessageSpan = this.element.querySelector(
      ".invalid-input-message",
    );

    this.errorMessageSpan.innerText =
      "Please choose at least one option from the above list";

    const inputs = this.element.querySelectorAll("input");
    this.inputs = Array.prototype.slice.call(inputs);

    this.registerEventHandlers();
  }

  // configure input type and form reference depending on type of input
  setType(): void {
    if (this.element.classList.contains("cmp-form-options--checkbox")) {
      this.firstInput = this.element.querySelector("input");
      this.parentForm = this.firstInput.form;
      this.inputType = InputTypes.CheckBox;
      this.firstInput.required = true;
      return;
    } else if (this.element.classList.contains("cmp-form-options--radio")) {
      this.firstInput = this.element.querySelector("input");
      this.parentForm = this.firstInput.form;
      this.inputType = InputTypes.Radio;
      return;
    } else if (this.element.classList.contains("cmp-form-options--drop-down")) {
      this.selectElement = this.element.querySelector("select");
      this.parentForm = this.selectElement.form;
      this.inputType = InputTypes.DropDown;
      return;
    } else if (
      this.element.classList.contains("cmp-form-options--multi-drop-down")
    ) {
      this.selectElement = this.element.querySelector("select");
      this.parentForm = this.selectElement.form;
      this.inputType = InputTypes.MultiDropDown;
      return;
    }

    console.error("Unable to determine input type");
  }

  // Determine if elements should be validated -- shouldn't validate when elements are hidden
  private shouldValidate(): boolean {
    if (
      this.inputType == InputTypes.CheckBox &&
      this.inputs[0]?.offsetParent === null
    ) {
      return false;
    }

    if (
      (this.inputType == InputTypes.CheckBox ||
        this.inputType == InputTypes.Radio) &&
      this.firstInput.offsetParent === null
    ) {
      return false;
    }

    if (this.hasSelectElements && this.selectElement.offsetParent === null) {
      return false;
    }

    return true;
  }

  validateRequired(): void {
    if (!this.shouldValidate()) {
      return;
    }

    if (
      this.inputType == InputTypes.CheckBox &&
      this.checkBoxesAreValid() == false
    ) {
      this.showErrorMessage();
      return;
    }

    if (this.inputType == InputTypes.CheckBox) {
      this.firstInput.required = false;
    }

    if (
      this.inputType == InputTypes.Radio &&
      !this.firstInput.checkValidity()
    ) {
      this.showErrorMessage();
      return;
    }

    if (this.hasSelectElements && !this.selectElement.checkValidity()) {
      this.showErrorMessage();
      return;
    }

    this.hideErrorMessage();
  }

  showErrorMessage(): void {
    this.errorMessageSpan.classList.add("display-block");
  }

  hideErrorMessage(): void {
    this.errorMessageSpan.classList.remove("display-block");
  }

  private registerEventHandlers(): void {
    this.parentForm.addEventListener(
      "submit",
      this.validateRequired.bind(this),
    );

    if (this.selectElement) {
      this.selectElement.addEventListener(
        "change",
        this.optionFieldsOnChange.bind(this),
      );

      return;
    }

    if (this.inputType == InputTypes.CheckBox) {
      this.inputs.forEach((cb) => {
        cb.addEventListener("change", this.checkboxOnChange.bind(this));
      });

      return;
    }

    this.inputs.forEach((cb) => {
      cb.addEventListener("change", this.optionFieldsOnChange.bind(this));
    });
  }

  optionFieldsOnChange(): void {
    if (this.selectElement && !this.selectElement.checkValidity()) {
      this.showErrorMessage();
      return;
    }

    if (this.firstInput && !this.firstInput.checkValidity()) {
      this.showErrorMessage();
      return;
    }

    this.hideErrorMessage();
  }

  checkBoxesAreValid(): boolean {
    const checkedInputs = this.inputs.filter((input) => {
      return input.checked;
    });
    const isValid = checkedInputs.length > 0;

    return isValid;
  }

  checkboxOnChange(): void {
    if (!this.checkBoxesAreValid()) {
      this.showErrorMessage();
      return;
    }

    this.hideErrorMessage();
  }
}

export { Fieldset };
