enum Selectors {
  hook = "data-cmp-hook-languageToggle",
}

class LanguageToggle {
  component: HTMLElement;
  dropdown: HTMLElement;

  constructor(component: HTMLElement) {
    // Elements
    this.component = component;
    if (!this.component) {
      return null;
    }
    this.dropdown = this.component.querySelector(
      `[${Selectors.hook}="dropdown"]`,
    );

    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    if (this.dropdown) {
      this.dropdown.addEventListener("change", (event) => {
        // Get the value of the dropdown.
        const target = <HTMLSelectElement>event.target;
        // Get language URL
        const url: string =
          target.options[target.selectedIndex].dataset.languageUrl;
        // Check to see if URL should be opened in same or new tab
        const newTab: string =
          target.options[target.selectedIndex].dataset.newWin;
        // Open language URL in new tab
        if (url && newTab) {
          window.open(target.options[target.selectedIndex].dataset.languageUrl);
          // Open language URL in the same tab
        } else if (url && !newTab) {
          window.open(
            target.options[target.selectedIndex].dataset.languageUrl,
            "_self",
          );
        }
      });
    }
  }
}

export { LanguageToggle };
