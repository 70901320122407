import { Message } from "src/main/site/scripts/Message";
import { Utils } from "src/main/site/scripts/utils";

enum Classnames {
  modalActive = "open",
  modalFullScreen = "modal__fullscreen",
  loading = "modal-loading",
}

export class SliderModal {
  private modalWrapper: HTMLElement;
  private modalOverlay: HTMLElement;
  private modalSlider: HTMLElement;
  private iframe: HTMLIFrameElement;
  private allowedUrls: string[] = ["cart"];
  private sliderClose: HTMLElement;
  private loadingIndicator: HTMLElement;

  constructor(container: HTMLElement) {
    this.modalWrapper = container.querySelector(
      ".modal-wrapper",
    ) as HTMLElement;
    this.modalOverlay = this.modalWrapper.querySelector(
      ".modal-overlay",
    ) as HTMLElement;
    this.modalSlider = this.modalWrapper.querySelector(
      ".modal-slider",
    ) as HTMLElement;
    this.iframe = this.modalSlider.querySelector(
      ".slider-iframe",
    ) as HTMLIFrameElement;
    this.sliderClose = this.modalSlider.querySelector(
      ".slider-close-btn",
    ) as HTMLElement;

    this.loadingIndicator = this.modalSlider.querySelector(
      ".loading",
    ) as HTMLElement;

    this.bindEvents();
    this.registerMessageSubscribers();
    this.initMessageListener();
  }

  private bindEvents(): void {
    this.modalOverlay.addEventListener("click", () => this.closeModal());
    this.sliderClose.addEventListener("click", () => this.closeModal());
    this.iframe.addEventListener("load", () => {
      this.injectIframeScript();
      this.hideLoadingIndicator(); // Hide loading indicator when iframe loads
    });

    window.addEventListener("IframeNavigationEvent", (event: any) => {
      const { url } = event.detail;
      Utils.msg.publish("IframeNavigationEvent", { url });
    });
  }

  private registerMessageSubscribers() {
    Utils.msg.subscribe(Message.loadSliderModal, (url) => this.openModal(url));
    window.addEventListener("message", (event) => {
      if (event.data.type === "toggleLoading") {
        event.data.show
          ? this.showLoadingIndicator()
          : this.hideLoadingIndicator();
      }
    });
    window.addEventListener(Message.sliderLoadingIndicator, (boolean) => {
      boolean ? this.showLoadingIndicator() : this.hideLoadingIndicator();
    });
    Utils.msg.subscribe("IframeNavigationEvent", (event) => {
      let { url } = event;
      url = this.modifyUrl(url);
      if (!this.isValidUrl(url)) {
        window.location.href = url; // Redirect parent if URL is invalid
        this.closeModal();
      } else {
        this.showLoadingIndicator();
        this.iframe.src = url; // Set iframe src
      }
    });
  }

  private modifyUrl(url: string): string {
    // If URL contains either "localhost" or "cbg-aem"
    return url.includes("localhost") || url.includes("cbg-aem")
      ? // Then check if URL ends with "/"
        url.endsWith("/")
        ? // If it ends with "/", remove the "/" and add ".html?wcmmode=disabled"
          url.slice(0, -1) + ".html?wcmmode=disabled"
        : // If it doesn't end with "/", just add ".html?wcmmode=disabled"
          url + ".html?wcmmode=disabled"
      : // If URL doesn't contain "localhost" or "cbg-aem", return it unchanged
        url;
  }

  public openModal(url: string): void {
    url = this.modifyUrl(url);

    if (this.isValidUrl(url)) {
      this.modalWrapper.classList.add(Classnames.modalActive);
      this.modalSlider.classList.add(Classnames.modalActive);
      this.stopBodyScroll();
      this.iframe.src = url; // Set iframe src
      this.showLoadingIndicator();
    } else {
      window.parent.location.href = url; // Redirect for invalid URLs
    }
  }

  private showLoadingIndicator(): void {
    this.loadingIndicator.style.display = "flex"; // Make sure the loading indicator is visible
  }

  private hideLoadingIndicator(): void {
    setTimeout(() => (this.loadingIndicator.style.display = "none"), 1000);
  }

  private injectIframeScript(): void {
    const iframeWindow = this.iframe.contentWindow;
    const innerDoc =
      this.iframe.contentDocument || this.iframe.contentWindow?.document;
    innerDoc?.body?.classList.add("slider-iframe-class");

    if (iframeWindow) {
      const script = iframeWindow.document.createElement("script");
      script.textContent = `
      function emitNavigationEvent(url) {
        const event = new CustomEvent("IframeNavigationEvent", { detail: { url } });
        window.parent.dispatchEvent(event); // Notify the parent about the navigation event
      }
          window.addEventListener('click', function(event) {
          const target = event.target;
          const parentTarget = target.parentElement;
          const buttonWrapper = target.closest('#cart-orderreview') || target.closest('#cart-browsecolors');
          const anchor = (target.tagName === 'A' ? target : parentTarget.tagName === 'A' ? parentTarget : null);

          if (anchor && anchor.href) {
            event.preventDefault();
            emitNavigationEvent(anchor.href);
          } else if (buttonWrapper) {
            const anchorInsideWrapper = buttonWrapper.querySelector('.button-link');
            if (anchorInsideWrapper?.href) {
              event.preventDefault();
              emitNavigationEvent(anchorInsideWrapper.href);
            }
          }
        });
        `;
      iframeWindow.document.head.appendChild(script);
    }
  }

  private validateUrl(url: string): boolean {
    return this.allowedUrls.some((allowedUrl) => url.includes(allowedUrl));
  }

  private handleUrlValidation(url: string): void {
    // Sanitize and validate URL before using
    try {
      const sanitizedUrl = new URL(url).toString();
      if (this.validateUrl(sanitizedUrl)) {
        // Only allow navigation within iframe for validated URLs
        this.iframe.src = sanitizedUrl;
      } else {
        // For invalid URLs, redirect to homepage instead of user input
        window.location.href = sanitizedUrl;
        this.closeModal();
      }
    } catch (e) {
      // Invalid URL format, redirect to homepage
      console.error("Invalid URL format:", e);
      window.location.href = "/";
      this.closeModal();
    }
  }

  // Listen for messages from the iframe
  private initMessageListener(): void {
    window.addEventListener("message", (event) => {
      if (event.data.action === "validateUrl") {
        const { url } = event.data;
        this.handleUrlValidation(url);
      }
    });
  }

  private stopBodyScroll(): void {
    document.querySelector("body").classList.add(Classnames.modalFullScreen);
  }

  private closeModal(): void {
    this.modalWrapper.classList.remove(Classnames.modalActive);
    this.modalSlider.classList.remove(Classnames.modalActive);
    document.querySelector("body").classList.remove(Classnames.modalFullScreen);
    this.deleteEncodedShipInfo();

    // Dispatch parentEvent when modal closes
    const event = new CustomEvent("parentEvent");
    window.parent.dispatchEvent(event);
  }

  private deleteEncodedShipInfo(): void {
    const encodedInfoElement = this.modalSlider.querySelector(
      "#encodedShipInfo",
    ) as HTMLElement | null;
    if (encodedInfoElement) {
      encodedInfoElement.remove();
    }
  }

  private isValidUrl(url: string): boolean {
    return this.allowedUrls.some((allowedUrl) => url.includes(allowedUrl));
  }
}
