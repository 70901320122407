import { SearchBar } from "./SearchBar";
import { ProductLookup } from "./ProductLookup";

document.querySelectorAll('[data-cbg-cmp="searchbar"]').forEach((el) => {
  new SearchBar(<HTMLElement>el);
});

document.querySelectorAll('[data-cbg-cmp="product-lookup"]').forEach((el) => {
  new ProductLookup(<HTMLElement>el);
});
