import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";

enum Classnames {
  hidden = "hidden",
  displayNone = "display-none",
  itemActive = "grid--wall__item-active",
  showMenu = "show__menu",
  visible = "visible",
  clearMenu = "clear-menu",
  clearMenuMobile = "clear-menu-mobile",
  applyFilterMobile = "close-filter-menu-mobile",
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-wall",
  select = "data-cmp-hook-retailer",
  filterhook = "data-cbg-cmp-hook-filter-option",
  categorieFilter = "data-cbg-cmp",
}

const retailerValues = {
  lowes: "Lowe's",
  "independent-retailer": "Independent Retailer",
};

class Wall {
  // Elements
  component: HTMLElement;
  menuButton: HTMLElement;
  closeButton: HTMLElement;
  applyFilterButton: HTMLElement;
  clearButton: HTMLElement;
  clearButtonMobile: HTMLElement;
  appliedFiltersContainer: HTMLElement;
  resultsCountContainer: HTMLElement;
  resultsTextContainer: HTMLElement;
  items: NodeListOf<HTMLElement>;
  noResultsMsgContainer: HTMLElement;
  noSearchResultsMsgContainer: HTMLElement;
  loadMoreButton: HTMLElement;
  sortDropDown: HTMLSelectElement;
  itemsHolder: HTMLElement;
  allFilterOptions: HTMLElement;
  completeCategoryFilters: NodeListOf<HTMLElement>;

  // Templates
  appliedFilterTemplate: HTMLTemplateElement;

  // Properties
  activeFilters: Record<string, Record<string, string>>;
  activeSearchFilters: Record<string, string>;
  filter: Record<string, Record<string, string[]>>;
  xfInsertionSlot: number;
  itemsPerPage: number;
  activePage: number;
  remainingItems: number;
  visibleItems: number;
  activelyFilteredItems: Array<HTMLElement>;
  cookieCategory: string;
  dataWallType: string;
  cookieValue: string;
  queryCount = 0;
  emptySearchResultsText: string;
  lastSearchQuery: string;
  globalRetailerSettingValue: string | null;
  retailerResultsPrefix: string;
  retailerLabel: string;
  selectContainer: HTMLSelectElement;
  storeFilterData: object;
  private readonly brand: string;
  newFilterVersion: string;

  static searchQueryFilterPrefix = "wall-search-query";
  filterCheckboxes: NodeListOf<Element>;
  filterRadioButton: NodeListOf<Element>;

  constructor(component: HTMLElement) {
    this.component = component;
    const requestURI = window.location.href;
    const fullURL = new URL(requestURI).pathname;
    const filterListKey = this.getFilterListKey(fullURL);
    //const allFilterParameters: string[] = [];
    if (requestURI.includes("valspar") || requestURI.includes("Valspar")) {
      this.brand = "valspar";
    }
    const allFilterParameters = new Map();
    const params = location.search.slice(1).split("&");

    params.forEach((item) => {
      const keyValue = item.split("=");
      const key = decodeURIComponent(keyValue[0]);
      const value = decodeURIComponent(keyValue[1]);

      if (allFilterParameters.has(key)) {
        allFilterParameters.get(key).push(value);
      } else {
        allFilterParameters.set(key, [value]);
      }
    });

    let arrayOfParameters = allFilterParameters.get("filterlist");
    if (arrayOfParameters) {
      arrayOfParameters = allFilterParameters.get("filterlist");
      for (const entry of arrayOfParameters) {
        const thisone = document.getElementById(entry) as HTMLInputElement;
        if (thisone) {
          thisone.checked = true;
        }
      }
    } else {
      let currentcookies = "";
      const filterListKeyCookie = Cookie.get(filterListKey);
      if (filterListKeyCookie != null && filterListKeyCookie.length > 0) {
        try {
          currentcookies = window.atob(Cookie.get(filterListKey));
        } catch {
          currentcookies = filterListKeyCookie;
        }
      }

      let storedFilters = "";
      if (currentcookies) {
        storedFilters = JSON.parse(currentcookies);
        arrayOfParameters = storedFilters;
      }
      if (arrayOfParameters) {
        // check for global retailer override
        if (this.globalRetailerSettingValue) {
          const { globalRetailerValue, globalRetailerId } =
            this.getGlobalRetailerSettings();
          const retailerParamsIndex = arrayOfParameters.findIndex(
            (e) => e.groupId === "retailer",
          );
          arrayOfParameters[retailerParamsIndex] = {
            ...arrayOfParameters[retailerParamsIndex],
            id: globalRetailerId,
            value: globalRetailerValue,
            title: globalRetailerValue,
          };
        }

        for (const entry of arrayOfParameters) {
          const thisone = document.getElementById(entry.id) as HTMLInputElement;
          if (thisone) {
            thisone.checked = true;
          }
        }
      }
    }

    this.initializeUI();

    this.initializeFilteringProperties();
    this.checkForGlobalRetailer();
    // Register event handlers and message subscribers
    this.registerEventHandlers();
    this.registerMessageSubscribers();
    this.tabIntoView();
    this.swatchOnHover();
  }

  private swatchOnHover() {
    window.addEventListener("load", function () {
      const colorSwatches = document.querySelectorAll(
        ".cbg-color-swatch.secondaryImage",
      );

      colorSwatches.forEach((colorSwatch) => {
        const element = colorSwatch as HTMLElement;
        let hoverImageUrl = colorSwatch.getAttribute("data-hoverImage");

        if (hoverImageUrl) {
          if (hoverImageUrl.indexOf(",") > -1) {
            if (colorSwatch.classList.contains("secondCarousel"))
              hoverImageUrl = hoverImageUrl.split(",")[1];
            else {
              hoverImageUrl = hoverImageUrl.split(",").pop();
            }
          }
        }

        element.style.backgroundImage = `url('${hoverImageUrl}')`;

        colorSwatch.addEventListener("mouseenter", function () {
          this.classList.add("hovered");
        });

        colorSwatch.addEventListener("mouseleave", function () {
          this.classList.remove("hovered");
        });
      });
    });
  }

  private checkForGlobalRetailer() {
    const globalRetailerValue = Cookie.get(this.cookieCategory);
    if (globalRetailerValue) {
      if (this.selectContainer) {
        Array.from(this.selectContainer.options).forEach(
          (
            option_element: HTMLOptionElement,
            /* index */
          ) => {
            if (option_element.selected == true) {
              this.retailerLabel = option_element.innerText;
            }
          },
        );
      }
      // const getID = JSON.parse(globalRetailerValue);
      // if (getID[0]) {
      if (globalRetailerValue.indexOf("/independent-retailer") > 0) {
        const irobject = JSON.parse(
          '{"checked": "true",' +
            '"groupId": "retailer",' +
            '"id": "cbg:independent-retailer",' +
            '"name": "filter-option-radio-retailer",' +
            '"subtype": "retailer",' +
            '"type": "radio",' +
            '"value": "Independent Retailer"}',
        );
        this.updateWithFilter(irobject);
      } else if (globalRetailerValue.indexOf("/lowes") > 0) {
        const lowesobject = JSON.parse(
          '{"checked": "true",' +
            '"groupId": "retailer",' +
            '"id": "cbg:lowes",' +
            '"name": "filter-option-radio-retailer",' +
            '"subtype": "retailer",' +
            '"type": "radio",' +
            '"value": "Lowes"}',
        );
        this.updateWithFilter(lowesobject);
      }
      // }
    }

    const storedValue = globalRetailerValue
      ? globalRetailerValue.match(/[^:]*$/)[0]
      : null;

    this.globalRetailerSettingValue = storedValue ? globalRetailerValue : null;
  }

  private getGlobalRetailerSettings() {
    let globalRetailerId;
    let globalRetailerValue;
    if (this.globalRetailerSettingValue) {
      globalRetailerId = `${this.globalRetailerSettingValue}`;
      globalRetailerValue = retailerValues[this.globalRetailerSettingValue];
    }
    return { globalRetailerId, globalRetailerValue };
  }

  private getFilterListKey(fullURL: string) {
    const pathToTrim = fullURL;
    const nextSlash = pathToTrim.lastIndexOf("/");
    const ending = fullURL.indexOf(".html");
    let generatedFilterKey = "";
    if (ending > -1) {
      generatedFilterKey = fullURL.substring(nextSlash, ending);
    } else {
      generatedFilterKey = fullURL.substring(nextSlash);
    }
    return generatedFilterKey;
  }

  /**
   * Initializes the UI properties.
   */
  private initializeUI() {
    this.xfInsertionSlot = this.component.dataset.xfInsertion
      ? parseFloat(this.component.dataset.xfInsertion)
      : -1;

    this.sortDropDown = this.component.querySelector(
      `[${Selector.hook}="select-menu"]`,
    );

    this.menuButton = this.component.querySelector(
      `[${Selector.hook}="show-filter-menu"]`,
    );

    this.closeButton = this.component.querySelector(
      `[${Selector.hook}="close-filter-menu"]`,
    );

    this.clearButton = this.component.querySelector(
      `[${Selector.hook}="clear-filter-menu"]`,
    );

    this.applyFilterButton = this.component.querySelector(
      `[${Selector.hook}="close-filter-menu-mobile"]`,
    );

    this.clearButtonMobile = this.component.querySelector(
      `[${Selector.hook}="clear-filter-menu-mobile"]`,
    );

    this.resultsCountContainer = this.component.querySelector(
      `[${Selector.hook}="results-count"]`,
    );

    this.resultsTextContainer = this.component.querySelector(
      `[${Selector.hook}="results-text"]`,
    );

    this.noResultsMsgContainer = this.component.querySelector(
      `[${Selector.hook}="no-results-msg--default"]`,
    );

    this.noSearchResultsMsgContainer = this.component.querySelector(
      `[${Selector.hook}="no-results-msg--search"]`,
    );
    this.emptySearchResultsText =
      this.noSearchResultsMsgContainer?.dataset?.emptyResultsText;

    this.loadMoreButton = this.component.querySelector(
      `[${Selector.hook}="load-more"]`,
    );
    this.allFilterOptions = this.component.querySelector(".filter-options");

    if (this.allFilterOptions) {
      this.filterCheckboxes = this.allFilterOptions.querySelectorAll(
        `[${Selector.filterhook}="checkbox"]`,
      );

      this.filterRadioButton = this.allFilterOptions.querySelectorAll(
        `[${Selector.filterhook}="radio"]`,
      );

      this.completeCategoryFilters = this.allFilterOptions.querySelectorAll(
        `[${Selector.categorieFilter}="filterOption"]`,
      );

      this.segregateValues();
    }

    this.cookieCategory = this.component.dataset.cookieCategory;
    this.cookieValue = this.component.dataset.cookieValue;
    this.dataWallType = this.component.dataset.wallType;
    this.retailerResultsPrefix = this.component.dataset.retailerResultsPrefix;
    this.selectContainer = document.querySelector(
      `[${Selector.select}="dropdown"]`,
    ) as HTMLSelectElement;
    this.newFilterVersion = this.component.dataset.newFilterVersion;
  }

  /**
   * Initializes properties used for filtering items in the Wall.
   */
  private initializeFilteringProperties() {
    this.activeSearchFilters = {};
    this.activeFilters = {};
    this.filter = {
      tag: {},
    };
    this.activePage = 1;
    this.itemsPerPage = this.component.dataset.itemsPerPage
      ? parseFloat(this.component.dataset.itemsPerPage)
      : 12;
    this.activelyFilteredItems = [];

    this.appliedFiltersContainer = this.component.querySelector(
      `[${Selector.hook}="applied-filters"]`,
    );

    this.appliedFilterTemplate = this.component.querySelector(
      `[${Selector.hook}="tmpl__applied-filter"]`,
    );

    this.items = this.component.querySelectorAll(
      `[${Selector.hook}="wall-item"]`,
    );

    this.filterItems(true);
  }

  /**
   * Standardizes tag ids used for filtering.
   * @param tagId
   */
  static normalizeTagId(tagId: string): string {
    return tagId.replace(/[:/]/g, "_").trim().toLowerCase();
  }

  /**
   * Registers event listeners for UI Elements
   */
  private registerEventHandlers() {
    if (this.sortDropDown) {
      this.sortDropDown.addEventListener(
        "change",
        this.sortColorOptions.bind(this),
      );
    }
    if (this.menuButton) {
      this.menuButton.addEventListener(
        "click",
        this.toggleFilterMenu.bind(this),
      );
    }

    if (this.closeButton) {
      this.closeButton.addEventListener(
        "click",
        this.toggleFilterMenu.bind(this),
      );
    }

    if (this.clearButton) {
      this.clearButton.addEventListener("click", this.clearFilters.bind(this));
    }

    if (this.clearButtonMobile) {
      this.clearButtonMobile.addEventListener(
        "click",
        this.clearFilters.bind(this),
      );
    }

    if (this.applyFilterButton) {
      this.applyFilterButton.addEventListener(
        "click",
        this.toggleFilterMenu.bind(this),
      );
    }

    if (this.appliedFiltersContainer) {
      this.appliedFiltersContainer.addEventListener(
        "click",
        this.handleFilterIconClick.bind(this),
      );
    }

    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener(
        "click",
        this.updateActivePage.bind(this, true),
      );
    }
  }

  /**
   * Registers subscribers for published messages.
   */
  private registerMessageSubscribers() {
    // Subscribe to the filterChanged publisher
    Utils.msg.subscribe(
      Message.filterChanged,
      this.updateWithFilter.bind(this),
    );

    // Subscribe to the wallSearchQuery publisher.
    Utils.msg.subscribe(
      Message.wallSearchQuery,
      this.updateWithQuery.bind(this),
    );

    Utils.msg.subscribe(Message.cookieUpdated, () => {
      this.checkForGlobalRetailer();
    });
  }

  /**
   * Toggles the mobile filter menu.
   */
  private toggleFilterMenu() {
    const filterMenu = document.querySelector(
      ".cbg-cmp-wall__container-filter",
    ) as HTMLElement;
    const menuButtonContainer = document.querySelector(
      ".mobile-bottom-menu-buttons",
    ) as HTMLElement;
    const bodyEl = document.querySelector("body");
    const htmlEl = document.querySelector("html");
    const filterOptions = document.querySelector(".filter-options");

    if (this.component.classList.toggle("show-menu")) {
      filterMenu.classList.add("mobile-filter-menu");
      menuButtonContainer.classList.add("mobile-filter-buttons-container");
      menuButtonContainer.remove();
      htmlEl.classList.add("html-hide-overflow");
      bodyEl.after(menuButtonContainer);
    } else {
      filterMenu.classList.remove("mobile-filter-menu");
      menuButtonContainer.classList.remove("mobile-filter-buttons-container");
      menuButtonContainer.remove();
      htmlEl.classList.remove("html-hide-overflow");
      filterOptions.after(menuButtonContainer);
    }
  }

  /**
   * Clears all filters.
   */
  private clearFilters() {
    // Dispatch to the filterClearAll message.
    Utils.msg.publish(Message.filterClearAll, {});

    // Dispatch to the searchClearInput message.
    Utils.msg.publish(Message.searchClearInput, { senderType: "wall" });

    // Toggle the clear menu class.
    this.component.classList.toggle(Classnames.clearMenu);

    // Clear all searches.
    this.clearSearches();
  }

  /**
   * Clears all searches.
   */
  private clearSearches() {
    for (const query in this.activeSearchFilters) {
      this.removeSearchFilter(query);
    }
  }

  /**
   * Updates the Wall's UI and state with given filter.
   * @param data
   */
  // Added subtype property in order to add more than one color when filtering
  private updateWithFilter(data) {
    const { checked, name, id, value, type, subtype, groupId, subColor } = data;

    const isRetailerOption =
      ((name || "").toLowerCase().indexOf("retailer") > -1 &&
        groupId === "retailer") ||
      subtype === "retailer";

    const tagIndex = type === "radio" ? name : id;
    const title = type === "radio" ? value : name;
    const tagId = Wall.normalizeTagId(tagIndex);

    const tagUIData = {
      id,
      title,
      subtype,
      groupId,
      name,
      value,
      subColor,
    };

    if (checked) {
      // Append filter to the active filters list
      this.activeFilters[tagId] = tagUIData;

      // Update the filter property with the type of filter and group.
      if (!("tag" in this.filter)) {
        this.filter.tag = {};
      }
      if (!(groupId in this.filter.tag)) {
        this.filter.tag[groupId] = [];
      }

      // Radios and toggles will only have a single item in an array.
      if (["radio", "toggle"].includes(type)) {
        this.filter.tag[groupId] = [id];
      } else if (this.filter.tag[groupId].indexOf(id) === -1) {
        this.filter.tag[groupId].push(id);
      }

      if (isRetailerOption) {
        // Force only one or less retailer options to be selectable
        Array.from(
          document.querySelectorAll<HTMLInputElement>(
            '[data-filter-option-subtype="retailer"]:checked',
          ),
        )
          .filter((e) => e.name !== data.name)
          .forEach((e) => e.click());

        // Update the results text.
        this.updateResultsWithRetailer(data);
      } else {
        // Add the filter icon to the UI
        this.addFilterIconToUI(tagUIData);
      }
    } else {
      if (isRetailerOption) {
        // Update the results text. This also needs to be done on uncheck
        this.updateResultsWithRetailer(data);
      }

      // Remove the filter from the active filters;
      delete this.activeFilters[tagId];

      if (this.filter.tag && groupId in this.filter.tag) {
        const tagIndex = this.filter.tag[groupId].indexOf(id);
        if (tagIndex > -1) {
          if (this.filter.tag[groupId].length === 1) {
            delete this.filter.tag[groupId];
          } else {
            this.filter.tag[groupId].splice(tagIndex, 1);
          }
        }
      }

      // Remove the filter icon from the UI
      if (!isRetailerOption) {
        this.removeFilterIconFromUI(tagId, groupId);
      }
    }

    // Update the filtered items
    this.filterItems(data);
  }

  private removeSearchFilter(query) {
    // Get the applied filter using the query term.
    const queryId = Wall.normalizeTagId(query);
    const filterId = this.activeSearchFilters[queryId];
    if (filterId) {
      // Remove the corresponding icon.
      this.removeFilterIconFromUI(filterId, "search", false);

      // Update the active search filters and active filters.
      delete this.activeSearchFilters[queryId];

      // Remove the filter from the active filters;
      delete this.activeFilters[filterId];

      // Nullify the last query.
      this.lastSearchQuery = null;

      // Update the filtered items
      this.filterItems();
    }

    return;
  }

  /**
   * Updates the Wall's UI and state from a search query.
   * @param data
   */
  private updateWithQuery(data) {
    const { query, removeFilter = false } = data;

    // Clear out previous searches first.
    this.clearSearches();

    if (removeFilter) {
      this.removeSearchFilter(query);
    }
    //In case of mobile close the slider when search begins
    if (
      window.innerWidth < 1100 &&
      this.component.classList.contains("show-menu")
    ) {
      this.toggleFilterMenu();
    }

    // Bounce if the query is already active.
    const queryId = Wall.normalizeTagId(query);
    if (queryId.length === 0 || queryId in this.activeSearchFilters) {
      return;
    }

    // Save the query.
    this.lastSearchQuery = query;

    // Increment the query count and create an id from it.
    this.queryCount += 1;
    const id = `${Wall.searchQueryFilterPrefix}-${this.queryCount}`;
    const title = query.trim();

    // Establish an object to keep track the id and title.
    const tagUIData = {
      id,
      groupId: "search",
      title,
    };

    // Append the query and id to the active search filters list.
    this.activeSearchFilters[queryId] = id;

    // Append filter to the active filters list
    this.activeFilters[id] = tagUIData;

    // Add the filter icon to the UI
    this.addFilterIconToUI(tagUIData);

    // Update the filtered items
    this.filterItems();
  }

  /**
   * Updates the Wall UI with a given filter.
   * @param filter
   */
  private addFilterIconToUI(filter) {
    const { id, title, groupId } = filter;
    const tagId = Wall.normalizeTagId(id);
    const tmpl =
      this.appliedFilterTemplate && this.appliedFilterTemplate.content
        ? <HTMLElement>this.appliedFilterTemplate.content.cloneNode(true)
        : null;
    const filterNameLocation = tmpl.querySelector(
      `[${Selector.hook}="filter-name"]`,
    );

    const colorLocation: HTMLElement = tmpl.querySelector(
      `[${Selector.hook}="tmpl--filter-color"]`,
    );
    if (colorLocation) {
      if (filter?.subColor) {
        colorLocation.style.backgroundColor = filter.subColor;
      } else {
        colorLocation.style.display = "none";
      }
    }

    filterNameLocation.innerHTML = title;
    const filterId = groupId
      ? `#applied-filter__${groupId}-${tagId}`
      : `#applied-filter__${tagId}`;
    const filterIcon = this.appliedFiltersContainer.querySelector(filterId);

    try {
      tmpl.firstElementChild.setAttribute(
        "id",
        `applied-filter__${groupId}-${tagId}`,
      );
      tmpl.firstElementChild.setAttribute(
        "data-applied-filter",
        JSON.stringify(filter),
      );
      if (!filterIcon) {
        this.appliedFiltersContainer.append(tmpl);
      }
    } catch (e) {
      console.error("Error: Adding filter element to UI", e);
    }
  }

  /**
   * Removes a filter icon with a given tag id from the Wall UI.
   * @param id
   */
  private removeFilterIconFromUI(id, groupId, clearInput = true) {
    try {
      const tagId = Wall.normalizeTagId(id);
      const filterId = groupId
        ? `#applied-filter__${groupId}-${tagId}`
        : `#applied-filter__${tagId}`;
      const filterIcon = this.appliedFiltersContainer.querySelector(filterId);
      filterIcon?.classList.add(Classnames.hidden);
      filterIcon?.remove();
      //Removing Fitler Before any transition
      // filterIcon?.addEventListener("transitionrun", () => {
      //   filterIcon.remove();
      // });

      // For searches within the wall...
      const isWallSearchFilter =
        tagId.indexOf(Wall.searchQueryFilterPrefix) > -1;
      if (isWallSearchFilter) {
        // Decrement the wall search counter.
        this.queryCount -= 1;

        if (clearInput) {
          // Publish to the clear search input filter.
          Utils.msg.publish(Message.searchClearInput, {
            sender: this.component,
            senderType: "wall",
          });
        }
      }
    } catch (e) {
      console.error("Error: Removing filter element from UI", e);
    }
  }

  /**
   * Filters the items within the wall based on the activeFilters.
   */
  private filterItems(isInitialFilter = false) {
    // Get all the active filters.
    const activeFilterCount = Object.keys(this.activeFilters).length;
    const requestURI = window.location.href;
    const fullURL = new URL(requestURI).pathname;
    const filterListKey = this.getFilterListKey(fullURL);

    const activeValues = Object.values(this.activeFilters);
    Cookie.set(filterListKey, window.btoa(JSON.stringify(activeValues)), 30);
    // Cookie.set(filterListKey, JSON.stringify(activeValues), 30);

    // Determine the number of the last item on the page.
    const lastItemOnPage = this.activePage * this.itemsPerPage;

    // Reset the active page
    this.updateActivePage();

    if (this.newFilterVersion == "true") {
      Array.prototype.find.call(this.filterCheckboxes, (item) => {
        item.closest("label").classList.add("filter-disable");
      });
      Array.prototype.find.call(this.filterRadioButton, (item) => {
        item.closest("label").classList.add("filter-disable");
      });
    }

    if (this.items) {
      // Activate all items when there are no active filters set.
      if (activeFilterCount === 0) {
        // Reset the number of visible items to 0.
        this.visibleItems = 0;
        // Reset the actively filtered items to an empty list.
        this.activelyFilteredItems = [];

        // Loop through each item
        this.items.forEach((item) => {
          // Add the active class name
          item.classList.add(Classnames.itemActive);

          // Add the item to the list of actively filtered items
          this.activelyFilteredItems.push(item);

          // If this can be visible...
          if (this.visibleItems < lastItemOnPage) {
            // Then add the visible class.
            item.classList.add(Classnames.visible);

            // And increment the number of visible items.
            this.visibleItems++;
          }
        });
        this.remainingItems = this.items.length - this.visibleItems;

        // Now update the UI with the results count.
        this.updateResultsCount(this.items.length);
        this.updateLoadMoreButton();
        if (this.newFilterVersion == "true") {
          this.disableFilters(this.activelyFilteredItems);
        }

        return;
      }

      // Reset the number of active items
      let totalActiveItems = 0;

      // Reset the number of visible items
      this.visibleItems = 0;

      // Reset the list of actively filtered items.
      this.activelyFilteredItems = [];

      // Prepare the search term for later checking.
      const query = (this.lastSearchQuery || "").toLowerCase().trim();

      // Loop through every wall item.
      this.items.forEach((item) => {
        // Reset the classnames for all items.
        item.classList.remove(Classnames.itemActive, Classnames.visible);

        // Keep track of if this item should be active or not.
        let shouldActivateItem = false;

        // Extract the title.
        const itemTitle = (item.dataset?.title || "").toLowerCase();

        // Extract each of the tags associated with the item and normalize them.
        const tags = (item.dataset?.tags || "")
          .split(",")
          .map((tag) => tag.trim());

        // Extract the color id.
        const colorId = (item.dataset?.colorId || "").toLowerCase();

        // Extract the previous color info.
        const previousColorInfo = (
          item.dataset?.colorPreviousInfo || ""
        ).toLowerCase();
        // Loop through each of the filters.
        for (const filter in this.filter) {
          // Get the categories of matches.
          const categories = this.filter[filter]; // ie. {"retailer": ["cbg:lowes"], "color-family": ["cbg:reds", "cbg:blues"]}

          // Maintain a count from all categories. We'll need at least one from each.
          let matchingCategories = 0;

          // Keep a count of the total categories.
          const totalCategories = Object.keys(categories).length;

          // Loop through all categories.
          for (const category in categories) {
            // Get the list of selected filters in the category.
            const selectedFiltersInCategory = categories[category]; // ie. ["cbg:reds", "cbg:oranges"]

            // Set the default value for hasAMatch to false.
            let hasAMatch = false;

            // Loop through the item's tag list.
            tags.forEach((tag) => {
              // Loop through the selected filters.
              selectedFiltersInCategory.forEach((selectedFilter) => {
                // Set hasAMatch to true if an item's tag contains the selected filter.
                if (tag === selectedFilter) {
                  hasAMatch = true;
                }
              }); // END: Loop through the selected filters.
            }); // END: Loop through the tag list.

            // When there is a match, increment the matchingCategories.
            if (hasAMatch) {
              matchingCategories++;
            }
          } // END: Loop through all categories.

          // The tag matches if the total number of categories is equal to the number of matching categories.
          const isATagMatch = totalCategories === matchingCategories;
          shouldActivateItem = isATagMatch;

          // If there is a search query, then also check for tag, title, and color id matches against the query.
          if (query.length > 0) {
            const queryMatchesColorId = colorId.indexOf(query) > -1;
            const queryMatchesTag = item.dataset?.tags.indexOf(query) > -1;
            const queryMatchesTitle = itemTitle.indexOf(query) > -1;
            const queryMatchesPreviousInfo =
              previousColorInfo.indexOf(query) > -1;

            const hasASearchMatch =
              queryMatchesTag ||
              queryMatchesTitle ||
              queryMatchesColorId ||
              queryMatchesPreviousInfo;
            shouldActivateItem = hasASearchMatch && isATagMatch;
          }
          //Adding class for buy online button if retailer is independent-retailer
          //Adding ? since this is only for valspar
          if (
            categories?.retailer &&
            categories?.retailer[0] == "cbg:independent-retailer"
          ) {
            item.classList.add("retailer-link");
          }
          if (categories?.retailer && categories?.retailer[0] == "cbg:lowes") {
            item.classList.remove("retailer-link");
          }
        } // END: Loop through each of the filters.

        const shouldEnableVisibility = this.visibleItems < lastItemOnPage;

        // Check the list of tags to see if the filter's tag id is in the list
        if (shouldActivateItem) {
          // Add the active class
          item.classList.add(Classnames.itemActive);
          // Increment the number of active items.
          totalActiveItems++;

          // Add the item to the list of actively filtered items.
          this.activelyFilteredItems.push(item);

          // If the number of visible items is less than the last item on the page
          if (shouldEnableVisibility) {
            // Add the visible class name
            item.classList.add(Classnames.visible);

            // Increment the number of visible items.
            this.visibleItems++;
          }
        }
      }); // END: Loop through every wall item.

      this.remainingItems = totalActiveItems - this.visibleItems;
      this.visibleItems = totalActiveItems - this.remainingItems;

      this.updateResultsCount(totalActiveItems);
      this.updateLoadMoreButton();
      if (this.newFilterVersion == "true") {
        this.disableFilters(this.activelyFilteredItems);
      }
    }

    const wallBanner: HTMLElement = document.querySelector("div#wall-banner");
    if (wallBanner) {
      const lowesWallPreviousSibling =
        wallBanner.previousElementSibling.className ===
        "grid--wall__item grid--wall__item-product grid--wall__item-active visible";
      const retailerWallPreviousSibling =
        wallBanner.previousElementSibling.className ===
        "grid--wall__item grid--wall__item-product retailer-link grid--wall__item-active visible";

      const displayBanner = (previousElement) => {
        if (previousElement) {
          wallBanner.style.display = "block";
        } else {
          wallBanner.style.display = "none";
        }
      };

      if (
        wallBanner.previousElementSibling.className.includes(
          "retailer-link grid--wall__item-active visible",
        )
      ) {
        displayBanner(
          retailerWallPreviousSibling === retailerWallPreviousSibling,
        );
      } else {
        displayBanner(lowesWallPreviousSibling);
      }
    }

    // Scroll to top when the wall is re-filtered, unless this the very first filter.
    // if (isInitialFilter && activeValues.length <= 1) {
    //   window.scrollTo({
    //     top: 0,
    //     left: 100,
    //     behavior: "smooth",
    //   });
    // } else if (isInitialFilter && activeValues.length > 1) {
    //   const wallTop = this.component.offsetTop;
    //   window.scrollTo({ top: wallTop });
    // }

    if (this.brand === "valspar" && activeValues.length > 0) {
      const obj = activeValues.filter((object) => {
        return object.groupId === "retailer";
      });
      obj?.forEach((filterN) =>
        activeValues.splice(
          activeValues.findIndex((e) => e?.name === filterN?.name),
          1,
        ),
      );
      if (isInitialFilter && activeValues.length == 0) {
        //Remove any scroll history and make it easy to scroll to top
        if ("scrollRestoration" in history) {
          history.scrollRestoration = "manual";
        }
        window.scrollTo(0, 0);
      }
    }

    if (isInitialFilter && activeValues.length > 0) {
      this.component.scrollIntoView();
    }
  }

  /**
   * Update the results count in the UI and display a
   *  message if there are no results based on active filters.
   * @param count
   */
  private updateResultsCount(count) {
    if (this.resultsCountContainer)
      this.resultsCountContainer.innerHTML = count;

    if (count === 0) {
      if (this.lastSearchQuery) {
        this.noSearchResultsMsgContainer.innerText =
          this.emptySearchResultsText?.replace(
            "%term%",
            `"${this.lastSearchQuery}"`,
          );
        this.noSearchResultsMsgContainer?.classList?.remove(
          Classnames.displayNone,
        );
      } else {
        this.noResultsMsgContainer?.classList?.remove(Classnames.displayNone);
      }
    } else {
      this.noResultsMsgContainer?.classList?.add(Classnames.displayNone);
      this.noSearchResultsMsgContainer?.classList?.add(Classnames.displayNone);
    }
  }

  /*
   Segerate values and give the results to each filter
   */
  private segregateValues() {
    this.storeFilterData = {};
    this.completeCategoryFilters.forEach((element: HTMLElement) => {
      const category = element.querySelector(".filter-option--heading").id;
      //this.storeFilterData[category] = {};
      const categoyObject = {};
      const categoryChecks = element.querySelectorAll(
        `[${Selector.filterhook}="checkbox"]`,
      );
      categoryChecks.forEach((item: HTMLInputElement) => {
        const value = item.value;
        categoyObject[value] = [];
      });
      this.storeFilterData[category] = categoyObject;
    });
  }

  /**
   * Manages clicks on filter icons.
   * @param evt
   */
  private handleFilterIconClick(evt) {
    const target = evt.target;
    const filter =
      target.dataset && target.dataset.appliedFilter
        ? target.dataset.appliedFilter
        : false;

    if (filter) {
      const filterData = JSON.parse(filter);
      const isSearchFilter =
        filterData?.id.indexOf(Wall.searchQueryFilterPrefix) > -1;
      if (typeof filterData.checked !== "undefined") {
        filterData.checked = false;
      }

      if (isSearchFilter) {
        this.removeSearchFilter(filterData.title);
      } else {
        this.updateWithFilter(filterData);
      }

      // Publish a message to the filterUnchecked event
      Utils.msg.publish(Message.filterUnchecked, filterData);
    }
  }

  /**
   * Updates the active page of the wall.
   */
  private updateActivePage(loadMore = false) {
    if (!loadMore) {
      this.activePage = 1;
    } else {
      if (this.remainingItems > 0) {
        this.activePage++;
        this.loadMoreItems();
      }
    }
  }

  /**
   * Displays more content on
   */
  private loadMoreItems() {
    const lastItemOnPage = this.activePage * this.itemsPerPage;
    let nextItemtoFocus: HTMLElement;

    // Loop through the list of the items that do not have the visible class.
    this.activelyFilteredItems
      .filter((item) => !item.classList.contains(Classnames.visible))
      .forEach((item) => {
        if (!nextItemtoFocus) nextItemtoFocus = item.querySelector("a");

        // Add a visible class to them if the number of visible items is less than the last item on the page
        if (this.visibleItems < lastItemOnPage) {
          item.classList.add(Classnames.visible);
          this.visibleItems++;
        }
      });

    const diff = this.remainingItems - this.itemsPerPage;
    this.remainingItems = diff < 0 ? 0 : diff;
    this.updateLoadMoreButton();

    nextItemtoFocus.focus();
  }

  /**
   * Disable the filters based on the sorted result
   */
  private disableFilters(sortedResult, allResults = false) {
    if (allResults) {
      //NO Filters Case
      Array.prototype.find.call(this.filterCheckboxes, (item) => {
        item.closest("label").classList.remove("filter-disable");
      });
      Array.prototype.find.call(this.filterRadioButton, (item) => {
        item.closest("label").classList.remove("filter-disable");
      });

      return;
    }
    //When filters are available
    sortedResult.forEach((element) => {
      const tags = (element.dataset?.tags || "")
        .split(",")
        .map((tag) => tag.trim());
      tags.map((tag) => {
        Array.prototype.find.call(this.filterCheckboxes, (item) => {
          if (item?.value == tag) {
            const element = document
              .getElementById(tag)
              ?.closest("label")?.classList;
            element.remove("filter-disable");
          }
        });
        Array.prototype.find.call(this.filterRadioButton, (item) => {
          if (item.value == tag) {
            const element = document
              .getElementById(tag)
              ?.closest("label")?.classList;
            element.remove("filter-disable");
          }
        });
      });
    });
  }

  /**
   * Updates the results messaging with the retailer name.
   */
  private updateResultsWithRetailer({ subtype }: { subtype: string }) {
    if (subtype === "retailer") {
      // this will be the first checked retailer but since we are forcing only one to be checked it is ok
      const retailersChecked: string | undefined =
        document.querySelector<HTMLInputElement>(
          '[data-filter-option-subtype="retailer"]:checked',
        )?.name;
      let msg: string;
      if (retailersChecked || this.retailerLabel) {
        msg = `${this.retailerResultsPrefix} ${retailersChecked ?? this.retailerLabel}`;
      } else {
        msg = "";
      }
      if (this.resultsTextContainer) this.resultsTextContainer.innerText = msg;
    }
  }

  /**
   * Updates the visibility of the load more button.
   */
  private updateLoadMoreButton() {
    this.loadMoreButton?.classList.toggle(
      Classnames.visible,
      this.remainingItems > 0,
    );
  }

  /**
   * Sorts the color values based on LRV and displayOrder Values .
   */
  private sortColorOptions() {
    let unsortedItems;
    if (this.dataWallType == "product") {
      unsortedItems = this.component.querySelectorAll(
        ".grid--wall__item-product",
      );
    }
    if (this.dataWallType == "color") {
      unsortedItems = this.component.querySelectorAll(
        ".grid--wall__item-color",
      );
    }
    const itemsArray = Array.from(this.items);
    const dropDownValue = this.sortDropDown.value;
    const L2D = "lightToDark";
    const D2L = "darkToLight";
    const FC = "featuredColors";
    const AZ = "AtoZ";
    const ZA = "ZtoA";

    if (dropDownValue === L2D) {
      const isGood = itemsArray.sort((a: any, b: any): any => {
        return a.dataset.lrv - b.dataset.lrv;
      });
      for (let i = 0; i < unsortedItems.length; i++) {
        const node = unsortedItems[i].parentNode;
        node.prepend(isGood[i]);
      }
    }
    if (dropDownValue === D2L) {
      const isGood = itemsArray.sort((a: any, b: any): any => {
        return b.dataset.lrv - a.dataset.lrv;
      });
      for (let i = 0; i < unsortedItems.length; i++) {
        const node = unsortedItems[i].parentNode;
        node.prepend(isGood[i]);
      }
    }

    if (dropDownValue === FC) {
      const isGood = itemsArray.sort((a: any, b: any): any => {
        return b.dataset.sort - a.dataset.sort;
      });
      for (let i = 0; i < unsortedItems.length; i++) {
        const node = unsortedItems[i].parentNode;
        node.prepend(isGood[i]);
      }
    }

    if (dropDownValue === AZ) {
      if (this.dataWallType == "color") {
        const isGood = itemsArray.sort((a: any, b: any): any => {
          return b.dataset.colorName.localeCompare(a.dataset.colorName);
        });
        for (let i = 0; i < unsortedItems.length; i++) {
          const node = unsortedItems[i].parentNode;
          node.prepend(isGood[i]);
        }
      }
      if (this.dataWallType == "product") {
        const isGood = itemsArray.sort((a: any, b: any): any => {
          return b.dataset.title.localeCompare(a.dataset.title);
        });
        for (let i = 0; i < unsortedItems.length; i++) {
          const node = unsortedItems[i].parentNode;
          node.prepend(isGood[i]);
        }
      }
    }

    if (dropDownValue === ZA) {
      if (this.dataWallType == "color") {
        const isGood = itemsArray.sort((a: any, b: any): any => {
          return a.dataset.colorName.localeCompare(b.dataset.colorName);
        });
        for (let i = 0; i < unsortedItems.length; i++) {
          const node = unsortedItems[i].parentNode;
          node.prepend(isGood[i]);
        }
      }
      if (this.dataWallType == "product") {
        const isGood = itemsArray.sort((a: any, b: any): any => {
          return a.dataset.title.localeCompare(b.dataset.title);
        });
        for (let i = 0; i < unsortedItems.length; i++) {
          const node = unsortedItems[i].parentNode;
          node.prepend(isGood[i]);
        }
      }
    }

    // Reset the classnames for all items.
    this.items.forEach((item) => {
      item.classList.remove(Classnames.itemActive, Classnames.visible);
    });

    // Reset the number of visible items
    this.visibleItems = 0;
    // Reset this.items to the sorted list.
    this.items = this.component.querySelectorAll(
      `[${Selector.hook}="wall-item"]`,
    );
    // Reset the list of actively filtered items.
    this.activelyFilteredItems = [];

    this.filterItems();
  }

  private tabIntoView() {
    const parent = this.component.querySelector(".filter-options");
    parent.addEventListener(
      "focusin",
      () => {
        const target = document.activeElement;
        const viewBottom = window.scrollY + window.innerHeight;
        const targetBottom =
          window.scrollY + target.getBoundingClientRect().bottom;
        const filterBottom =
          window.scrollY + parent.getBoundingClientRect().bottom;

        if (targetBottom > viewBottom) {
          target.scrollIntoView(true);
        } else if (targetBottom > filterBottom) {
          target.scrollIntoView(true);
        }
      },
      true,
    );
  }
}

export { Wall };
