enum Selector {
  hook = "data-cbg-cmp-hook-recaptcha",
}

class Recaptcha {
  container: HTMLElement;
  siteKey: string;
  parent: HTMLElement;
  onSubmit: string;

  constructor(container: HTMLElement) {
    // Elements
    this.container = container;

    if (!this.container) {
      return null;
    }
    this.parent = this.container.parentElement.parentElement;
    this.siteKey = this.container.dataset.sitekey;
    this.onSubmit = this.container.dataset.callback;
    this.registerEventHandlers();
  }

  private registerEventHandlers() {
    this.onLoad();
  }

  private onLoad = (): void => {
    const captchaSiteKey = this.siteKey;
    const inputValue = this.parent.querySelector(
      "#g-recaptcha-response"
    ) as HTMLInputElement;
    if (this.parent) {
      if (inputValue) {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(`${captchaSiteKey}`, { action: "homepage" })
            .then(function (token) {
              if (token) {
                if (process.env.NODE_ENV !== 'production') {
                  console.log(token);
                };
              }
            });
        });
      }
    }
  };
}

export { Recaptcha };
