/**
 * The ready function is a replica of $(document).ready() in jQuery.
 *
 * @param fn: Function
 * Usage: ready( () => {} ); OR ready(functionName);
 */
function ready(fn: () => void): void {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      fn();
    });
  }
}

function elementReady(selector: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    const el = document.querySelector(selector);

    if (el) {
      resolve(el);
    }

    new MutationObserver(function (mutationRecords, observer) {
      // Query for elements matching the specified selector
      Array.from(document.querySelectorAll(selector)).forEach(
        function (element) {
          resolve(element);

          //Once we have resolved we don't need the observer anymore.
          observer.disconnect();
        },
      );
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
}

/**
 * Retrieves the data-cart-version attribute as a boolean from the cart element identified by data-cbg-cmp="cart".
 *
 * @returns {boolean} True if the data-cart-version attribute is "true", otherwise false.
 */
function getCartVersionBoolean(): boolean {
  return true;
}

/**
 * Returns the name of the CBG brand associated with the website being viewed
 *
 * @returns {string} CBG brand name
 */
function getCbgBrand(): string {
  const brandContainerSuffix = "-container";
  const body = document.body as HTMLBodyElement;
  const cssClasses: Array<string> = body.className.split(/\s+/g);
  const brandContainerClass = cssClasses.find((cssClass) =>
    cssClass.endsWith(brandContainerSuffix),
  );

  if (!brandContainerClass) return null;

  return brandContainerClass.replace(brandContainerSuffix, "");
}

/**
 * Returns the name of the CBG SUB brand associated with the website being viewed
 *
 * @returns {string} CBG SUB brand name
 */
function getCbgBrandSecondary(): string {
  const brandContainerSuffix = "krylon-";
  const body = document.body as HTMLBodyElement;
  const cssClasses: Array<string> = body.className.split(/\s+/g).reverse();
  const brandContainerClass = cssClasses.find((cssClass) =>
    cssClass.startsWith(brandContainerSuffix),
  );

  if (!brandContainerClass) return "";

  return brandContainerClass.replace(brandContainerSuffix, "");
}

/**
 * Returns a boolean indicating if an extension is required for AEM page paths
 *
 * @returns {boolean}
 */
function isExtensionRequired(): boolean {
  const lowerEnvSegments = ["local.", "localhost.", "ebus.swas"];

  return lowerEnvSegments.some((segment) => location.host.includes(segment));
}

/**
 * Removes styles added to prevent page scrolling when dialogs are open
 *
 */

function disableScroll(): void {
  const scrollY = window.scrollY;
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollY}px`;
}

function restoreNormalScroll(): void {
  const scrollY = window.scrollY;
  document.body.style.position = "";
  window.scrollTo(0, scrollY);
}

function escapeHtml(text: string): string {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

class Session {
  public static get(name: string): string {
    return window.sessionStorage.getItem(name);
  }

  public static set(name: string, value: string): void {
    window.sessionStorage.setItem(name, value);
  }

  public static delete(name: string): void {
    window.sessionStorage.removeItem(name);
  }
}

class Cookie {
  public static get(name: string): string {
    let cDecoded;
    let res;
    const cookieName = name + "=";
    try {
      cDecoded = decodeURIComponent(document.cookie); //to be careful
    } catch (e) {
      //
    } finally {
      if (!cDecoded) {
        cDecoded = document.cookie;
      }
      const cArr = cDecoded.split("; ");
      cArr.forEach((val) => {
        if (val.indexOf(cookieName) === 0)
          res = val.substring(cookieName.length);
      });
    }
    return res;
  }

  public static getWithRegex(searchValue: string): string | undefined {
    const searchPattern = `/${searchValue}(.*)$`;
    const regexPattern = new RegExp(searchPattern);
    const decodedCookies = decodeURIComponent(document.cookie);
    const arrayOfCookies = decodedCookies.split("; ");
    let res;
    const foundCookie = arrayOfCookies.find((val) => regexPattern.test(val));
    if (foundCookie) {
      // Return only the cookie name.
      const bits = foundCookie.split("=");
      res = bits[0];
    }
    return res;
  }

  public static set(name: string, value: string, expDays = 0): void {
    if (!name) {
      return console.warn(
        `Unable to set cookie with name "${name}". Provided cookie names must evaluate to true.`,
      );
    }

    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + "; path=/";
  }

  public static setWithDomain(
    name: string,
    value: string,
    expDays = 0,
    domain: string = null,
  ): void {
    if (!name) {
      return console.warn(
        `Unable to set cookie with name "${name}". Provided cookie names must evaluate to true.`,
      );
    }

    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);

    const cookieValues = [
      `${name}=${value}`,
      `expires=${date.toUTCString()}`,
      "path=/",
      `domain=${domain}`,
    ];

    document.cookie = cookieValues.join(";");
  }

  public static delete(name: string): void {
    document.cookie = name + "=; Max-Age=-99999999;";
  }
}

class Messenger {
  subscribers: Record<string, any>;

  constructor() {
    this.subscribers = {};
  }

  subscribe(event, callback) {
    let index;
    if (!this.subscribers || !this.subscribers[event]) {
      this.subscribers[event] = [];
    }
    index = this.subscribers[event].push(callback);
    index--;

    return {
      unsubscribe: () => {
        // Using an arrow function here
        this.subscribers[event].splice(index, 1);
      },
    };
  }

  publish(event, data) {
    if (!this.subscribers[event]) return;
    this.subscribers[event].forEach((subscriberCallback) =>
      subscriberCallback(data),
    );
  }
}

class LocalStorageHandler {
  key: string;
  value: string;

  // Initialize the class with a key and optionally set an initial value
  public init(key: string, initialValue?: string): void {
    this.key = key;
    if (initialValue) {
      this.set(initialValue);
    }
  }

  // Save value to localStorage
  public set(value: string): void {
    this.value = value;
    localStorage.setItem(this.key, value);
  }

  // Retrieve value from localStorage
  public get(value): string | null {
    const item = localStorage.getItem(value);
    return item;
  }

  // Remove value from localStorage
  public remove(): void {
    localStorage.removeItem(this.key);
  }
}

const Utils = {
  elementReady,
  ready,
  getCbgBrand,
  getCbgBrandSecondary,
  getCartVersionBoolean,
  isExtensionRequired,
  restoreNormalScroll,
  escapeHtml,
  msg: new Messenger(),
  storeValue: new LocalStorageHandler(),
};

export { Utils, Session, Cookie };
