import { FavoritesAPI, FavoriteColorItem } from "./FavoritesController";
import { Cookie, Utils } from "../../site/scripts/utils";
import { Message } from "../../site/scripts/Message";
import { State } from "../../enum/state";
import { AskVal, CartItem } from "../cart/AskValV3";

enum Classnames {
  displayNone = "display-none",
  iconAdd = "icon-add",
  iconRemove = "icon-remove",
  loading = "loading",
  stateAdd = "cart-cta--add-state",
  stateRemove = "cart-cta--add-remove",
}

enum Selector {
  element = "data-component-id",
  hook = "data-cbg-cmp-hook-cart-cta",
}

class FavoriteCTA {
  static instances: Map<string, FavoriteCTA[]> = new Map();
  container: HTMLElement;
  chipTextRemove: HTMLElement;
  chipTextAdd: HTMLElement;
  persistenceKey = "favorites";
  brand: string;
  channelId: string;
  collection: string;
  collectionData: string;
  collectionName: string;
  colorId: string;
  colorName: string;
  colorNumber: string;
  colorFamily: string;
  colorsInCollectionText: string;
  country: string;
  hex: string;
  href: string;
  language: string;
  tags: string;
  cartResponseCookie: {
    cart: CartItem[];
    cartItems: CartItem[];
    favorites: CartItem[];
  };
  colorItem: FavoriteColorItem;
  id: number;
  state = State.INACTIVE;
  isDisabled: boolean;
  mdmId: string;
  swatchUrl: string;
  currentBrand: string;
  favoritesMdmVersion: boolean;
  private boundHandleClick: (event: MouseEvent) => void;

  constructor(component: HTMLElement, favorite?: FavoriteColorItem) {
    this.container = component;
    if (!this.container) return;

    const data = this.container.dataset;
    this.currentBrand = Utils.getCbgBrand();
    this.brand = data.brand;
    this.channelId = data.favChannelId;
    this.collection = data.collection;
    this.collectionData = data.collectionData;
    this.collectionName = data.collectionName;
    this.colorId = data.favColorId;
    this.colorName = data.favColorName;
    this.colorNumber = data.colorNumber || data.favColorId;
    this.colorFamily = data.colorFamily;
    this.colorsInCollectionText = data.colorsInCollectionText;
    this.country = data.country;
    this.hex = data.favHex;
    this.href = data.href;
    this.language = data.language;
    this.tags = data.favTags;
    this.mdmId = data.mdmid;
    this.swatchUrl = data.swatchUrl;
    if (this.collection) {
      this.colorId = this.collection;
      this.colorName = this.collection;
      this.colorNumber = this.collection;
    }

    const element = document.querySelector(
      '[data-cbg-cmp="favorites"]',
    ) as HTMLElement;
    this.favoritesMdmVersion = element?.dataset?.favoritesMdmVersion === "true";

    this.colorItem = {
      cartId: null,
      channelId:
        this.channelId || AskVal.getChannelId(this.brand, this.country),
      collection: this.collection,
      collectionData: this.collectionData,
      collectionName: this.collectionName,
      colorId: this.colorId,
      colorName: this.colorName,
      colorNumber: this.colorNumber,
      colorFamily: this.colorFamily,
      colorsInCollectionText: this.colorsInCollectionText,
      hex: this.hex,
      href: this.href,
      id: this.id,
      localeId: AskVal.getLocaleId(this.language, this.country),
      tags: this.tags,
      timestamp: new Date().getTime(),
      mdmId: this.mdmId,
      swatchUrl: this.swatchUrl,
    };

    const url = window.location.href;
    const isFavoritesOrCart = url.includes("favorites") || url.includes("cart");

    if (favorite || isFavoritesOrCart) {
      this.updateComponentState(State.ADD);
    }

    this.boundHandleClick = this.handleClickBehaviors.bind(this);
    this.container.addEventListener("click", this.boundHandleClick);
    this.registerMessageSubscribers();
    this.initializeUI();

    // Register this instance
    const id = this.favoritesMdmVersion ? this.mdmId : this.colorId;
    if (!FavoriteCTA.instances.has(id)) {
      FavoriteCTA.instances.set(id, []);
    }
    FavoriteCTA.instances.get(id).push(this);
  }

  private updateUI(): void {
    const stateActive = this.state === State.ACTIVE;
    this.container.classList.toggle(State.ACTIVE, stateActive);
    this.chipTextAdd?.classList.toggle(Classnames.displayNone, stateActive);
    this.chipTextRemove?.classList.toggle(Classnames.displayNone, !stateActive);
  }

  public updateComponentState(action): void {
    this.container.classList.remove(State.LOADING);
    this.state = action === State.ADD ? State.ACTIVE : State.INACTIVE;
    this.updateUI();
  }

  private addFavorite(): void {
    if (this.isDisabled) return;
    
    if (this.currentBrand === "minwax" && this.maxFavoritesReached()) {
      Utils.msg.publish(Message.addFavoritesErrorResponse, {
        colorName: "You've reached the maximum number of favorites allowed",
      });
      return;
    }
    //Update UI to show the add state and add loading state.
    this.updateUI();
    this.container.classList.add(State.LOADING);
    FavoritesAPI.add(this.colorItem);
    this.container.classList.remove(State.LOADING);
    this.state = State.ACTIVE;
  }

  private maxFavoritesReached(): boolean {
    const favItems = JSON.parse(
      window.localStorage.getItem(this.persistenceKey) || "[]",
    );
    console.log("favItems", favItems, favItems.length);
    return favItems.length >= 8;
  }

  private removeFavorite(): void {
    this.state = State.INACTIVE;
    this.updateUI();
    this.container.classList.add(State.LOADING);
    const colorItemID = this.favoritesMdmVersion
      ? this.colorItem.mdmId
      : this.colorItem.colorId;
    FavoritesAPI.remove(colorItemID);
    this.container.classList.remove(State.LOADING);
  }

  public handleClickBehaviors(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.state === State.INACTIVE ? this.addFavorite() : this.removeFavorite();
  }

  private registerMessageSubscribers(): void {
    const isMdmVersion = this.favoritesMdmVersion;

    Utils.msg.subscribe(
      Message.addToFavorites,
      (item) =>
        this.isRelevantFavorite(item, isMdmVersion) &&
        this.updateComponentState(State.ADD),
    );

    Utils.msg.subscribe(
      Message.removeFromFavorites,
      (item) =>
        this.isRelevantFavorite(item, isMdmVersion) &&
        this.updateComponentState(State.REMOVE),
    );

    Utils.msg.subscribe(
      Message.cookieUpdated,
      (cookie: { retailer: string }) => {
        if ("retailer" in cookie) {
          this.channelId = AskVal.getChannelId(this.brand, this.country);
          this.colorItem.channelId = this.channelId;
        }
      },
    );

    Utils.msg.subscribe(
      Message.notificationsBannerState,
      (state) => (this.isDisabled = state.isOpen),
    );
  }

  private isRelevantFavorite(item: any, isMdmVersion): boolean {
    return (
      (isMdmVersion && item.mdmId !== undefined && this.mdmId === item.mdmId) ||
      (!isMdmVersion &&
        ((item.colorNumber !== undefined &&
          this.colorNumber === item.colorNumber) ||
          (item.collection !== undefined &&
            this.collection === item.collection)))
    );
  }

  private initializeUI(): void {
    this.chipTextAdd = this.container.querySelector(
      `[${Selector.hook}="chip-text-add"]`,
    );
    this.chipTextRemove = this.container.querySelector(
      `[${Selector.hook}="chip-text-remove"]`,
    );
    if (this.collection) {
      FavoritesAPI.read()
        .then((favorites) => {
          this.cartResponseCookie = { cart: [], cartItems: [], favorites };

          const collectionFavoriteIndex =
            this.cartResponseCookie.favorites.findIndex(
              (element) =>
                element.colorNumber === this.collection ||
                element.collection === this.collection,
            );

          if (collectionFavoriteIndex != -1) {
            this.state = State.ACTIVE;
          }
          this.updateUI();
        })
        .catch((error) => {
          console.error("Failed to fetch favorites data:", error);
        });
    }
  }

  static handleGlobalClickEvent(event: MouseEvent): void {
    const target = <HTMLElement>event.target;
    if (target.dataset.cmpInit) return;

    const isFavoriteCTA = target.classList.contains("cbg-favorite-cta");
    const isFavoriteCollectionCta =
      target.classList.contains("cbg-coty-collection-favorite") ||
      target.parentElement?.classList.contains("cbg-coty-collection-favorite");

    if (isFavoriteCTA || isFavoriteCollectionCta) {
      event.preventDefault();
      const cta = new FavoriteCTA(target);
      cta.handleClickBehaviors(event);
    }
  }
}

export { FavoriteCTA };
