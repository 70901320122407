import { ResultsContainer, AlgoliaSearchResult } from "./ResultsContainer";

class ColorFamilyResultsContainer extends ResultsContainer {
  resultsIndexKey = "wp_color_families_index";
  type = "color-family";

  constructor() {
    super();
    this.setContainerFromType();
  }

  updateWithResults(results: AlgoliaSearchResult): void {
    if (results?.hits.length) {
      const html = this.getTemplateFromType();

      const first = results.hits[0];
      const family = first.color_family;
      const slug = first.color_family_slug;
      const colorList = results.hits.map((hit) => {
        return {
          name: hit.post_title,
          hexValue: hit.hex,
        };
      });

      try {
        const $colorFamilySlug = html.querySelector(
          `[${this.resultsHook}="colorfamilyslug"]`,
        );
        const $swatches = <HTMLElement>(
          html.querySelector(`[${this.resultsHook}="colorfamilyswatches"]`)
        );
        const $colorFamilyName = html.querySelector(
          `[${this.resultsHook}="colorfamilyname"]`,
        );

        if (slug && $colorFamilySlug) {
          $colorFamilySlug.setAttribute("href", slug);
        }

        if (family && $colorFamilyName) {
          $colorFamilyName.innerHTML = family;
        }

        if (colorList && $swatches) {
          colorList.forEach((color) => {
            const $swatchBlock = `
              <div class="cbg-cmp-searchresult--color-family-swatch cbg__hidden-text"
                  style="background-color: ${color.hexValue}">
                  ${color.name}
              </div>
              `;
            $swatches.innerHTML += $swatchBlock;
          });
        }

        this.container.append(html);
      } catch (error) {
        console.warn("Issue adding Color Family Results", error);
      }
    }
  }
}

export { ColorFamilyResultsContainer };
