import { CartAPI } from "../cart/AskValV3";
import { Message } from "../../site/scripts/Message";
import { Utils } from "../../site/scripts/utils";

export class Checkout {
  private container: HTMLElement;
  private showDelete: boolean;
  private cartData: any;
  showShipping: boolean;
  showPaintChips: boolean;
  showNavigationButton: boolean;
  private readonly MAX_CHIPS_ALLOWED = 10;
  private emptyCartText: string;

  constructor(container: HTMLElement) {
    this.container = container;
    const { showDelete, showShipping, showPaintchips, showNavigationButton } =
      container?.dataset || {};
    this.showDelete = showDelete === "true";
    this.showShipping = showShipping === "true";
    this.showPaintChips = showPaintchips === "true";
    this.emptyCartText = container.dataset.emptyCartText || "";
    this.bindEvents();
    this.registerMessageSubscribers();
    this.init();
  }

  private bindEvents() {
    const placeOrderButton = this.container.querySelector(
      ".checkout-custom .checkout-cart .button--wrapper-inner",
    ) as HTMLButtonElement | null;
    placeOrderButton?.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.sendToInventory(event);
    });
  }

  private async sendToInventory(event: Event): Promise<void> {
    this.toggleLoadingDisplay(true);
    try {
      const response = await CartAPI.orderToInventory();
      if (response.requestStatus === "fault") {
        console.warn("Order to inventory request failed");
        this.showInventoryError();
        return;
      }

      const closestAncestor = (event.target as Element).closest(
        "[data-button-path]",
      );
      if (closestAncestor) {
        const path = closestAncestor.getAttribute("data-button-path");

        // Decode URL-encoded string safely
        try {
          const decodedPath = decodeURIComponent(path);

          // Validate and sanitize the decoded URL
          const url = new URL(decodedPath, window.location.origin);

          // Check if the URL is within your site's domain
          if (url.origin === window.location.origin) {
            window.parent.location.href = url.href;
          } else {
            console.error("Invalid URL");
          }
        } catch (e) {
          console.error("Error decoding or validating URL:", e);
        }
      }
    } catch (error) {
      console.error("Failed to send order to inventory:", error);
      this.showInventoryError();
    } finally {
      this.toggleLoadingDisplay(false);
    }
  }

  private async init() {
    this.toggleVisibility(".checkout-shipping", this.showShipping);
    this.toggleVisibility(".checkout-paint-chips", this.showPaintChips);

    try {
      await Promise.all(
        [
          this.showShipping && this.fetchRandomUser(),
          this.showPaintChips && this.fetchCart(),
        ].filter(Boolean),
      );
    } catch (error) {
      console.error("Initialization error:", error);
    }
  }
  /**
   * Registers subscribers for published messages.
   */
  private registerMessageSubscribers(): void {
    // Subscribe to the message when a favorite is added.
    Utils.msg.subscribe(Message.addToCartResponse, (response) => {
      this.cartData = response;
      this.sortCartData();
      this.renderCartChips();
      this.setupChipListeners();
      this.updateErrorMessageVisibility();
      response.status == 400 && window.scrollTo({ top: 0, behavior: "smooth" });
    });

    Utils.msg.subscribe(Message.removeFromCartResponse, (response) => {
      this.cartData = response;
      this.sortCartData();
      this.renderCartChips();
      this.setupChipListeners();
      this.updateErrorMessageVisibility();
    });
  }

  private toggleVisibility(selector: string, isVisible: boolean) {
    !isVisible && this.container.querySelector(selector)?.classList.add("hide");
  }

  private toggleLoadingDisplay(condition: boolean) {
    const element = window.parent.document.querySelector(
      ".modal-slider.open .loading",
    ) as HTMLElement;
    element.style.display = condition ? "flex" : "none";
  }

  private async fetchRandomUser(): Promise<void> {
    const userJson = this.readEncodedDataFromDOM();
    if (!userJson) {
      console.error("No user information found.");
      return;
    }

    const {
      firstName,
      lastName,
      email,
      address1,
      city,
      state,
      postalCode,
      country,
    } = userJson;

    const shippingInfo = this.container.querySelector(".shipping-information");
    if (shippingInfo) {
      const fullNameP = document.createElement("p");
      fullNameP.className = "user-fullname";
      fullNameP.textContent = `${firstName} ${lastName}`;

      const addressP = document.createElement("p");
      addressP.className = "user-address";
      addressP.textContent = `${address1}, ${city}`;

      const locationP = document.createElement("p");
      locationP.className = "user-location";
      locationP.textContent = `${state}, ${postalCode}, ${country}`;

      const emailP = document.createElement("p");
      emailP.className = "user-email";
      emailP.textContent = email;

      shippingInfo.replaceChildren(fullNameP, addressP, locationP, emailP);
    }
  }

  private decodeEncodedData(encodedData: string): any {
    try {
      return JSON.parse(decodeURIComponent(atob(encodedData)));
    } catch (e) {
      console.error("Failed to decode the data:", e);
      return null;
    }
  }

  private readEncodedDataFromDOM(): any {
    const hiddenInput = window.parent.document.querySelector(
      ".modal-slider #encodedShipInfo",
    ) as HTMLInputElement | null;
    return hiddenInput ? this.decodeEncodedData(hiddenInput.value) : null;
  }

  private async fetchCart() {
    try {
      this.cartData = await CartAPI.getCart();
      this.sortCartData();
      this.renderCartChips();
      this.setupChipListeners();
      this.updateErrorMessageVisibility();
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  }

  private sortCartData() {
    this.cartData?.cart?.sort((a: any, b: any) => a.id - b.id);
  }

  private renderCartChips() {
    const container = this.container.querySelector(".paint-chips-data");
    container!.innerHTML = ""; // Clear existing chips

    const orderReviewButton = this.container.querySelector<HTMLButtonElement>(
      ".checkout-custom #cart-orderreview",
    );
    const browseColorsButton = this.container.querySelector<HTMLButtonElement>(
      ".checkout-custom #cart-browsecolors",
    );

    const isCartEmpty = !this.cartData?.cart?.length;

    // Toggle button visibility based on cart state
    this.toggleButtonVisibility(orderReviewButton, !isCartEmpty);
    this.toggleButtonVisibility(browseColorsButton, isCartEmpty);

    if (isCartEmpty) {
      this.renderEmptyCartMessage(container);
      return;
    }

    this.cartData.cart.forEach((item: any) => {
      const chipHTML = item.isCollection
        ? this.renderCollectionChip(item)
        : this.renderSingleChip(item);

      container?.insertAdjacentHTML("beforeend", chipHTML);
    });
  }

  private toggleButtonVisibility(
    button: HTMLButtonElement | null,
    isVisible: boolean,
  ) {
    if (button) {
      button.style.display = isVisible ? "inline" : "none";
    }
  }

  private renderEmptyCartMessage(container: Element | null) {
    if (!container) return;
    const emptyCartMessage = document.createElement("div");
    emptyCartMessage.className = "empty-cart-message";
    emptyCartMessage.textContent = this.emptyCartText;
    container.appendChild(emptyCartMessage);
  }

  // Render a collection chip with max 3 colors
  private renderCollectionChip(item: any): string {
    const colors = item.colors
      .sort()
      .slice(0, 3)
      .map(
        (hexColor: string, index: number) =>
          `<div class="cart-chip-color" style="background-color: ${hexColor}; left: ${(index + 1) * 1}rem;"></div>`,
      )
      .join("");

    return `
      <div class="cart-chip collection">
        <div class="cart-chip-spacer">
          <div class="cart-chip-hex">${colors}</div>
          <div class="cart-chip-name">
            <div class="cart-chip-label-name">${item.colorNumber}</div>
            <div class="cart-chip-label-number">Color Collection</div>
          </div>
          ${this.showDelete ? this.getChipRemoveHTML(item) : ""}
        </div>
      </div>`;
  }

  // Render a single color chip
  private renderSingleChip(item: any): string {
    return `
      <div class="cart-chip">
        <div class="cart-chip-spacer">
          <div class="cart-chip-hex">
            <div class="cart-chip-color" style="background-color: ${item.hexColor};"></div>
          </div>
          <div class="cart-chip-name">
            <div class="cart-chip-label-name">${item.colorName}</div>
            <div class="cart-chip-label-number">${item.colorNumber}</div>
          </div>
          ${this.showDelete ? this.getChipRemoveHTML(item) : ""}
        </div>
      </div>`;
  }

  private getChipRemoveHTML(item: any) {
    return `
      <div class="cart-chip-remove" data-chip-uid="${item.id}">
        <i class="bin-add" aria-hidden="true"></i>
      </div>
      <div class="cart-chip-remove-notice hide">
        <p class="cart-chip-undo-text">${item.colorName ? item.colorName : item.collection} was removed</p>
        <a class="cart-chip-undo-remove">Undo</a>
      </div>`;
  }

  private setupChipListeners() {
    this.container
      .querySelectorAll<HTMLElement>(".bin-add")
      .forEach((binIcon) => {
        binIcon.addEventListener("click", () => this.handleRemoveChip(binIcon));
      });

    this.container
      .querySelectorAll<HTMLElement>(".cart-chip-undo-remove")
      .forEach((undoButton) => {
        undoButton.addEventListener("click", () =>
          this.handleUndoChip(undoButton),
        );
      });
  }

  private handleRemoveChip(binIcon: HTMLElement) {
    const cartChip = binIcon.closest(".cart-chip") as HTMLElement;
    if (!cartChip) return;

    const chipElement = cartChip.querySelector(
      ".cart-chip-remove",
    ) as HTMLElement | null;
    const chipId = chipElement?.dataset?.chipUid
      ? +chipElement.dataset.chipUid
      : null;
    this.toggleChipState(cartChip, true);

    // Store the timeout ID on the chip element to manage undo operations
    cartChip.dataset.timeoutId = String(
      this.scheduleChipDeletion(chipId, cartChip),
    );
  }

  private handleUndoChip(undoButton: HTMLElement) {
    const cartChip = undoButton.closest(".cart-chip") as HTMLElement;
    if (!cartChip) return;

    // Cancel the deletion timeout
    clearTimeout(Number(cartChip.dataset.timeoutId));
    this.toggleChipState(cartChip, false);
  }

  private toggleChipState(cartChip: HTMLElement, isRemoving: boolean) {
    cartChip
      .querySelector(".cart-chip-remove-notice")
      ?.classList.toggle("hide", !isRemoving);
    cartChip
      .querySelector(".cart-chip-spacer")
      ?.classList.toggle("cart-chip-content-hidden", isRemoving);
  }

  private scheduleChipDeletion(chipId: number | null, cartChip: HTMLElement) {
    return window.setTimeout(() => this.deleteCartChip(chipId), 3000);
  }

  private async deleteCartChip(chipId: number | null) {
    if (!chipId) return;
    try {
      this.cartData = await CartAPI.removeFromCart(chipId);
      this.sortCartData();
      this.renderCartChips();
      this.setupChipListeners();
      this.updateErrorMessageVisibility();
    } catch (error) {
      console.error("Error deleting cart chip:", error);
    }
  }

  private showInventoryError(): void {
    const errorMessage =
      this.container.dataset.inventoryFailed ||
      "Failed to process inventory request";
    const errorToast = document.createElement("div");
    errorToast.className =
      "paint-chips__error-inventory paint-chips__error-inventory--visible";
    errorToast.textContent = errorMessage;

    // Remove any existing error toasts
    const existingToast = this.container.querySelector(
      ".paint-chips__error-inventory",
    );
    existingToast?.remove();

    // Add new toast
    const description = this.container.querySelector(".checkout-description");
    description?.before(errorToast);

    // Scroll error into view with smooth behavior
    errorToast.scrollIntoView({ behavior: "smooth", block: "center" });

    // Auto-hide after 150 seconds
    setTimeout(() => {
      errorToast.classList.add("paint-chips__error-inventory--hidden");
      setTimeout(() => errorToast.remove(), 300);
    }, 10000);
  }

  private updateErrorMessageVisibility(): void {
    const errorMessageElement = this.container.querySelector(
      ".paint-chips__error-toast",
    );
    if (!errorMessageElement) return;

    const totalChips = this.cartData?.cart?.length || 0;
    const errorOnApi = this.cartData?.requestStatus === "fault";
    const maxError = this.container.dataset.maxerror;

    let message = "";
    let isVisible = false;

    if (totalChips >= this.MAX_CHIPS_ALLOWED) {
      message = maxError;
      isVisible = true;
    } else if (errorOnApi) {
      message = this.cartData?.errors?.[0]?.message || "An error occurred.";
      isVisible = true;
    }

    errorMessageElement.textContent = message;
    errorMessageElement.classList.toggle(
      "paint-chips__error-toast--hidden",
      !isVisible,
    );
    errorMessageElement.classList.toggle(
      "paint-chips__error-toast--visible",
      isVisible,
    );
  }
}
