class DropDownSelector {
  private container: HTMLElement;
  private button: HTMLElement;
  private menu: HTMLElement;
  private menuVisible: boolean;
  private experienceFragmentContainer: HTMLElement;
  private options: NodeListOf<HTMLElement>;
  private focusedOptionIndex: number = -1;

  constructor(element: HTMLElement) {
    this.container = element;
    this.button = element.querySelector(
      ".cmp-adaptiveform-dropdown__button",
    ) as HTMLElement;
    this.menu = element.querySelector(
      ".cmp-adaptiveform-dropdown__menu",
    ) as HTMLElement;
    this.options = this.menu.querySelectorAll(
      ".cmp-adaptiveform-dropdown__option",
    );
    this.experienceFragmentContainer = document.getElementById(
      "experience-fragment-container",
    ) as HTMLElement;

    this.init();
  }

  private init(): void {
    if (this.button && this.menu) {
      this.button.addEventListener("click", () => this.toggleDropdown());

      this.options.forEach((option, index) => {
        option.addEventListener("click", () => this.selectItem(option));
        option.addEventListener("keydown", (event) =>
          this.handleKeyDown(event, index),
        );
      });

      document.addEventListener("click", (event) =>
        this.handleOutsideClick(event),
      );
      this.button.addEventListener("keydown", (event) =>
        this.handleButtonKeyDown(event),
      );
    }
  }

  private toggleDropdown(): void {
    if (this.menu) {
      this.menuVisible = !this.menuVisible;
      this.menu.style.display = this.menuVisible ? "block" : "none";

      if (this.menuVisible) {
        this.focusedOptionIndex = -1; // Reset the focused index
        this.button.setAttribute("aria-expanded", "true");
      } else {
        this.button.setAttribute("aria-expanded", "false");
      }
    } else {
      console.error("Menu element not found");
    }
  }

  private selectItem(option: HTMLElement): void {
    this.button.textContent = option.textContent?.trim() || "";
    this.menu.style.display = "none"; // Close dropdown after selection
    this.menuVisible = false;

    const experienceFragmentPath = option.getAttribute("data-url");
    if (this.experienceFragmentContainer && experienceFragmentPath) {
      this.loadExperienceFragment(experienceFragmentPath);
    }
  }

  private loadExperienceFragment(url: string): void {
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        if (this.experienceFragmentContainer) {
          this.experienceFragmentContainer.innerHTML = html;
        }
      })
      .catch((error) =>
        console.error("Error loading experience fragment:", error),
      );
  }

  private handleButtonKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case "ArrowDown":
      case "Enter":
        this.toggleDropdown();
        if (this.menuVisible && this.options.length > 0) {
          this.focusOption(0);
        }
        event.preventDefault();
        break;
    }
  }

  private handleKeyDown(event: KeyboardEvent, index: number): void {
    switch (event.key) {
      case "ArrowDown":
        if (index < this.options.length - 1) {
          this.focusOption(index + 1);
        }
        event.preventDefault();
        break;
      case "ArrowUp":
        if (index > 0) {
          this.focusOption(index - 1);
        }
        event.preventDefault();
        break;
      case "Enter":
        this.selectItem(this.options[index]);
        event.preventDefault();
        break;
      case "Escape":
        this.toggleDropdown();
        this.button.focus();
        event.preventDefault();
        break;
    }
  }

  private focusOption(index: number): void {
    this.options.forEach((option) => option.classList.remove("focused"));
    this.options[index].classList.add("focused");
    this.options[index].focus();
    this.focusedOptionIndex = index;
  }

  private handleOutsideClick(event: Event): void {
    if (this.menuVisible && !this.container.contains(event.target as Node)) {
      this.toggleDropdown();
    }
  }
}

document.querySelectorAll(".cmp-adaptiveform-dropdown").forEach((el) => {
  new DropDownSelector(el as HTMLElement);
});

export { DropDownSelector };
