import { PdfGenerator } from "../../site/scripts/PdfGenerator";

class Billboard {
  pdfLogo: string;
  pdfDisclaimer: string;

  private container: HTMLElement;

  constructor(component: HTMLElement) {
    this.container = component;
    if (!this.container) {
      return;
    }

    this.pdfLogo = this.container.dataset.pdfLogo;
    this.pdfDisclaimer = this.container.dataset.pdfDisclaimer;

    const pdfGenerator = new PdfGenerator({
      sharingButtonClass: "cmp-billboard__sharing-button",
      fileName: "SceneImage.pdf",
    });

    const sharingButton: HTMLElement = this.container.querySelector(
      ".cmp-billboard__sharing-button",
    ) as HTMLElement;
    if (sharingButton) {
      sharingButton.addEventListener("click", () => {
        const hiddenDiv: HTMLDivElement = document.createElement("div");
        hiddenDiv.style.width = "600px";
        hiddenDiv.style.height = "1113px";
        hiddenDiv.className = "pdf-sharing pdf-inspiration";
        hiddenDiv.appendChild(
          document.querySelector(".billboard").cloneNode(true),
        );
        document.body.append(hiddenDiv);
        this.generatePdfLayout(hiddenDiv);
        pdfGenerator.generatePdf(hiddenDiv);
      });
    }
  }

  private updateSwatchColors(): void {
    const colorSwatches = document.querySelectorAll<HTMLElement>(
      ".pdf-inspiration .cmp-dynamicCard__card-color-swatch",
    );
    colorSwatches.forEach((swatch) => {
      const color = swatch.style.backgroundColor;
      const imageContainer = swatch
        .closest(".cmp-dynamicCard__card-container")
        .querySelector<HTMLElement>(".cmp-dynamicCard__card-color-swatch");
      if (color && imageContainer) {
        imageContainer.style.backgroundColor = color;
      }
    });
  }

  private generatePdfLayout(pdf: HTMLDivElement): void {
    this.updateSwatchColors();
    //header
    const logoContainer: HTMLDivElement = document.createElement("div");
    logoContainer.classList.add("logo-container");
    const logo = document.createElement("img");
    logo.src = this.pdfLogo;
    logo.classList.add("cmp-image__image");
    logoContainer.appendChild(logo.cloneNode(true));

    const heading = document.createElement("h1");
    heading.classList.add("featured-color-heading");
    heading.textContent = "Featured Color";
    pdf.prepend(heading);

    pdf.prepend(logoContainer);

    //disclaimer
    const disclaimer = document.createElement("p");
    disclaimer.classList.add("disclaimer");
    disclaimer.textContent = this.pdfDisclaimer;
    pdf.appendChild(disclaimer);
  }
}

export { Billboard };
