import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

enum ImageSize {
  width = 600,
  height = 1112.5,
}

interface PdfConfig {
  sharingButtonClass: string;
  fileName?: string;
}

class PdfGenerator {
  constructor(private config: PdfConfig) {}

  private addPdfSuffixToClasses(element: HTMLElement) {
    function addSuffixToClassNames(node: HTMLElement) {
      if (node.classList.contains("pdf-class-name-modified")) return;

      node.className = Array.from(node.classList)
        .map((className) => `${className}-pdf`)
        .join(" ");

      Array.from(node.children).forEach((child) => {
        if (child instanceof HTMLElement) {
          addSuffixToClassNames(child);
        }
      });
    }

    if (!element.classList.contains("pdf-class-name-modified")) {
      element.classList.add("pdf-class-name-modified");
      Array.from(element.children).forEach((child) => {
        if (child instanceof HTMLElement) {
          addSuffixToClassNames(child);
        }
      });
    }
  }

  public async generatePdf(hiddenDiv: HTMLDivElement) {
    try {
      this.addPdfSuffixToClasses(hiddenDiv);

      const style: HTMLStyleElement = document.createElement("style");
      document.head.appendChild(style);
      style.sheet?.insertRule(
        "body > div:last-child img { display: inline-block; }",
      );

      const canvas: HTMLCanvasElement = await html2canvas(hiddenDiv, {
        scale: 3,
      });

      style.remove();

      const imgData: string = canvas.toDataURL("image/png");
      const pdf: jsPDF = new jsPDF("p", "px", [
        ImageSize.width,
        ImageSize.height,
      ]);

      pdf.addImage(imgData, "PNG", 0, 0, ImageSize.width, ImageSize.height);
      pdf.save(this.config.fileName || "SceneImage.pdf");

      document.body.removeChild(hiddenDiv);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }
}

export { PdfGenerator };
