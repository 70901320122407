class Marketo {
  // Elements
  container: HTMLFormElement;

  programName: string;
  private endpoint: string;
  private successPage: string;
  private submitBtn: HTMLButtonElement;

  constructor(component: HTMLFormElement) {
    this.container = component;
    this.submitBtn = this.container.querySelector('button[type="submit"]');

    if (!this.container) {
      return null;
    }

    const data = this.container.dataset;

    this.endpoint = data.endpoint;
    this.programName = data.marketoProgramName;
    this.successPage = data.successPage;

    this.container.addEventListener(
      "submit",
      this.marketoDataToPost.bind(this),
    );
  }

  private beginLoading() {
    document.body.style.cursor = "wait";
    this.submitBtn.disabled = true;
  }

  private finishLoading() {
    document.body.style.cursor = "";
    this.submitBtn.disabled = false;
  }

  private marketoDataToPost(event: Event): boolean {
    event.preventDefault();
    this.beginLoading();

    if (!this.container.checkValidity()) {
      this.finishLoading();
      return false;
    }

    const formValues = {};

    const formDataValues: FormData = new FormData(this.container);

    formDataValues.forEach((value, name) => {
      if (value) {
        if (Object.prototype.hasOwnProperty.call(formValues, name)) {
          formValues[name] += `;${value}`;
        } else {
          formValues[name] = value;
        }
      }
    });

    const dataforMarketo = {
      programName: this.programName,
      input: [formValues],
    };

    window.gtm4.populateEmailSignupData(this.container);
    this.postData(dataforMarketo).then((res) => res);
  }

  private async postData(info): Promise<void> {
    if (!this.endpoint) {
      throw new Error("Endpoint is not set");
      this.finishLoading();
      return;
    }

    const requestData = JSON.stringify(info);
    try {
      const response = await fetch(this.endpoint, {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        body: requestData,
      });

      const data = await response.text();

      if (response.ok || (response.status >= 200 && response.status < 400)) {
        this.container.reset();
        if (this.successPage) {
          location.href = this.successPage;
        } else {
          this.finishLoading();
        }
      } else {
        this.finishLoading();
      }
    } catch (err) {
      this.finishLoading();
      throw err;
    }
  }
}

export { Marketo };
