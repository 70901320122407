declare global {
  interface Window {
    Bootstrapper: any;
  }
}

class Cpra {
  constructor() {
    this.handleCpraClick();
  }
  private handleCpraClick() {
    const cpraLink: HTMLElement = document.querySelector('[href="#cpra"]');
    const ensightenBootstrapper = window.Bootstrapper;
    if (cpraLink) {
      cpraLink.onclick = (event) => {
        event.preventDefault();
        if (ensightenBootstrapper?.gateway) {
          ensightenBootstrapper.gateway.openModal();
        };
      };
    }
  }
}

export { Cpra };
