import { ToolTip } from "./ToolTip";
import { ModalButton } from "../modal/ModalButton";

// Initialize all options.
document.querySelectorAll('[data-cbg-cmp="toolTip"]').forEach((el) => {
  new ToolTip(<HTMLElement>el);

  const opensModal = (<HTMLElement>el)?.dataset?.opensModal || "";
  if (opensModal.length > 0) {
    new ModalButton(<HTMLElement>el);
  } else {
    new ToolTip(<HTMLElement>el);
  }
});
